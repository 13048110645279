import { useTranslation } from "react-i18next";

export const Empty = () => {

    const { t: msg } = useTranslation(['home']);

    return (
        <div className="empty uk-width-1-1 uk-card uk-card-default">
            <div className="uk-card-body">
                <p className="text">{msg("nodata")}</p>
            </div>
        </div >
    )
}
const MODAL_NAMES = {
  PATIENT: {
    GTC_MODAL: "gtc-modal",
    DISCLAIMER: "disclaimer-modal",
    SURVEY_MODAL: "survey-modal",
    SCORE_MODAL: "score-modal",
    LAB_PDF_MODAL: "lab-pdf-modal",
    VIEW_SCORES_MODAL: "view-scores-modal",
    NOTIFICATION_MODAL: "notification-modal",
    CONTACTS_MODAL: "contacts-modal",
    ACCOUNT_AND_PRIVACY_MODAL: "account-and-privacy-modal",
  },
};

export default MODAL_NAMES;

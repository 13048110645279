import { ReactKeycloakProvider } from "@react-keycloak/web";
import MainRoute, { ScrollToTop } from "./MainRoute";
import keycloak, { initOptions } from "./keycloakConfig";
import { BrowserRouter } from "react-router-dom";
import { LoadingEllipsis } from "./shared/components/loading/Loading";
import { useEffect } from "react";
import { AuthClientEvent } from "@react-keycloak/core";
import { requestPermission } from "./serviceWorker";
import { observer } from "mobx-react-lite";

const App = observer(() => {

  const onKeycloakEvent = (event: AuthClientEvent) => {
    if (event === "onTokenExpired") {
      try {
        keycloak.logout();
      } catch (error) { }
    }
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      requestPermission()
    }
  }, [])

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={<LoadingEllipsis fullHeight />}
      onEvent={onKeycloakEvent}
      autoRefreshToken={false}
    >
      <BrowserRouter>
        <ScrollToTop />
        <MainRoute />
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
})

export default App;
export const englishtermsofuse = {
  termsofuse: {
    title: "Terms of Use for the ALS Analytics app",
    semi: "These Terms of Use were last updated on November 01, 2023",
    description: `APST Research GmbH ("APST Research") has developed an ALS research platform/app
     ("ALS Analytics App"). APST Research provides the ALS Analytics App to interested users who
      suffer from amyotrophic lateral sclerosis ("Users"). In the ALS Analytics App, users can
       use the ALS Functional Rating Scale revised (ALSFRSr) ("ALS Functional Rating Score")
        to record the status of their disease and assess the progression of their disease.
         In addition, users can determine the biomarker NfL (neurofilament light chain) ("NfL value")
          by taking a blood sample and have the progression analysis obtained after evaluating the NfL
           value displayed in the app ("NfL analysis").`
  },
  applicationOfSubject: {
    title: "1.	Application and subject matter of the Terms of Use",
    list: [
      {
        data: `The following terms of use ("Terms of Use") apply to the initial and future use of the ALS Analytics App and the content, services and functions offered therein (hereinafter collectively referred to as "Services"). With regard to the Services, a distinction must be made between the free content of the ALS Analytics App, which includes the determination of the ALS Functional Rating Score and other relevant information and content ("Free Services"), and the fee-based offer to determine and evaluate the NF-L value and display the NfL analysis ("Paid Service").`,
      },
      {
        data: "By registering in the ALS Analytics app and using the services, the user confirms that they accept the terms of use and agree to their validity. Registration and use of the services is not possible without acceptance of the Terms of Use.",
      },
    ],
  },
  conclusionOfContract: {
    title: "2.	Conclusion of contract",
    list: [
      {
        data: `When registering the user account, the user must accept the validity of the Terms of Use and give their consent under data protection law. The contract for the use of the ALS Analytics app and the services is not concluded until APST Research has sent confirmation of registration by email. By clicking the "Let's go" button, the user submits an offer to conclude a contract for the use of the ALS Analytics app and services. Immediately after sending the offer, the user will receive confirmation of their registration. Subject to sentence 1, the confirmation is also the acceptance of the contractual offer to use the ALS Analytics app and the free services.`,
      },
      {
        data: `The contract for the use of the fee-based service is subject to the <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"}">General Terms and Conditions</a> for NfL Analysis.`,
      }, {
        data: `The user can print or save the Terms of Use using the browser function. The user can also download and archive the Terms of Use in PDF form as part of the registration process. Upon confirmation of registration, APST Research shall send the user the Terms of Use in their current version. APST Research does not store the terms of use.`,
      },
    ],
  },
  scopeOfService: {
    title: "3.	Scope of services, changes to services",
    list: [
      {
        data: "The ALS Analytics App and Services are offered via a browser-based web application and as a native iOS or Android app that can be accessed via the internet.",
      },
      {
        data: "The scope of the ALS Analytics App and the services available to the user depends on which services APST Research makes available in the ALS Analytics App. Provided services can be:",
        subList: [
          {
            data: "Provision of the ALS functional scale to determine the ALS Functional Rating Score. This is a questionnaire consisting of twelve questions on the functions of the head, arms, legs, trunk and breathing, each with five sub-questions, the answers to which are rated on a point scale from 0 to 4. This results in a total score of between 48 and 0 points, the ALS Functional Rating Score, which enables people with ALS to assess the progression of their disease themselves. The progression score in the ALS Analytics app offers the option of classifying the speed of disease progression as fast, slow or average. It serves as a guide for the user.",
          },
          {
            data: `Paid provision of the function for determining the biomarker NfL by means of a blood sample. Users can use this function to evaluate their NF-L value and display the resulting trend analysis in the app. Details of this function are set out in the <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"}">General Terms and Conditions</a> for the NfL analysis.`,
          },
        ]
      },
      {
        data: "APST Research is continuously developing the ALS Analytics App, the Services and the underlying software and their content. APST Research has the right to implement updates and new versions to improve the ALS Analytics App and the Services at any time. APST Research may make changes of any kind to the ALS Analytics App or the Services or to the way in which the ALS Analytics App or the Services are provided. APST Research reserves the right to change or amend the functionality of the ALS Analytics App and the Services at any time or to terminate them in whole or in part, to discontinue or remove Services or to restrict access to Services. APST Research shall inform the user of any not insignificant impairment of the accessibility or usability of the ALS Analytics App or the services within a reasonable period before the time of the change. APST Research is not responsible for any direct or indirect damages incurred by the user as a result of the ALS Analytics app or services being changed, modified, terminated or restricted.",
      },
      {
        data: "APST Research points out that the ALS Analytics App and the Services are not a medical device.",
      },
    ],
  },
  accessRequirements: {
    title: "4.	Access requirements, user account",
    list: [
      {
        data: "The user is obliged to provide all software and hardware required to access the ALS Analytics App and the Services, such as a terminal device and an internet connection. Additional software may need to be downloaded or installed (e.g. certain software plug-ins or software applications) in order to access certain services or documents. To use the native app, the user must download the application via the app store provider Google Play Store or the Apple Store.",
      },
      {
        data: "In order to use the services, the user must be registered with a user account and logged in to the ALS Analytics app. The user's email address and a password as well as the user's consent under data protection law are required for new registration. Once registration is complete, the user can log in, provide further information about themselves (such as age, gender and onset of illness) and gain access to the ALS Analytics app and services that are suitable for them.",
      }, {
        data: "Only natural persons with unlimited legal capacity who are at least 16 years old and not under guardianship may register a user account. When registering and using the ALS Analytics App, the user must provide correct, truthful and, where necessary, complete information. APST Research expressly reserves the right to verify the data provided during registration by taking appropriate measures. APST Research is entitled to make the use of the ALS Analytics App and the services dependent on further requirements. The user account is personal and may only be used by the user personally. The access data for the user account must be kept secret and any access by third parties must be prevented by suitable measures. The user is responsible for all activities that take place via his user account and is liable to APST Research in accordance with the statutory provisions. APST Research shall not be liable for any damages incurred by the user as a result of third parties using the access data or the user account with or without the user's knowledge.",
      },
    ],
  },
  obligationsOfUser: {
    title: "5.	Obligations of the user.",
    list: [
      {
        data: "The user may only use the ALS Analytics app for the purpose for which access to the services was granted. Any other use of the ALS Analytics App and the services is prohibited.",
      },
      {
        data: "In particular, the user may not post any content in the ALS Analytics App that may be punishable by law (in particular incitement to hatred, insults, defamation, threats), that is pornographic, vulgar or obscene, harassing or otherwise offensive, that is anti-constitutional, extremist, racist or xenophobic, or that represents content originating from prohibited groups, that infringes the rights of third parties (in particular personal rights, copyrights, trademark rights, patent rights or other rights of third parties).",
      }, {
        data: "The user shall use the ALS Analytics App and the services in such a way that no impairments, overloads or damage occur and the purpose pursued with the ALS Analytics App and the services is neither jeopardized nor circumvented. The user shall not circumvent or modify the security precautions of the ALS Analytics app, either themselves or through third parties.",
      },
    ],
  },
  blockingOfAccount: {
    title: "6.	Blocking of the user account",
    list: [
      {
        data: "APST Research reserves the right, in its sole discretion, to terminate or restrict the user's access to any part of the ALS Analytics App or the Services at any time and without notice.",
      },
      {
        data: "In particular, APST Research is entitled to block or delete a user account if the user breaches an obligation under these Terms of Use or if APST Research has reason to suspect that such a breach of obligation has occurred.",
      }
    ],
  },
  rightsOfUser: {
    title: "7.	Rights of use of the user",
    list: [
      {
        data: "All rights to the ALS Analytics App and the services, including the rights to related software, databases, graphics, user interfaces, designs and other content, designations, names and trademarks, shall remain with APST Research or any third parties that have granted APST Research rights in connection with the ALS Analytics App or the services.",
      },
      {
        data: "The user receives a simple, non-transferable right, limited in time to the duration of the user contract, to use the ALS Analytics app and the services for their respective intended purpose. The user may only use the ALS Analytics app and the services for private purposes. The user is not entitled to any rights that are not expressly granted to the user.",
      }, {
        data: "In particular, the user may not copy, translate, modify, reverse engineer, decompile or distribute the ALS Analytics App and the services or any part thereof, including the source code or any copy, adaptation, transcription or merged part thereof. The user is not entitled to transfer, lease, assign, rent or sublicense the rights granted to him under these Terms of Use or to make the services available for use by other persons.",
      },
    ],
  },
  rightsOfResponsibility: {
    title: "8.	Rights of use and responsibility for content posted by the user",
    list: [
      {
        data: "By uploading content (such as images, text, audio and video material) to the ALS Analytics App, the user grants APST Research a non-exclusive, revocable, royalty-free and perpetual sublicensable right to use the content for the purposes for which it was uploaded to the ALS Analytics App, in particular to copy, transmit, publicly play, publicly perform, reproduce, edit, translate and reformat the content.",
      },
      {
        data: "By posting content, the user represents and warrants that he/she is the owner of all rights to the content necessary to post the content and to grant APST Research the rights of use under these Terms of Use.",
      }, {
        data: "It is not possible for APST Research to control content posted by users in the ALS Analytics App. This applies in particular with regard to the accuracy of the content, its legality, topicality and quality as well as its suitability for a specific purpose. APST Research is not liable for content posted by users.",
      },
    ],
  },
  costs: {
    title: "9.	Costs",
    list: [
      {
        data: `The user shall not incur any costs for registering a user account and using the ALS Analytics app and the free services in accordance with sections 3.2.1 and 3.2.2. For the use of the fee-based services in accordance with section 3.2.3, the user shall incur costs that are specified in the <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"}">General Terms and Conditions</a> for NfL-Analysis.`,
      },
      {
        data: `Registration and use of the ALS Analytics App and the services require the provision of the user's personal data and consent to processing under data protection law. Information on the processing of personal data by APST Research can be found in the <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "data-privacy-policy"}">Data privacy</a> information.`,
      }
    ],
  },
  revocationsAndTermination: {
    title: "10.	Revocation, termination, deletion of the user account",
    list: [
      {
        data: "As a consumer, the user has a legal right of revocation. The revocation policy applies.",
      },
      {
        data: `The user has the option of deleting their user account at any time and terminating the user contract by clicking on the termination button in their user account under "Settings". The user will receive an e-mail from APST Research with a confirmation of termination sent to the e-mail address provided to APST Research. Upon termination and deletion of the user account, the user contract and the user's right to use the ALS Analytics app and services shall end.`,
      }, {
        data: "If the user revokes a consent given by him under data protection law or objects to further processing of his personal data, APST Research may terminate the user agreement without observing a notice period. Section 10.2 sentences 2 and 3 shall apply accordingly.",
      },
    ],
  },
  noWarranty: {
    title: "11.	No warranty",
    list: [
      {
        data: "APST Research shall always endeavor to ensure the proper operation of the ALS Analytics app and the services, but shall not be liable to the user for the continuous accessibility, availability and functionality of the ALS Analytics app and the services.",
      },
      {
        data: "APST Research has the right to take measures that affect access to the ALS Analytics App or the Services if this is necessary, for example, for technical, maintenance or security reasons.",
      }, {
        data: "In particular, APST Research is not liable to the user for performance or availability problems:",
        subList: [
          {
            data: "Due to factors beyond the control of APST Research (e.g. natural disasters, wars, terrorist attacks, riots or government measures),",
          },
          {
            data: "Caused by services, hardware or software of the user or third parties,",
          },
          {
            data: "Caused by the use of a service after APST Research has instructed the user to change or update the use of a service and the user has not changed or updated the use as instructed,",
          },
          {
            data: "During pre-release, beta and test services (as determined by APST Research), ",
          },
          {
            data: "Caused by the unauthorized act or omission of the user or by other persons who have gained access to the APST Research network using the user's access data or devices,",
          },
          {
            data: "Caused by the user's failure to maintain required configurations, use supported platforms and comply with acceptable use policies; or",
          },
          {
            data: "Are based on a blocking of the user account in accordance with section 6.",
          },
        ]

      },
    ],
  },
  liability: {
    title: "12.	Liability",
    list: [
      {
        data: "Notwithstanding the following limitations of liability, APST Research shall be liable without limitation for damages to life, body and health that are based on a negligent or intentional breach of duty by APST Research, its legal representatives or vicarious agents, as well as for damages that are covered by liability under the Product Liability Act, as well as for damages that are based on intentional or grossly negligent breaches of contract and fraudulent intent by APST Research, its legal representatives or vicarious agents.",
      },
      {
        data: "In the event of negligence, APST Research's liability shall be limited to compensation for typical foreseeable damage in connection with the use of the ALS Analytics App and the Services. In the event of simple negligence, however, APST Research shall only be liable if APST Research has breached an obligation, the fulfillment of which was essential for the proper use of the ALS Analytics App and the services, taking into account the interests of both parties, and on the fulfillment of which the user was entitled to rely.",
      },
      {
        data: "APST Research is also not liable for malfunctions and loss of quality of the ALS Analytics App and the services as a result of data transmission on the internet for which APST Research is not responsible and which make the use of Internet-based services more difficult or impossible.",
      },
      {
        data: "Any further liability of APST Research is excluded regardless of the legal nature of the asserted claim. Insofar as the liability of APST Research is excluded or limited, this shall also apply to the personal liability of its employees, workers, staff, representatives and vicarious agents.",
      },
    ],
  },

  dataPrivacy: {
    title: "13.	Data privacy",
    description: `APST Research would like to point out that the user's personal data is processed when using the ALS Analytics app. APST Research complies with the applicable data protection regulations. Information on how personal data is processed can be found in the <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "data-privacy-policy"}">Data privacy</a> information.`
  },

  applicableLaw: {
    title: "14.	Applicable law, place of jurisdiction, dispute resolution",
    list: [
      {
        data: "These Terms of Use, their subject matter and their formation (as well as all non-contractual disputes or claims) shall be governed by the laws of the Federal Republic of Germany, excluding the UN Convention on Contracts for the International Sale of Goods (CISG), unless mandatory consumer protection law prescribes otherwise.",
      },
      {
        data: "The place of jurisdiction is Berlin if the user is a merchant within the meaning of the German Commercial Code, a legal entity under public law or a special fund under public law. The same applies to users who do not have a general place of jurisdiction in Germany or another EU member state or who have moved their place of residence abroad after conclusion of the contract or whose place of residence or habitual abode is not known at the time the action is filed. Otherwise, the applicable statutory provisions shall apply to local and international jurisdiction.",
      },
      {
        data: `The European Commission provides a platform for online dispute resolution (OS). You can find the link to the platform here: <a className="uk-text-primary gtc-text" target="_blank" href="https://ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</a>. APST Research is not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.`,
      },
    ],
  },

  invalidityOfIndividual: {
    title: "15.	Invalidity of individual provisions, contractual omissions",
    list: [
      {
        data: "Should individual provisions of these Terms of Use be or become invalid or unenforceable, this shall not affect the validity of the remaining Terms of Use. The invalid or unenforceable provision shall be replaced by the relevant statutory provisions. If legal provisions are not available in the respective case, the effective or enforceable provision shall replace the ineffective or unenforceable provision, the effects of which come closest to the objective that APST Research and the user pursued with the ineffective or unenforceable regulation.",
      },
      {
        data: "Clause 15.1 shall apply accordingly in the event that one or more of the conditions prove to be incomplete.",
      },
    ],
  },

  changesToTerms: {
    title: "16.	Changes to the terms of use",
    list: [
      {
        data: "APST Research reserves the right to change or amend these Terms of Use at any time without giving reasons with effect for the future if:",
        subList: [
          {
            data: "This is necessary to adapt to changed circumstances for which APST Research is not responsible (in particular changes in the law and difficulties in the implementation of the contract of use that were not foreseeable when the contract was concluded) and the change is reasonable for the user;",
          },
          {
            data: "The change is only beneficial to the user;",
          },
          {
            data: "The changes or adjustments have no material impact on the functions of the services or are of a purely technical or organizational nature;",
          },
          {
            data: `APST Research introduces additional, entirely new services or performances that require a description in the <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"}">General Terms and Conditions</a>, unless this would be detrimental to the current user relationship. An intended change will be sent to the user at the email address last provided to APST Research or communicated via an announcement in the app. The change shall enter into force no later than 14 days after the change has been published in the app or the user has been made aware of the change in some other way, unless the user objects to the change within 14 days. The user is deemed to have accepted the new terms and conditions if the ALS Analytics app and services are used after these 14 days have expired.`,
          },
        ]
      },

      {
        data: `In the event of an objection within the period specified in Section 16.1, APST Research may terminate the contractual relationship with the user without notice. If APST Research does not terminate the previous contractual relationship after the effective objection, the previous Terms of Use shall continue to apply unchanged in relation to the respective user. The current version of the Terms of Use can be viewed by clicking on the hyperlink "Terms of Use" <a target="_blank" href='https://www.apstresearch.com'> on the website</a>`,
      },
    ],
  },
  contact: {
    title: "17.	Contact",
    description: "APST Research can be contacted at:",
    list: [
      {
        data: "APST Research GmbH",
      },
      {
        data: "Westhafenstraße 1",
      }, {
        data: "13353 Berlin, Germany",
      },
      {
        data: `E-Mail: <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>`,
      }, {
        data: `Telefon: <a href="tel:+49(0)30-81031410">+49 (0) 30-81031410</a>`,
      },
    ],
  },
};

import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import ErrorBoundary from "../components/error/ErrorBoundary";
import { Bar } from 'react-chartjs-2';
import ChartAnnotation from 'chartjs-plugin-annotation';
import { getLabelFromPercentile, getIndices } from "../components/utils/utils"
import { useAppContext } from '../context/Context';
import { Chart as ChartJS, registerables } from 'chart.js'
import { formatDate, getAgesOverYears } from '../components/utils/utils';
import { useTranslation } from 'react-i18next';
import InfoText from "../components/info/InfoText";

ChartJS.register(...registerables, ChartAnnotation)

export const NFLDiagramGraph = observer(() => {
    const { store } = useAppContext();
    const nflChartRef = React.createRef();
    const { t: msg } = useTranslation(['home']);
    const [entered, setEntered] = useState(false);
    const [tooltipLabel, setTooltipLabel] = useState(0);

    const nfl = store.result.all;
    const patient = store.patient.patientJson;
    const dob = patient ? patient.date_of_birth : "";

    const initialZoomValue = 0;
    const minZoomValue = 0;
    const maxZoomValue = 2;
    const [zoomValue, setZoomValue] = useState(initialZoomValue);
    const adjustedZoomValue = 1 / zoomValue;
    const ages = getAgesOverYears(dob);

    // const maxNumber = Math.max(...nfl.map((n) => n.asJson.nfl_value));
    const yvalues = nfl.map((nc) => parseFloat(nc.asJson.nfl_value));
    const labels = nfl.map((sv) => formatDate(sv.asJson.date_of_sample_collection));
    const indices = ages.map((age) => getIndices(age)).slice(-labels.length);

    const data = {
        labels: labels,
        datasets: [
            {
                type: "bubble",
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: '#016caa',
                hoverRadius: 0,
                data: yvalues,
                radius: 7,
                fill: false,
                showLine: false,
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const yValue = context.parsed.y;
                            return `${yValue} (pg/ml)`;
                        }
                    }
                }
            },
            {
                type: "bar",
                label: "",
                barPercentage: 1,
                categoryPercentage: 1,
                backgroundColor: "#ADD8E6",
                data: indices,
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: false,
                },
                min: -10 * adjustedZoomValue,
                max: 10 * adjustedZoomValue,
            },
            y: {
                ticks: {
                    stepSize: 40,
                },
                beginAtZero: true,
                max: 1000,
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: msg("nflChartLabel"),
                color: "#666",
                position: 'right',
                align: "center",
            },
            annotation: {
                annotations: yvalues.map((yAs, index) => ({
                    id: `label${index}`,
                    type: 'label',
                    xValue: index,
                    yValue: Math.max(yAs - (35), 0),
                    content: getLabelFromPercentile(yAs),
                    backgroundColor: tooltipLabel === yAs ? 'blue' : '#74A9D8',
                    color: tooltipLabel === yAs ? 'white' : 'black',
                    borderRadius: 4,
                    enter: function () {
                        const yValue = yAs;
                        setEntered(true)
                        setTooltipLabel(yValue)
                    },
                    leave: function () {
                        setEntered(false)
                        setTooltipLabel(0)
                    },
                    font: {
                        size: 16
                    }
                }))
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    mode: "x",
                },
                pan: {
                    enabled: true,
                    mode: "x"
                }
            }
        },
    };

    const handleResetZoom = () => {
        setZoomValue(initialZoomValue);
    };

    const handleZoomChange = (event) => {
        const newZoomValue = parseFloat(event.target.value);
        setZoomValue(newZoomValue);
    };

    const infodatas = [
        { age: 20, ml: 11.1 },
        { age: 30, ml: 13.8 },
        { age: 40, ml: 19.7 },
        { age: 50, ml: 26.2 },
        { age: 60, ml: 34.9 },
        { age: 70, ml: 46.5 },
        { age: 80, ml: 61.9 },
        { age: 90, ml: 82.4 }];

    return (
        <div>
            <div className="uk-flex uk-flex-between">
                <div>
                    <p className="uk-margin-remove-top uk-text-bold">NfL {msg("diagramTitle")}</p>
                    <button className='btn btn-primary' onClick={handleResetZoom}>
                        {msg("resetZoomButton")}
                    </button>
                </div>
                <div>
                    {entered && <p className='text'>{tooltipLabel} (pg/ml)</p>}
                </div>
                <div>
                    <InfoText>
                        <p>{msg("nflDiagramInfoTextOne")} </p>
                        <p>{msg("nflDiagramInfoTextTwo")}</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>{msg("nflAgeVSpgmlInfoText")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infodatas.map((d, index) => (
                                    <tr key={index}>
                                        <td>{d.age}</td>
                                        <td>{d.ml}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <p>{msg("nflDiagramInfoTextThree")}</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{msg("nflInfoTextPercentileOne")}</td>
                                </tr>
                                <tr>
                                    <td>{msg("nflInfoTextPercentileTwo")}</td>
                                </tr>
                                <tr>
                                    <td>{msg("nflInfoTextPercentileThree")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </InfoText>
                </div>
            </div>
            <ErrorBoundary>
                <div style={{ minHeight: "70vh", height: '80vh' }}>
                    <Bar ref={nflChartRef} type="bubble" data={data} options={options} />
                    <input
                        className="uk-range uk-width-1-1"
                        type="range"
                        min={minZoomValue}
                        max={maxZoomValue}
                        step={0.1}
                        value={zoomValue}
                        onChange={handleZoomChange}
                    />
                </div>
            </ErrorBoundary>
        </div>
    )
});

// >= 400 ? 100 : 60
//${85} maxNumber >= 500 ? 100 : 60 vh
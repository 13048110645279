import { useState } from "react";
import MODAL_NAMES from "../dialogs/ModalName";
import SurveyModal from "../dialogs/survey/SurveyModal";
import { observer } from "mobx-react-lite";
import SurveyTabs from "./SurveyTabs";
import ViewScoresModal from "../dialogs/viewSurvey/ViewScoresModal";
import useTitle from "../../shared/hooks/useTitle"
import { useTranslation } from "react-i18next";
import { ISurvey } from "../../models/Survey";
import { ALSFRSDiagram } from "../../shared/charts/ALSFRSDiagram";
import Modal from "../../shared/components/Modal";
import ErrorBoundary from "../../shared/components/error/ErrorBoundary";
import showModalFromId from "../../shared/components/functions/ModalShow";
import InfoText from "../../shared/components/info/InfoText";
import { Empty } from "../../shared/components/nodata/Empty";
import Toolbar from "../../shared/components/toolbar/Toolbar";
import icons from "../../shared/components/utils/icons";
import { getCourseValue, formatDate, getTotalValue } from "../../shared/components/utils/utils";
import { useAppContext } from "../../shared/context/Context";

export const InitialSurvey = observer(() => {
    const { store } = useAppContext();
    const { t: msg } = useTranslation(['home']);
    const [selectedTab, setselectedTab] = useState("scores-tab");
    useTitle("Questionaires");

    const patient = store.patient.patientJson;
    const surveys = store.survey.all.map((sv) => sv.asJson);

    const date_of_symptoms_onset = () => {
        return patient ? patient.time_of_symptoms_onset : "";
    }

    const totalValue = () => {
        const val = getTotalValue(surveys);
        return val
    };

    const progression = () => {
        const val = getCourseValue(surveys, date_of_symptoms_onset());
        return val
    };

    const onCompleteSurvey = () => {
        showModalFromId(MODAL_NAMES.PATIENT.SURVEY_MODAL)
    };

    return (
        <div className="uk-section profile">
            <div className="uk-container uk-container-large">
                <div className="sticky-top">
                    <ErrorBoundary>
                        <Toolbar
                            leftControls={
                                <div>
                                    <SurveyTabs
                                        selectedTab={selectedTab}
                                        setselectedTab={setselectedTab}
                                    />
                                </div>
                            }
                            rightControls={
                                <div className="uk-flex">
                                    <div>
                                        <button className="btn btn-primary" type="button"
                                            onClick={onCompleteSurvey}>
                                            {msg("startQuestionaireButton")}
                                        </button>
                                    </div>
                                </div>
                            }
                        />
                    </ErrorBoundary>
                </div>
                <ErrorBoundary>
                    <div>
                        <div className="uk-flex uk-flex-between">
                            <div>
                                <p className="uk-text-small uk-text-bold">{msg("progressionRateTitle")}</p>
                            </div>
                            <div>
                                <InfoText>
                                    <div>
                                        <p className="uk-text-bold">{msg("progressInfoTextQ1")}</p>
                                        <p>{msg("progressInfoTextA1")} </p>
                                        <p className="uk-text-bold">{msg("progressInfoTextQ2")}</p>
                                        <p>{msg("progressInfoTextA2")} </p>
                                        <p className="uk-text-bold">{msg("progressInfoTextQ3")}</p>
                                        <p>{msg("progressInfoTextA3")}</p>
                                        <p className="uk-text-bold">{msg("progressInfoTextQ4")}</p>
                                        <p>{msg("progressInfoTextA4")}</p>
                                    </div>
                                </InfoText>
                            </div>
                        </div>
                        <div className="progression uk-flex uk-card uk-card-default">
                            <div className="rate-card">
                                <div className="rates">
                                    <div className="rate">{totalValue().value}</div>
                                </div>
                                <p className="rate-text">{msg("progressionRateTotalValue")}</p>
                            </div>
                            <div className="rate-card">
                                <div className="rates">
                                    <div className="rate">{progression().value}</div>
                                </div>
                                <p className="rate-text">{msg("progressionRateValue")}</p>
                            </div>
                        </div>
                        {selectedTab === "scores-tab" &&
                            <div className="uk-margin custombg uk-border-rounded">
                                <ALSFRSDiagram />
                            </div>
                        }
                        {selectedTab === "surveys-tab" &&
                            <>
                                <div className="uk-margin">
                                    <SurveyGrid />
                                </div>
                                {store.survey.all.length === 0 && <Empty />}
                            </>
                        }
                    </div>
                </ErrorBoundary>
            </div>
            <Modal modalId={MODAL_NAMES.PATIENT.SURVEY_MODAL}>
                <SurveyModal />
            </Modal>
            <Modal modalId={MODAL_NAMES.PATIENT.VIEW_SCORES_MODAL}>
                <ViewScoresModal />
            </Modal>
        </div>
    )
});

const SurveyGrid = () => {
    const { store } = useAppContext();
    const { t: msg } = useTranslation(['home']);

    const sortedData = [...store.survey.all].sort((a, b) => {
        return new Date(b.asJson.date).getTime() - new Date(a.asJson.date).getTime();
    });

    const onViewSurvey = (survey: ISurvey) => {
        store.survey.select(survey)
        showModalFromId(MODAL_NAMES.PATIENT.VIEW_SCORES_MODAL)
    };

    return (
        <div className="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-grid-small uk-margin" data-uk-grid>
            {sortedData.map((svc, index) => (
                <div key={index}>
                    <div className="sv-uk-card uk-card-default uk-card-body" onClick={() => onViewSurvey(svc.asJson)}>
                        <h5 className="uk-text-bold uk-text-uppercase">{msg("questionaires")} {index + 1}</h5>
                        <p><img alt="icon" src={icons.today} className="icon" />{formatDate(svc.asJson.date)}</p>
                    </div>
                </div>
            ))}
        </div>
    )
};
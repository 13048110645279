export const germantermsofuse = {
  termsofuse: {
    title: "Nutzungsbedingungen für die Nutzung der ALS Analytics App",
    semi: "Diese Nutzungsbedingungen wurden zuletzt am 01. November 2023 aktualisiert.",
    description: `Die APST Research GmbH („APST Research“) hat eine ALS Research Plattform / App („ALS Analytics App“) entwickelt. APST Research stellt die ALS Analytics App interessierten Nutzerinnen und Nutzern bereit, die an Amyotropher Lateralsklerose leiden („Nutzer“). In der ALS Analytics App haben Nutzer die Möglichkeit, mit Hilfe der ALS-Funktionsskala (Amyotrophic Lateral Sclerosis Functional Rating Scale revised (ALSFRSr)-Skala) („ALS Functional Rating Score“) den Status ihrer Krankheit zu erfassen und die Bewertung ihres Krankheitsverlaufs vorzunehmen. Darüber hinaus können Nutzer den Biomarker Nf-L (Neurofilament light chain) („NF-L-Wert“) mittels Blutabnahme bestimmen und sich die nach Auswertung des NF-L-Werts erzielte Verlaufsanalyse in der App anzeigen lassen („NF-L-Analyse“).`
  },
  applicationOfSubject: {
    title: "1.	Anwendung und Gegenstand der Nutzungsbedingungen",
    list: [
      {
        data: `Für die erstmalige und zukünftige Nutzung der ALS Analytics App und der darin angebotenen Inhalte, Leistungen und Funktionen (nachfolgend zusammen „Dienste“ genannt) gelten die folgenden Nutzungsbedingungen („Nutzungsbedingungen“). Hinsichtlich der Dienste ist zu unterscheiden zwischen den kostenlos angebotenen Inhalten der ALS Analytics App, die die Ermittlung des ALS Functional Rating Scores und weitere relevante Informationen und Inhalte umfassen, („kostenlose Dienste“) und dem kostenpflichtigen Angebot, den NF-L-Wert bestimmen und auswerten sowie die NF-L-Analyse anzeigen zu lassen („kostenpflichtiger Dienst“).`,
      },
      {
        data: "Mit der Registrierung in der ALS Analytics App und der Nutzung der Dienste bestätigt der Nutzer, dass er die Nutzungsbedingungen akzeptiert und sich mit deren Geltung einverstanden erklärt. Ohne die Zustimmung zu den Nutzungsbedingungen ist eine Registrierung und Nutzung der Dienste nicht möglich.",
      },
    ],
  },
  conclusionOfContract: {
    title: "2.	Vertragsschluss",
    list: [
      {
        data: `Bei Registrierung des Nutzerkontos muss der Nutzer die Geltung der Nutzungsbedingungen akzeptieren und seine datenschutzrechtliche Einwilligung erteilen. Der Vertrag über die Nutzung der ALS Analytics App und der Dienste kommt nicht vor Zusendung einer Bestätigung der Registrierung via E-Mail durch APST Research zustande. Durch Klick des Buttons „Los geht es“ gibt der Nutzer ein Angebot auf Abschluss eines Vertrags über die Nutzung der ALS Analytics App und der Dienste ab. Unmittelbar nach der Absendung des Angebots erhält der Nutzer eine Bestätigung seiner Registrierung. Die Bestätigung ist vorbehaltlich des Satzes 1 zugleich die Annahme des Vertragsangebots über die Nutzung der ALS Analytics App und der kostenlosen Dienste.`,
      },
      {
        data: `Der Vertrag über die Nutzung des kostenpflichtigen Dienstes ist Gegenstand der <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"}">Allgemeinen Geschäftsbedingungen</a> für die NF-L-Analyse`,
      }, {
        data: `Der Nutzer kann die Nutzungsbedingungen über die Browser-Funktion ausdrucken oder speichern. Der Nutzer kann sich die Nutzungsbedingungen im Rahmen des Registrierungsprozesses auch in PDF-Form herunterladen und archivieren. Mit der Bestätigung der Registrierung übersendet APST Research dem Nutzer die Nutzungsbedingen in ihrer geltenden Fassung zu. APST Research speichert die Nutzungsbedingungen nicht.`,
      },
    ],
  },

  scopeOfService: {
    title: "3.	Leistungsumfang, Leistungsänderungen",
    list: [
      {
        data: "Die ALS Analytics App und die Dienste werden über eine browserbasierte Webanwendung und als native iOS- oder Android-App angeboten, auf die über das Internet zugegriffen werden kann.",
      },
      {
        data: "Der Umfang der ALS Analytics App und der dem Nutzer zur Verfügung stehenden Dienste richtet sich danach, welche Dienste APST Research in der ALS Analytics App verfügbar macht. Zur Verfügung stehende Dienste können sein:",
        subList: [
          {
            data: "Bereitstellung der ALS-Funktionsskala zur Ermittlung des ALS Functional Rating Score. Hierbei handelt es sich um einen Fragebogen bestehend aus zwölf Fragen zu den Funktionen von Kopf, Armen, Beinen, Rumpf und Atmung mit jeweils fünf Unterfragen, deren Antworten auf einer Punkteskala von 0 bis 4 bewertet werden. Daraus ergibt sich eine Gesamtpunktzahl zwischen 48 und 0 Punkten, der ALS Functional Rating Score, mit dessen Hilfe es für Menschen mit ALS möglich ist, den Verlauf ihrer Erkrankung selbst zu erfassen. Der Verlaufswert in der ALS Analytics App bietet die Möglichkeit, die Geschwindigkeit des Krankheitsverlaufs als schnell, langsam oder durchschnittlich einzuordnen. Er dient als Orientierungshilfe für den Nutzer.",
          },
          {
            data: `Kostenpflichtige Bereitstellung der Funktion zur Bestimmung des Biomarkers Nf-L mittels Blutabnahme. Nutzer können mit Hilfe dieser Funktion ihren NF-L-Wert auswerten und die daraus erzielte Verlaufsanalyse in der App anzeigen lassen. Das Nähere zu dieser Funktion ist Gegenstand der <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"}">Allgemeinen Geschäftsbedingungen</a> für die NF-L-Analyse.`,
          },
        ]
      },
      {
        data: "APST Research entwickelt die ALS Analytics App, die Dienste und die zugrundeliegende Software sowie deren Inhalte kontinuierlich weiter. APST Research hat das Recht, jederzeit Aktualisierungen und neue Versionen zur Verbesserung der ALS Analytics App und der Dienste zu implementieren. APST Research kann Änderungen jeglicher Art an der ALS Analytics App oder den Diensten oder an der Art und Weise vornehmen, wie die ALS Analytics App oder die Dienste bereitgestellt werden. APST Research behält sich vor, den Funktionsumfang der ALS Analytics App und der Dienste jederzeit zu ändern oder zu ergänzen oder ganz oder teilweise zu beenden, Dienste einzustellen, zu entfernen oder den Zugang zu Diensten zu beschränken. Über nicht unerhebliche Beeinträchtigungen der Zugriffsmöglichkeit oder der Nutzbarkeit der ALS Analytics App oder der Dienste wird APST Research den Nutzer innerhalb einer angemessenen Frist vor dem Zeitpunkt der Änderung informieren. APST Research ist nicht verantwortlich für direkte oder indirekte Schäden, die dem Nutzer dadurch entstehen, dass die ALS Analytics App oder Dienste geändert, modifiziert, beendet oder eingeschränkt werden.",
      },
      {
        data: "APST Research weist darauf hin, dass es sich bei der ALS Analytics App und den Diensten nicht um ein Medizinprodukt handelt.",
      },
    ],
  },
  accessRequirements: {
    title: "4.	Zugangsvoraussetzungen, Nutzerkonto",
    list: [
      {
        data: "Der Nutzer ist verpflichtet, die gesamte Software und Hardware bereitzuhalten, die er für den Zugang zur ALS Analytics App und zu den Diensten benötigt, wie z.B. ein Endgerät und eine Internetverbindung. Unter Umständen muss zusätzliche Software heruntergeladen oder installiert werden (z.B. bestimmte Software-Plug-ins oder Software-Anwendungen), um auf bestimmte Dienste oder Dokumente zugreifen zu können. Für die Nutzung der nativen App muss der Nutzer die Anwendung über den App-Store Anbieter Google Play Store oder den Apple Store herunterladen.",
      },
      {
        data: "Um die Dienste nutzen zu können, muss der Nutzer in der ALS Analytics App mit einem Nutzerkonto registriert und eingeloggt sein. Für die Neuregistrierung werden die E-Mail-Adresse des Nutzers und ein Passwort sowie die datenschutzrechtliche Einwilligung des Nutzers benötigt. Nach Abschluss der Registrierung kann sich der Nutzer einloggen, weitere Informationen zu seiner Person (wie Alter, Geschlecht und Erkrankungsbeginn) angeben und Zugang zur ALS Analytics App und für ihn passende Dienste erhalten.",
      }, {
        data: "Nur unbeschränkt geschäftsfähige natürliche Personen, die mindestens 16 Jahre alt sind und nicht unter Betreuung stehen, dürfen ein Nutzerkonto registrieren. Der Nutzer hat im Rahmen der Registrierung und der weiteren Nutzung der ALS Analytics App richtige, wahrheitsgemäße und, soweit erforderlich, vollständige Angaben zu machen. APST Research behält sich die Überprüfung der bei der Registrierung angegebenen Daten durch geeignete Maßnahmen ausdrücklich vor. APST Research ist berechtigt, die Nutzung der ALS Analytics App und der Dienste von weiteren Voraussetzungen abhängig zu machen. Das Nutzerkonto ist personengebunden und darf nur vom Nutzer persönlich genutzt werden. Die Zugangsdaten zum Nutzerkonto sind geheim zu halten und jeglicher Zugang durch Dritte durch geeignete Maßnahmen zu verhindern. Der Nutzer ist für sämtliche Aktivitäten verantwortlich, die über sein Nutzerkonto erfolgen, und haftet APST Research entsprechend den gesetzlichen Bestimmungen. APST Research haftet nicht für Schäden, die dem Nutzer daraus entstehen, dass Dritte die Zugangsdaten oder das Nutzerkonto mit oder ohne Kenntnis des Nutzers nutzen.",
      },
    ],
  },
  obligationsOfUser: {
    title: "5.	Pflichten des Nutzers",
    list: [
      {
        data: "Der Nutzer darf die ALS Analytics App ausschließlich zu dem Zweck nutzen, zu welchem ihm der Zugang zu den Diensten gewährt wurde. Jede darüber hinaus gehende Nutzung der ALS Analytics App und der Dienste ist untersagt. ",
      },
      {
        data: "Der Nutzer darf insbesondere keine Inhalte in der ALS Analytics App einstellen, die strafbar sein können (insbesondere Volksverhetzung, Beleidigung, Verleumdung, Bedrohung), als pornographisch, vulgär oder obszön, belästigend oder in sonstiger Weise anstößig anzusehen sind, verfassungsfeindlich, extremistisch, rassistisch oder fremdenfeindlich sind oder Inhalte darstellen, die von verbotenen Gruppierungen stammen, in Rechte Dritter (insbesondere Persönlichkeitsrechte, Urheberrechte, Markenrechte, Patentrechte oder sonstige Rechte Dritter) eingreifen.",
      }, {
        data: "Der Nutzer hat die ALS Analytics App und die Dienste so zu nutzen, dass keine Beeinträchtigungen, Überlastungen oder Schäden auftreten und der mit der ALS Analytics App und den Diensten verfolgte Zweck weder gefährdet noch umgangen wird. Der Nutzer wird weder selbst noch durch Dritte Sicherheitsvorkehrungen der ALS Analytics App umgehen oder verändern. ",
      },
    ],
  },
  blockingOfAccount: {
    title: "6.	Sperrung des Nutzerkontos",
    list: [
      {
        data: "APST Research behält sich das Recht vor, in ihrem alleinigen Ermessen den Zugang des Nutzers zu jedem Teil der ALS Analytics App oder der Dienste jederzeit und ohne Vorankündigung zu beenden oder einzuschränken. ",
      },
      {
        data: "APST Research ist insbesondere zur Sperrung oder Löschung eines Nutzerkontos berechtigt, wenn der Nutzer gegen eine nach diesen Nutzungsbedingungen bestehende Pflicht verstößt oder wenn APST Research Anlass hat, zu vermuten, dass ein solcher Pflichtverstoß vorliegt.",
      }
    ],
  },
  rightsOfUser: {
    title: "7.	Nutzungsrechte des Nutzers",
    list: [
      {
        data: "Alle Rechte an der ALS Analytics App und an den Diensten, einschließlich den Rechten an damit zusammenhängender Software, Datenbanken, Grafiken, Benutzeroberflächen, Designs sowie anderen Inhalte, Bezeichnungen, Namen und Marken, verbleiben bei APST Research oder bei etwaigen Dritten, die APST Research Rechte im Zusammenhang mit der ALS Analytics App oder den Diensten eingeräumt haben. ",
      },
      {
        data: "Der Nutzer erhält ein einfaches, nicht übertragbares, zeitlich auf die Dauer des Nutzungsvertrags beschränktes Recht, die ALS Analytics App und die Dienste für ihren jeweiligen bestimmungsmäßigen Zweck zu nutzen. Der Nutzer darf die ALS Analytics App und die Dienste ausschließlich für private Zwecke nutzen. Rechte, die dem Nutzer nicht ausdrücklich eingeräumt werden, stehen ihm nicht zu.",
      }, {
        data: "Insbesondere darf der Nutzer die ALS Analytics App und die Dienste oder Teile hiervon, einschließlich des Quellcodes oder eine Kopie, Adaption, Transkription oder einen zusammengeführten Teil davon, nicht kopieren, übersetzen, verändern, zurückentwickeln, dekompilieren und verbreiten. Der Nutzer ist nicht berechtigt, die ihm im Rahmen dieser Nutzungsbedingungen gewährten Rechte zu übertragen, zu verleasen, abzutreten, zu vermieten oder Unterlizenzen zu vergeben oder die Dienste anderen Personen zur Nutzung zur Verfügung zu stellen.",
      },
    ],
  },
  rightsOfResponsibility: {
    title: "8.	Nutzungsrechte und Verantwortung für vom Nutzer eingestellte Inhalte",
    list: [
      {
        data: "Durch das Einstellen von Inhalten (etwa Bilder, Texte, Audio- und Videomaterial) in die ALS Analytics App gewährt der Nutzer APST Research ein einfaches, widerrufliches, unentgeltliches und zeitlich unbegrenztes, unterlizenzierbares Nutzungsrecht, die Inhalte für die Zwecke zu verwenden, zu denen sie in der ALS Analytics App eingestellt wurden, insbesondere, die Inhalte zu kopieren, zu übermitteln, öffentlich abzuspielen, öffentlich vorzuführen, zu vervielfältigen, zu bearbeiten, zu übersetzen und neu zu formatieren. ",
      },
      {
        data: "Durch Einstellen von Inhalten sichert der Nutzer zu und gewährleistet, dass er Inhaber sämtlicher Rechte an den Inhalten ist, die notwendig sind, um die Inhalte einzustellen und APST Research die Nutzungsrechte nach diesen Nutzungsvereinbarungen einzuräumen. ",
      }, {
        data: "Es ist APST Research nicht möglich, Inhalte zu kontrollieren, die von Nutzern in der ALS Analytics App eingestellt werden. Dies gilt insbesondere hinsichtlich der Richtigkeit der Inhalte, der Rechtmäßigkeit, Aktualität und Qualität sowie deren Eignung für einen bestimmten Zweck. APST Research haftet nicht für von Nutzern eingestellte Inhalte.",
      },
    ],
  },
  costs: {
    title: "9.	Kosten",
    list: [
      {
        data: `Für die Registrierung eines Nutzerkontos und die Nutzung der ALS Analytics App und der kostenlosen Dienste gemäß Ziffer 3.2.1 und 3.2.2 entstehen dem Nutzer keine Kosten. Für die Nutzung der kostenpflichtigen Dienste gemäß Ziffer 3.2.3 entstehen dem Nutzer Kosten, die in den <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"}">General Terms and Conditions</a> für die NF-L-Analyse [AGB bitte verlinken] spezifiziert sind.`,
      },
      {
        data: `Die Registrierung und Nutzung der ALS Analytics App und der Dienste setzen die Bereitstellung der personenbezogenen Daten des Nutzers und die datenschutzrechtliche Einwilligung in die Verarbeitung voraus. Informationen zur Verarbeitung personenbezogener Daten durch APST Research können den <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "data-privacy-policy"}">Datenschutzhinweisen</a> entnommen werden.`,
      }
    ],
  },
  revocationsAndTermination: {
    title: "10.	Widerruf, Kündigung, Löschung des Nutzerkontos",
    list: [
      {
        data: "Als Verbraucher steht dem Nutzer ein gesetzliches Widerrufsrecht zu. Es gilt die Widerrufsbelehrung",
      },
      {
        data: `Der Nutzer hat die Möglichkeit, jederzeit sein Nutzerkonto zu löschen und den Nutzungsvertrag zu kündigen, in dem der Nutzer in seinem Nutzungskonto unter „Einstellungen“ den Kündigungs-Button anklickt. Der Nutzer erhält von APST Research eine E-Mail mit einer Kündigungsbestätigung an die bei APST Research hinterlegte E-Mailadresse. Mit der Kündigung und Löschung des Nutzerkontos endet der Nutzungsvertrag und das Recht des Nutzers, die ALS Analytics App und die Dienste zu nutzen.`,
      }, {
        data: "Der Nutzer hat die Möglichkeit, jederzeit sein Nutzerkonto zu löschen und den Nutzungsvertrag zu kündigen, in dem der Nutzer in seinem Nutzungskonto unter „Einstellungen“ den Kündigungs-Button anklickt. Der Nutzer erhält von APST Research eine E-Mail mit einer Kündigungsbestätigung an die bei APST Research hinterlegte E-Mailadresse. Mit der Kündigung und Löschung des Nutzerkontos endet der Nutzungsvertrag und das Recht des Nutzers, die ALS Analytics App und die Dienste zu nutzen.",
      },
    ],
  },

  noWarranty: {
    title: "11.	Keine Gewährleistung",
    list: [
      {
        data: "APST Research ist stets bemüht, einen ordnungsgemäßen Betrieb der ALS Analytics App und der Dienste sicherzustellen, haftet dem Nutzer aber nicht für die durchgängige Erreichbarkeit, Verfügbarkeit und Funktionalität der ALS Analytics App und der Dienste.",
      },
      {
        data: "APST Research hat das Recht, Maßnahmen zu ergreifen, die den Zugang zu der ALS Analytics App oder den Diensten beeinträchtigen, wenn dies z.B. aus technischen, Wartungs- oder Sicherheitsgründen erforderlich ist.",
      },
      {
        data: "APST Research haftet dem Nutzer insbesondere nicht für Leistungs- oder Verfügbarkeitsprobleme",
        subList: [
          {
            data: "Aufgrund von Faktoren, die außerhalb der Kontrolle von APST Research liegen (z.B. Naturkatastrophen, Kriege, Terroranschläge, Aufstände oder staatliche Maßnahmen),",
          },
          {
            data: "Die durch Services, Hardware oder Software des Nutzers oder von Dritten verursacht wurden,",
          },
          {
            data: "Die durch die Verwendung eines Dienstes verursacht wurden, nachdem APST Research den Nutzer angewiesen hat, die Verwendung eines Dienstes zu ändern oder zu aktualisieren, und er die Verwendung nicht wie angewiesen geändert oder aktualisiert hat,",
          },
          {
            data: "Während Vorabversions-, Beta- und Testdiensten (wie von APST Research festgelegt), ",
          },
          {
            data: "Die durch die nicht autorisierte Handlung oder Unterlassung des Nutzers oder durch andere Personen verursacht wurden, die sich mithilfe der Zugangsdaten oder Geräte des Nutzers Zugriff auf das Netzwerk von APST Research verschafft haben,",
          },
          {
            data: "Die durch das Versäumnis des Nutzers verursacht wurden, erforderliche Konfigurationen einzuhalten, unterstützte Plattformen zu verwenden und Richtlinien für die akzeptable Nutzung einzuhalten, oder",
          },
          {
            data: "Auf einer Sperrung des Nutzerkontos gemäß Ziffer 6 beruhen.",
          },
        ]

      },
    ],
  },
  liability: {
    title: "12.	Haftung ",
    list: [
      {
        data: "APST Research haftet unbeschadet der nachfolgenden Haftungsbeschränkungen uneingeschränkt für Schäden an Leben, Körper und Gesundheit, die auf einer fahrlässigen oder vorsätzlichen Pflichtverletzung von APST Research, ihrer gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen, sowie für Schäden, die von der Haftung nach dem Produkthaftungsgesetz umfasst werden, sowie für Schäden, die auf vorsätzlichen oder grob fahrlässigen Vertragsverletzungen sowie Arglist von APST Research, ihren gesetzlichen Vertretern oder Erfüllungsgehilfen beruhen.",
      },
      {
        data: "Bei Fahrlässigkeit beschränkt sich die Haftung von APST Research auf den Ersatz des im Rahmen der Nutzung der ALS Analytics App und der Dienste typischen vorhersehbaren Schadens. Bei einfacher Fahrlässigkeit haftet APST Research jedoch nur, wenn APST Research eine Pflicht verletzt hat, deren Erfüllung eine ordnungsgemäße und unter Berücksichtigung der Interessen beider Parteien angemessene Nutzung der ALS Analytics App und der Dienste, überhaupt erst ermöglicht hat und auf deren Einhaltung der Nutzer vertrauen durfte.",
      },
      {
        data: "APST Research haftet auch nicht für Störungen und Qualitätsverlust der ALS Analytics App und der Dienste infolge der Datenübertragung im Internet, welche APST Research nicht zu vertreten hat und die die Nutzung von internetbasierten Leistungen erschweren oder verhindern.",
      },
      {
        data: "Eine weitergehende Haftung von APST Research ist ohne Rücksicht auf die Rechtsnatur des geltend gemachten Anspruchs ausgeschlossen. Soweit die Haftung von APST Research ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung ihrer Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen.",
      },
    ],
  },

  dataPrivacy: {
    title: "13.	Datenschutz",
    description: `APST Research weist darauf hin, dass bei der Nutzung der ALS Analytics App personenbezogene Daten des Nutzers verarbeitet werden. APST Research hält die geltenden Datenschutzbestimmungen ein. Informationen darüber, wie personenbezogene Daten verarbeitet werden, können den <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "data-privacy-policy"}">Datenschutzhinweisen</a> entnommen werden.`
  },

  applicableLaw: {
    title: "14.	Anwendbares Recht, Gerichtsstand, Streitbeilegung",
    list: [
      {
        data: "Diese Nutzungsbedingungen, ihr Gegenstand und ihre Entstehung (sowie alle außervertraglichen Streitigkeiten oder Ansprüche) unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (CISG), soweit zwingendes Verbraucherschutzrecht nicht etwas anderes vorschreibt.",
      },
      {
        data: "Gerichtsstand ist in Berlin, soweit der Nutzer Kaufmann i. S. des Handelsgesetzbuchs, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist. Das Gleiche gilt für Nutzer, die keinen allgemeinen Gerichtsstand in Deutschland oder einem anderen EU-Mitgliedstaat oder ihren Wohnsitz nach Vertragsschluss ins Ausland verlegt haben oder deren Wohnsitz oder gewöhnlicher Aufenthaltsort bei Klageerhebung nicht bekannt ist. Im Übrigen gelten für die örtliche und die internationale Zuständigkeit die anwendbaren gesetzlichen Bestimmungen.",
      },
      {
        data: `Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Den Link zur Plattform finden Sie hier: ec.europa.eu/consumers/odr/. APST Research ist nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.`,
      },
    ],
  },

  invalidityOfIndividual: {
    title: "15.	Unwirksamkeit einzelner Bestimmungen, Vertragslücke",
    list: [
      {
        data: "Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam oder undurchführbar sein oder werden, so wird dadurch die Wirksamkeit der Nutzungsbedingungen im Übrigen nicht berührt. An die Stelle der undurchführbaren oder unwirksamen Bestimmung treten die jeweiligen gesetzlichen Vorschriften. Sofern Gesetzesrecht im jeweiligen Fall nicht zur Verfügung steht, soll diejenige wirksame oder durchführbare Regelung an die Stelle der unwirksamen oder undurchführbaren Bestimmung treten, deren Wirkungen der Zielsetzung am nächsten kommen, die APST Research und der Nutzer mit der unwirksamen bzw. undurchführbaren Bestimmung verfolgt haben.",
      },
      {
        data: "15.2	Ziffer 15.1 gilt entsprechend für den Fall, dass sich eine oder mehrere der Bedingungen als lückenhaft erweisen.",
      },
    ],
  },

  changesToTerms: {
    title: "16.	Änderungen des Nutzungsbedingungen",
    list: [
      {
        data: "APST Research behält sich das Recht vor, diese Nutzungsbedingungen jederzeit ohne Angabe von Gründen mit Wirkung für die Zukunft zu ändern oder zu ergänzen, wenn",
        subList: [
          {
            data: "Dies zur Anpassung an geänderte, von APST Research nicht zu vertretende Umstände (insbesondere Gesetzesänderungen und bei Vertragsschluss nicht vorhersehbare Schwierigkeiten bei der Durchführung des Nutzungsvertrages) erforderlich und die Änderung für den Nutzer zumutbar ist;",
          },
          {
            data: "Die Änderung lediglich vorteilhaft für den Nutzer ist;",
          },
          {
            data: "Die Änderungen oder Anpassungen ohne wesentlichen Einfluss auf Funktionen der Dienste oder rein technischer oder organisatorischer Art sind;",
          },
          {
            data: `APST Research zusätzliche, gänzlich neue Dienste oder Leistungen einführt, die einer Beschreibung in den Bedingungen bedürfen, es sei denn, dass dies für das laufende Nutzungsverhältnis nachteilig wäre. Eine beabsichtigte Änderung wird dem Nutzer an die der APST Research zuletzt überlassene E-Mailadresse zugesandt oder über eine Bekanntmachung in der App mitgeteilt. Die Änderung tritt spätestens 14 Tage, nachdem die Änderung in der App veröffentlicht wurde oder der Nutzer auf andere Weise auf die Änderung aufmerksam gemacht wurde, in Kraft, wenn der Nutzer der Änderung nicht innerhalb von 14 Tagen widerspricht. Es wird davon ausgegangen, dass der Nutzer die neuen Bedingungen akzeptiert hat, wenn die Nutzung der ALS Analytics App und der Dienste nach Ablauf dieser 14 Tage erfolgt.`,
          },
        ]
      },

      {
        data: `16.2	Im Falle eines Widerspruchs innerhalb der in Ziffer 16.1 genannten Frist kann APST Research das Vertragsverhältnis mit dem Nutzer fristlos kündigen. Sollte APST Research das bisherige Vertragsverhältnis nach dem wirksamen Widerspruch nicht kündigen, so behalten die bisherigen Nutzungsbedingungen im Verhältnis zu dem entsprechenden Nutzer unverändert ihre Geltung. Die jeweils aktuelle Version der Nutzungsbedingungen kann durch Anklicken des Hyperlinks „Nutzungsbedingungen“ auf der Webseite <a target="_blank" href='https://www.apstresearch.com'> eingesehen werden.`,
      },
    ],
  },
  contact: {
    title: "17.	Kontakt",
    description: "Die APST Research ist erreichbar unter",
    list: [
      {
        data: "APST Research GmbH",
      },
      {
        data: "Westhafenstraße 1",
      }, {
        data: "13353 Berlin, Germany",
      },
      {
        data: `E-Mail: <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>`,
      }, {
        data: `Telefon: <a href="tel:+49(0)30-81031410">+49 (0) 30-81031410</a>`,
      },
    ],
  },
};

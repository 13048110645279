import { makeObservable, runInAction } from "mobx";
import SnackbarStore from "./SnackbarStore";

export default class UiStore {
  title: string = "";
  backButton = false;
  backPath: null | string = null;
  snackbar = new SnackbarStore(this);

  constructor() {
    makeObservable(this, {
      title: true,
      backButton: true,
    });
  }

  setTitle(title: string) {
    runInAction(() => {
      this.title = title;
    });
  }
}

export const germanTermsAndConditions = {
  termsofuse: {
    title: "Allgemeine Geschäftsbedingungen für die NF-L-Analyse",
    semi: "Diese Allgemeinen Geschäftsbedingungen wurden zuletzt am 01. Oktober 2023 aktualisiert",
    description: ``
  },
  applicationOfSubject: {
    title: "1.	Allgemeines, Anwendung und Gegenstand der AGB",
    list: [
      {
        data: `Die APST Research GmbH („APST Research“) hat eine ALS Research Plattform / App („ALS Analytics App“) entwickelt. APST Research stellt die ALS Analytics App interessierten Nutzerinnen und Nutzern, die an Amyotropher Lateralsklerose leiden („Nutzer“), nach Maßgabe der <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "terms-of-use"}">Nutzungsbedingungen</a> der ALS Analytics App bereit. In der ALS Analytics App haben Nutzer Zugriff auf kostenfreie Dienste. Darüber hinaus können Nutzer kostenpflichtig den Biomarker Nf-L (Neurofilament light chain) („NF-L-Wert“) mittels Blutabnahme bestimmen und sich die nach Auswertung des NF-L-Werts erzielte Verlaufsanalyse in der App anzeigen lassen („NF-L-Analyse“). `,
      },
      {
        data: `Diese Allgemeinen Geschäftsbedingungen („AGB“) in ihrer zum Zeitpunkt des Vertragsschlusses gültigen Fassung finden Anwendung auf sämtliche Verträge zwischen der APST Research und dem Nutzer, die über die in der ALS Analytics App angebotene NF-L-Analyse zustande kommen.`,
      }, {
        data: `Mit dem Absenden seiner Bestellung der kostenpflichtigen Dienste bestätigt der Nutzer, dass er die AGB akzeptiert und dass er sich mit deren Geltung einverstanden erklärt. Hiermit werden die AGB Bestandteil des Vertrags über die von APST Research im Rahmen der ALS Analytics App angebotenen kostenpflichtigen Dienste. Ohne die Zustimmung zu den AGB ist eine Bestellung der NF-L-Analyse in der ALS Analytics App nicht möglich.`,
      },
    ],
  },
  conclusionOfContract: {
    title: "2.	Bestellung, Vertragsschluss, Vertragstext",
    list: [
      {
        data: `Die Darstellung der Funktionalität NF-L-Analyse in der ALS Analytics App stellt kein bindendes Angebot der APST Research dar. Durch das Anklicken des Buttons »kostenpflichtig bestellen« gibt der Nutzer ein Angebot auf Abschluss eines Vertrags über die von APST Research im Rahmen der ALS Analytics App angebotenen kostenpflichtigen NF-L-Analyse ab. Im Anschluss wird der Nutzer an den Zahlungsdienstleister weitergeleitet und muss dort seine Zahlungsinformationen angeben. Unmittelbar nach dem Absenden der Bestellung erhält der Nutzer eine automatische Bestätigung seiner Bestellung. Die Bestätigung ist nicht die Annahme des Vertragsangebots. Die Annahme erfolgt in einer gesonderten E-Mail, nachdem APST Research die Bestätigung des Zahlungseingangs für die NF-L-Analyse erhalten hat.`,
      },
      {
        data: `Der Nutzer kann diese AGB über die Browser-Funktion ausdrucken oder speichern. Der Nutzer kann sich dieses Dokument im Rahmen des Bestellprozesses auch in PDF-Form herunterladen und archivieren. Mit der Bestätigung der Bestellung übersendet APST Research dem Nutzer die AGB in ihrer geltenden Fassung zu. APST Research speichert den Vertragstext nicht.`,
      }
    ],
  },
  scopeOfService: {
    title: "3.	Leistungsbeschreibung",
    list: [
      {
        data: `Der in der ALS Analytics App angebotene kostenpflichtige Dienst der NF-L-Analyse bietet Nutzern die Möglichkeit, den Verlaufsmarker Nf-L (NF-L-Wert) mittels Blutabnahme bestimmen und sich die nach Auswertung des NF-L-Werts erzielte Verlaufsanalyse in der App anzeigen zu lassen. Der Dienst umfasst im Einzelnen die folgenden Leistungen:`,
        subList: [
          {
            data: `Die Zusendung eines Blutabnahme-Kits an den Nutzer einschließlich eines Informationsblatts für den die Blutabnahme vornehmenden Arzt.`,
          },
          {
            data: `Die Versendung der Blutprobe durch den vom Nutzer konsultierten Arzt an APST Research und ein mit der Untersuchung der Blutprobe beauftragtes Labor.`,
          },
          {
            data: `Die Untersuchung der Blutprobe in einem Labor zur Ermittlung des NF-L-Werts einschließlich der Überlassung der Werte an APST Research.`,
          },
          {
            data: `Die Auswertung des Untersuchungsergebnisses, insbesondere des NF-L-Werts, seitens APST Research durch in Verhältnissetzung zu anderen Werten, u.a. dem in der ALS Analytics App durch den Nutzer ermittelten ALS Functional Rating Score.`,
          },
          {
            data: `Die Anzeige der NF-L-Analyse in der ALS Analytics App.`,
          },
        ]
      },

      {
        data: `Für die Blutabnahme sucht der Nutzer einen Arzt seiner Wahl auf. Die ärztlichen Leistungen im Rahmen der Blutabnahme sind sogenannte Selbstzahlerleistungen und nicht von dem in der ALS Analytics App angebotenen kostenpflichtigen Dienst der NF-L-Analyse umfasst.`,
      },
      {
        data: `Der Nutzer kann die NF-L-Analyse jeweils im Abstand von sechs Monaten wiederholen. Für jede Bestellung gelten diese Allgemeinen Geschäftsbedingungen.`,
      }
    ],
  },
  accessRequirements: {
    title: "4.	Zugangsvoraussetzungen",
    description: `Um die in der ALS Analytics App angebotene NF-L-Analyse nutzen zu können, muss sich der Nutzer nach Maßgabe der <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "terms-of-use"}">Nutzungsbedingungen</a> ALS Analytics App  in der ALS Analytics App registriert und ein Nutzerkonto angelegt haben.`
  },
  revocation: {
    title: "5.	Widerruf",
    description: `Als Verbraucher steht dem Nutzer ein gesetzliches Widerrufsrecht zu: <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "revocation"}">Widerrufsbelehrung</a>.`,
  },
  costOfDelivery: {
    title: "6.	Kosten, Lieferung",
    list: [
      {
        data: "Die Kosten für die Inanspruchnahme der NF-L-Analyse betragen 120,00 Euro einschließlich Umsatzsteuer. Die Kosten umfassen die Leistungen gemäß Ziffer 3.1.",
      },
      {
        data: "Der Betrag von 120,00 Euro im Sinne der Ziffer 6.1 umfasst dagegen nicht die ärztlichen Leistungen im Rahmen der Blutabnahme gemäß Ziffer 3.2. Die Kosten der Blutabnahme wird der Arzt dem Nutzer gesondert als Selbstzahlerleistung in Rechnung stellen. APST Research ist in diese Vorgänge nicht eingebunden. ",
      },
      {
        data: "Für die Zahlung an APST Research nach Ziffer 6.1 können ausschließlich die bei Abschluss des Bestellprozesses in der ALS Analytics App angezeigten Zahlungsarten verwendet werden. APST Research behält sich bei jeder Bestellung vor, bestimmte Zahlarten nicht anzubieten oder auf andere Zahlarten zu verweisen.",
      },
      {
        data: "Das bestellte Produkt wird an die im Rahmen des Bestellprozesses vom Nutzer angegebene Lieferadresse geliefert.",
      },
    ],
  },
  liability: {
    title: "7.	Haftung",
    list: [
      {
        data: `APST Research haftet unbeschadet der nachfolgenden Haftungsbeschränkungen uneingeschränkt für Schäden an Leben, Körper und Gesundheit, die auf einer fahrlässigen oder vorsätzlichen Pflichtverletzung von APST Research, ihrer gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen, sowie für Schäden, die von der Haftung nach dem Produkthaftungsgesetz umfasst werden, sowie für Schäden, die auf vorsätzlichen oder grob fahrlässigen Vertragsverletzungen sowie Arglist von APST Research, ihren gesetzlichen Vertretern oder Erfüllungsgehilfen beruhen. `,
      },
      {
        data: `Bei Fahrlässigkeit beschränkt sich die Haftung von APST Research auf den Ersatz des im Rahmen der Nutzung der ALS Analytics App und der Dienste typischen vorhersehbaren Schadens. Bei einfacher Fahrlässigkeit haftet APST Research jedoch nur, wenn APST Research eine Pflicht verletzt hat, deren Erfüllung eine ordnungsgemäße und unter Berücksichtigung der Interessen beider Parteien angemessene Nutzung der ALS Analytics App und der Dienste, überhaupt erst ermöglicht hat und auf deren Einhaltung der Nutzer vertrauen durfte.`,
      }, {
        data: `Eine weitergehende Haftung von APST Research ist ohne Rücksicht auf die Rechtsnatur des geltend gemachten Anspruchs ausgeschlossen. Soweit die Haftung von APST Research ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung ihrer Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen.`,
      },
    ],
  },
  dataPrivacy: {
    title: "8.	Datenschutz",
    description: `APST Research weist darauf hin, dass bei der Nutzung der ALS Analytics App und der Funktionalität der NF-L-Analyse personenbezogene Daten des Nutzers verarbeitet werden. APST Research hält die geltenden Datenschutzbestimmungen ein. Informationen darüber, wie personenbezogene Daten verarbeitet werden, können den <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "data-privacy-policy"}">Datenschutzhinweisen</a> entnommen werden.`
  },
  changesToTerms: {
    title: "9.	Änderungen der AGB",
    list: [
      {
        data: `APST Research behält sich das Recht vor, diese AGB jederzeit ohne Angabe von Gründen mit Wirkung für die Zukunft zu ändern oder zu ergänzen, wenn dies zur Anpassung an geänderte, von APST Research nicht zu vertretende Umstände (insbesondere Gesetzesänderungen und bei Vertragsschluss nicht vorhersehbare Schwierigkeiten bei der Durchführung des Nutzungsvertrages) erforderlich und die Änderung für den Nutzer zumutbar ist oder die Änderung lediglich vorteilhaft für den Nutzer ist. Eine beabsichtigte Änderung wird dem Nutzer an die der APST Research überlassene E-Mailadresse zugesandt oder über eine Bekanntmachung in der ALS Analytics App mitgeteilt. Änderungen treten spätestens 14 Tage, nachdem die Änderung in der App veröffentlicht wurde oder der Nutzer auf andere Weise auf die Änderung aufmerksam gemacht wurde, in Kraft, wenn der Nutzer der Änderung nicht innerhalb von 14 Tagen widerspricht. Es wird davon ausgegangen, dass der Nutzer die neuen Bedingungen akzeptiert hat, wenn die Nutzung des Dienstes nach Ablauf dieser 14 Tage erfolgt.`,
      },
      {
        data: `Im Falle eines Widerspruchs innerhalb der Frist kann APST Research das Vertragsverhältnis mit dem Nutzer fristlos kündigen. Sollte APST Research das bisherige Vertragsverhältnis nach dem wirksamen Widerspruch nicht kündigen, so behalten die bisherigen AGB im Verhältnis zu dem entsprechenden Nutzer unverändert ihre Geltung.`,
      }
    ],
  },
  applicableLaw: {
    title: "10.	Anwendbares Recht, Gerichtsstand, Streitbeilegung",
    list: [
      {
        data: `Diese AGB, ihr Gegenstand und ihre Entstehung (sowie alle außervertraglichen Streitigkeiten oder Ansprüche) unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (CISG), soweit zwingendes Verbraucherschutzrecht nicht etwas anderes vorschreibt.`,
      },
      {
        data: `Gerichtsstand ist in Berlin, soweit der Nutzer Kaufmann i. S. des Handelsgesetzbuchs, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist. Das Gleiche gilt für Nutzer, die keinen allgemeinen Gerichtsstand in Deutschland oder einem anderen EU-Mitgliedstaat oder ihren Wohnsitz nach Vertragsschluss ins Ausland verlegt haben oder deren Wohnsitz oder gewöhnlicher Aufenthaltsort bei Klageerhebung nicht bekannt ist. Im Übrigen gelten für die örtliche und die internationale Zuständigkeit die anwendbaren gesetzlichen Bestimmungen.`,
      }, {
        data: `Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Den Link zur Plattform finden Sie hier: <a className="uk-text-primary gtc-text" target="_blank" href="https://ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</a>. APST Research ist nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.`,
      },
    ],
  },
  finalProvisions: {
    title: "11.	Schlussbestimmungen",
    list: [
      {
        data: `Sollten einzelne Bestimmungen dieser AGB unwirksam oder undurchführbar sein oder nach Vertragsschluss unwirksam oder undurchführbar werden, so wird dadurch die Wirksamkeit des Vertrages im Übrigen nicht berührt. An die Stelle der undurchführbaren oder unwirksamen Bestimmung treten die jeweiligen gesetzlichen Vorschriften. Sofern Gesetzesrecht im jeweiligen Fall nicht zur Verfügung steht, soll diejenige wirksame oder durchführbare Regelung an die Stelle der unwirksamen oder undurchführbaren Bestimmung treten, deren Wirkungen der wirtschaftlichen Zielsetzung am nächsten kommen, die APST Research und der Nutzer mit der unwirksamen bzw. undurchführbaren Bestimmung verfolgt haben.`,
      },
      {
        data: "Ziffer 11.1 gilt entsprechend für den Fall, dass sich eine oder mehrere der Bedingungen als lückenhaft erweisen.",
      }
    ],
  },
};

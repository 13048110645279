import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";
import PatientModel, { IPatient } from "../models/Patient";

export default class PatientStore {
  protected store: AppStore;
  patient: PatientModel | null = null;

  constructor(store: AppStore) {
    makeObservable(this, {
      patient: true,
      patientJson: true,
    });
    this.store = store;
  }

  load(item: IPatient) {
    runInAction(() => {
      this.patient = new PatientModel(this.store, item);
    });
  }

  get patientJson() {
    return this.patient ? this.patient.asJson : null;
  }

  logOut() {
    runInAction(() => {
      this.patient = null;
    });
  }
}
import dashboard from "../../../icons/dashboard.svg";
import useraccount from "../../../icons/useracount.svg";
import account_circle from "../../../icons/account_circle.svg";
import chevron_right from "../../../icons/chevron_right.svg";
import pen from "../../../icons/pen.svg";
import local_library from "../../../icons/local_library.svg";
import statistics from "../../../icons/statistics.svg";
import shopping_basket from "../../../icons/shopping_basket.svg";
import logout from "../../../icons/logout.svg";
import help_info from "../../../icons/help_info.svg";
import today from "../../../icons/today.svg";
import security from "../../../icons/security.svg";
import cancel from "../../../icons/cancel.svg";

const icons = {
  dashboard,
  useraccount,
  account_circle,
  chevron_right,
  pen,
  local_library,
  statistics,
  shopping_basket,
  logout,
  help_info,
  today,
  security,
  cancel
};

export default icons;

export const englishinfolibrary = {
  aboutAllAbbrev: {
    title: "ALS, NFL and ALSFRS",
    description: `The protein <b>neurofilament light chain (NfL)</b> is an important component in the structure of nerve cells (neurons) and their extensions (axons) in the brain and spinal cord as well as nerve cords in the human body. When nerve cells are damaged, as is the case with the breakdown of motor neurons in <b>ALS</b>, NfL is released into the cerebrospinal fluid (CSF) and blood (serum).
              NfL can be detected in blood serum and cerebrospinal fluid using special analysis methods.
              <br /><br />
              NfL is considered a <b>prognostic and progression marker of ALS</b>. The NfL value in the blood is - in addition to the ALS symptoms - a characteristic of the ALS disease course. A high blood serum NfL value is often associated with a higher speed in the progression of ALS symptoms.
              <br /><br />
              The blood NfL level increases with age. Studies have agreed on threshold values above which the NfL level is pathological in ALS. The diagram in the ALS Analytics app shows the individual NfL values in the blood over the course of the disease as a function of age. NfL values above the blue area in the diagram are considered to be definitely pathological.
              <br /><br />
              According to current scientific findings, the NfL value in the blood is quite stable, so that a change in the serum concentration can only be expected over the course of several months. Therefore, the interval between NfL examinations should be at around 6 months.
              <br /><br />
              The <b>ALS Functional Rating Scale (ALSFRS)</b> is a clinical scale used to assess the severity and progression of ALS. The ALSFRS scale is a questionnaire consisting of 12 questions on ALS-typical symptoms and 5 response options (0-4 points). The questions can be answered by both the doctor and the patient.
              <br /><br />
              The online patient assessment of the ALSFRS makes it possible to record the individual course of the disease and differentiate between different forms of ALS progression. A distinction is made between different speeds of progression such as fast or slow progression, early or late impairment of respiratory function, early or late bulbar symptoms, spatial limitation or spread of
              paresis.
              <br /><br />
              The <b>progression value</b> in the ALS Analytics app offers people with ALS the opportunity to classify the speed of their own disease progression as fast, slow or average. It serves as a guide for the person affected as well as their doctors and therapists. The progression value is calculated from the current ALSFRS total value and the date of onset of disease symptoms.
              <br /><br />
              The self-assessment data from the ALSFRS is only visible in your ALS Analytics App account.
              <br /><br />
              With your consent, the data can also be made available to university institutions and manufacturers of medical devices or medicines for <b>research and development purposes</b>. For this purpose, the research institutions and manufacturers receive data only in pseudonymized or anonymized form.`,
  },
  howToOrder: {
    title: "How to order",
    description: `
              You can order a test for NfL by visiting our online store using the ALS Analytics app.
              <br /><br />
              To access the online store, click on the "Order NfL analysis kit" tab in the navigation menu on the starting page in the app.
              <br /><br />
              After purchase, we will deliver a blood sample collection kit to you. Your blood sample collection kit will be delivered within 5-7 days with standard shipping, depending on your location.
              <br /><br />
              Your kit comes with everything you need to return your sample to APST Research. The kit will include either a pre-paid return envelope or a return label on the box. See instructions before sealing your sample collection box.
              <br /><br />A prescription is not necessary when ordering our NfL test.
              <br /><br />
              <br /><br />
              We accept the following payment methods:
              <ul>
                <li>Visa</li>
                <li>Mastercard</li>
                <li>American Express</li>
                <li>Paypal</li>
              </ul>
              We don’t accept insurance at this time, however, we work to keep our tests as affordable as possible.`,
  },
  sendingKits: {
    title: "Sending Kits",
    description: `We will deliver a home sample collection kit to you with everything you need to return your blood sample to APST Research.
              <br /><br />
              Our blood sample collection kit is delivered in generic plain packaging with no reference to ALS Analytics app on the packaging.
              <br /><br />
              Generally, your blood sample collection kit will arrive within 5 business days, this however is dependent on your location.
              <br /><br />
              Shipping to you and return shipping to APST Research is included in the price of the test kit.
              <br /><br />
              Once your sample is collected, seal the blood sample in the bag provided in your kit. Then place the bag with the sample back into the kit box. If there is a label included on your bag, make sure you have filled it out completely. Close the kit box. Your kit will include either a pre-paid return envelope or a return label on the box to send your sample back to APST Research. Please
              send the kit back immediately. For more information please see the instructions in your kit.
              <br /><br />
              At present, we only ship to the following countries:
              <ul>
                <li>Austria</li>
                <li>Germany</li>
                <li>Italy</li>
                <li>Switzerland</li>
              </ul>
              Stay tuned to our website and social media for updates, as we would like to support people in more locations as we expand.`,
  },
  bloodSample: {
    title: "Extration of blood sample",
    description: `You collect your blood sample at your doctor.
              <br /><br />
              The kit includes everything your doctor needs to collect your sample.
              <br /><br />
              Our blood sample collection kit comes with instructions that will guide you on how to collect your sample at your doctor.`,
  },
  aboutLabAnalysis: {
    title: "About Lab Analysis",
    description: `Your samples are processed in the same laboratory used by outpatient specialist and hospitals.
              <br /><br />
              All samples are analyzed in the Labor Berlin - Charité Vivantes GmbH in Berlin that is a certified and accredited laboratory in the EU.
              <br /><br />
              Our laboratory undergoes frequent quality control checks to ensure the highest standard of testing. The equipment and analyzers used in our partner lab are all validated for the specific NfL testing of your sample.
              <br /><br />
              Once APST Research receives your sample, your results will be delivered between 5-14 business days later to your ALS Analytics app account.
              <br /><br />
              When your results are ready, you will receive an email or a push message on your mobile phone to let you know your results are available. You can than access your results in your ALS Analytics app account.
              <br /><br />
              We also provide you with a PDF copy of the results which you can print off and take with you to your doctor.`,
  },
  cancellingKit: {
    title: "How to cancel purchase",
    description: `If you no longer want to use your kit or change your mind, you have the right to withdraw from this purchase contract within fourteen days without giving any reason. The withdrawal period is fourteen days from the date of conclusion of the purchase contract.
              <br /><br />
              To exercise the right to cancel, you must inform us of your decision to cancel this purchase contract by a clear statement (e.g. a letter sent by post or e-mail):
              <br /><br />
              <br /><br />
              APST Research GmbH
              <br /><br />
              Westhafenstraße 1<br />
              13353 Berlin, Germany.
              <br /><br />
              <br /><br />
              E-Mail: <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>
              <br /><br />
              You can find more cancelation and refund information in our revocation policy.
              <br /><br />
              At APST Research we are committed to providing good customer service and we would like to hear about your experience. Should you have any feedback, suggestions for improvement, or if your experience with the ALS Analytics app has not met your expectations, please let us know by writing to <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>.`,
  },
}

import { useState } from "react";
import { ISurvey, defaultSurvey } from "../../../models/Survey";

interface IReturnType {
  survey: ISurvey;
  setSurvey: React.Dispatch<React.SetStateAction<ISurvey>>;
  onQ1Change: (letter: string, value: number) => void;
  onQ2Change: (letter: string, value: number) => void;
  onQ3Change: (letter: string, value: number) => void;
  onQ4Change: (letter: string, value: number) => void;
  onQ5AChange: (letter: string, value: number) => void;
  onQ5BChange: (letter: string, value: number) => void;
  onQ6Change: (letter: string, value: number) => void;
  onQ7Change: (letter: string, value: number) => void;
  onQ8Change: (letter: string, value: number) => void;
  onQ9Change: (letter: string, value: number) => void;
  onQ10Change: (letter: string, value: number) => void;
  onQ11Change: (letter: string, value: number) => void;
  onQ12Change: (letter: string, value: number) => void;
}

const useFunctions = (): IReturnType => {
  const [survey, setSurvey] = useState<ISurvey>({ ...defaultSurvey });

  const onQ1Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.language = value;
    }
    if (letter === "b") {
      survey.language = value;
    }
    if (letter === "c") {
      survey.language = value;
    }
    if (letter === "d") {
      survey.language = value;
    }
    if (letter === "e") {
      survey.language = value;
    }
    setSurvey({ ...survey, language: value });
  };

  const onQ2Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.salvation = value;
    }
    if (letter === "b") {
      survey.salvation = value;
    }
    if (letter === "c") {
      survey.salvation = value;
    }
    if (letter === "d") {
      survey.salvation = value;
    }
    if (letter === "e") {
      survey.salvation = value;
    }
    setSurvey({ ...survey, salvation: value });
  };

  const onQ3Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.swallow = value;
    }
    if (letter === "b") {
      survey.swallow = value;
    }
    if (letter === "c") {
      survey.swallow = value;
    }
    if (letter === "d") {
      survey.swallow = value;
    }
    if (letter === "e") {
      survey.swallow = value;
    }
    setSurvey({ ...survey, swallow: value });
  };

  const onQ4Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.handwriting = value;
    }
    if (letter === "b") {
      survey.handwriting = value;
    }
    if (letter === "c") {
      survey.handwriting = value;
    }
    if (letter === "d") {
      survey.handwriting = value;
    }
    if (letter === "e") {
      survey.handwriting = value;
    }
    setSurvey({ ...survey, handwriting: value });
  };

  const onQ5AChange = (letter: string, value: number) => {
    if (letter === "a") {
      survey.useOfCutlery = value;
    }
    if (letter === "b") {
      survey.useOfCutlery = value;
    }
    if (letter === "c") {
      survey.useOfCutlery = value;
    }
    if (letter === "d") {
      survey.useOfCutlery = value;
    }
    if (letter === "e") {
      survey.useOfCutlery = value;
    }
    setSurvey({ ...survey, useOfCutlery: value });
  };

  const onQ5BChange = (letter: string, value: number) => {
    if (letter === "a") {
      survey.useOfFeedingTube = value;
    }
    if (letter === "b") {
      survey.useOfFeedingTube = value;
    }
    if (letter === "c") {
      survey.useOfFeedingTube = value;
    }
    if (letter === "d") {
      survey.useOfFeedingTube = value;
    }
    if (letter === "e") {
      survey.useOfFeedingTube = value;
    }
    setSurvey({ ...survey, useOfFeedingTube: value });
  };

  const onQ6Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.dressingAndPersonalHygiene = value;
    }
    if (letter === "b") {
      survey.dressingAndPersonalHygiene = value;
    }
    if (letter === "c") {
      survey.dressingAndPersonalHygiene = value;
    }
    if (letter === "d") {
      survey.dressingAndPersonalHygiene = value;
    }
    if (letter === "e") {
      survey.dressingAndPersonalHygiene = value;
    }
    setSurvey({ ...survey, dressingAndPersonalHygiene: value });
  };

  const onQ7Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.turningOverInBedAndStraighteningTheBedspread = value;
    }
    if (letter === "b") {
      survey.turningOverInBedAndStraighteningTheBedspread = value;
    }
    if (letter === "c") {
      survey.turningOverInBedAndStraighteningTheBedspread = value;
    }
    if (letter === "d") {
      survey.turningOverInBedAndStraighteningTheBedspread = value;
    }
    if (letter === "e") {
      survey.turningOverInBedAndStraighteningTheBedspread = value;
    }
    setSurvey({
      ...survey,
      turningOverInBedAndStraighteningTheBedspread: value,
    });
  };

  const onQ8Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.walking = value;
    }
    if (letter === "b") {
      survey.walking = value;
    }
    if (letter === "c") {
      survey.walking = value;
    }
    if (letter === "d") {
      survey.walking = value;
    }
    if (letter === "e") {
      survey.walking = value;
    }
    setSurvey({ ...survey, walking: value });
  };

  const onQ9Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.climbingStairs = value;
    }
    if (letter === "b") {
      survey.climbingStairs = value;
    }
    if (letter === "c") {
      survey.climbingStairs = value;
    }
    if (letter === "d") {
      survey.climbingStairs = value;
    }
    if (letter === "e") {
      survey.climbingStairs = value;
    }
    setSurvey({ ...survey, climbingStairs: value });
  };

  const onQ10Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.shortnessOfBreath = value;
    }
    if (letter === "b") {
      survey.shortnessOfBreath = value;
    }
    if (letter === "c") {
      survey.shortnessOfBreath = value;
    }
    if (letter === "d") {
      survey.shortnessOfBreath = value;
    }
    if (letter === "e") {
      survey.shortnessOfBreath = value;
    }
    setSurvey({ ...survey, shortnessOfBreath: value });
  };

  const onQ11Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.shortnessOfBreathWhenLyingDown = value;
    }
    if (letter === "b") {
      survey.shortnessOfBreathWhenLyingDown = value;
    }
    if (letter === "c") {
      survey.shortnessOfBreathWhenLyingDown = value;
    }
    if (letter === "d") {
      survey.shortnessOfBreathWhenLyingDown = value;
    }
    if (letter === "e") {
      survey.shortnessOfBreathWhenLyingDown = value;
    }
    setSurvey({ ...survey, shortnessOfBreathWhenLyingDown: value });
  };

  const onQ12Change = (letter: string, value: number) => {
    if (letter === "a") {
      survey.breathingAids = value;
    }
    if (letter === "b") {
      survey.breathingAids = value;
    }
    if (letter === "c") {
      survey.breathingAids = value;
    }
    if (letter === "d") {
      survey.breathingAids = value;
    }
    if (letter === "e") {
      survey.breathingAids = value;
    }
    setSurvey({ ...survey, breathingAids: value });
  };

  const returnType = {
    survey,
    setSurvey,
    onQ1Change,
    onQ2Change,
    onQ3Change,
    onQ4Change,
    onQ5AChange,
    onQ5BChange,
    onQ6Change,
    onQ7Change,
    onQ8Change,
    onQ9Change,
    onQ10Change,
    onQ11Change,
    onQ12Change,
  };
  return returnType;
};

export default useFunctions;

import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export const defaultSurvey: ISurvey = {
  survey_id: 0,
  patient_id: 0,
  date: "",
  language: 0,
  salvation: 0,
  swallow: 0,
  handwriting: 0,
  useFeedingTube: "No",
  useOfFeedingTube: 0,
  useOfCutlery: 0,
  dressingAndPersonalHygiene: 0,
  turningOverInBedAndStraighteningTheBedspread: 0,
  walking: 0,
  climbingStairs: 0,
  shortnessOfBreath: 0,
  shortnessOfBreathWhenLyingDown: 0,
  breathingAids: 0,
  alsscore: 0,
};

export interface ISurvey {
  survey_id: number;
  patient_id: number | null;
  date: string;
  language: number;
  salvation: number;
  swallow: number;
  handwriting: number;
  useFeedingTube: string; //"YES/NO"
  useOfCutlery: number; // (5a)
  useOfFeedingTube: number; //(5b)
  dressingAndPersonalHygiene: number;
  turningOverInBedAndStraighteningTheBedspread: number;
  walking: number;
  climbingStairs: number;
  shortnessOfBreath: number;
  shortnessOfBreathWhenLyingDown: number;
  breathingAids: number;
  alsscore: number;
}

export default class SurveyModel {
  private survey: ISurvey;

  constructor(private store: AppStore, survey: ISurvey) {
    makeAutoObservable(this);
    this.survey = survey;
  }

  get asJson(): ISurvey {
    return toJS(this.survey);
  }
}

export const languages = [
    {
        label: "English",
        value: 'en',
    },
    {
        label: "Deutsch",
        value: 'de',
    },
    {
        label: "Italiano",
        value: "it",
    },

]

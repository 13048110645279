import moment from "moment";
import { ISurvey } from "../../../models/Survey";

const MAX_SCORE = 48;

function getLatestSurvey(surveys: ISurvey[]): ISurvey | null {
  if (surveys.length === 0) {
    return null;
  }

  const latest = surveys.slice().sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB.getTime() - dateA.getTime();
  });
  return latest[0];
}

export const getTotalValue = (surveys: ISurvey[]) => {
  const latest = getLatestSurvey(surveys);
  return {
    value: latest ? latest.alsscore : 0,
  };
};

export const getCourseValue = (surveys: ISurvey[], dateOfSymptomsOnset: string) => {

  let score = null;
  const latest = getLatestSurvey(surveys);

  if (latest !== null) {
    const diseaseBegin = moment(dateOfSymptomsOnset);
    score = latest.alsscore;

    if (diseaseBegin.isValid()) {
      const now = moment();
      const duration = moment.duration(now.diff(diseaseBegin));
      let months = duration.months() + 12 * duration.years();

      if (duration.days() > 0) {
        months += 1;
      }
      let result = months > 0 ? (MAX_SCORE - score) / (1.0 * months) : 0;

      return {
        value: parseFloat(result.toFixed(2)),
      };
    }
  }
  return {
    value: 0,
  };
};

export const getIndices = (age: number) => {
  switch (true) {
    case age > 90:
      return 82.4;
    case age > 80:
      return 61.9;
    case age > 70:
      return 46.5;
    case age > 60:
      return 34.9;
    case age > 50:
      return 26.2;
    case age > 40:
      return 19.7;
    case age > 30:
      return 14.8;
    default:
      return 11.1;
  }
};

export const getAgesOverYears = (dob: string) => {
  const birthDate = new Date(dob);
  const birthYear = birthDate.getFullYear();
  const currentYear = new Date().getFullYear();
  let ages = [];

  for (let year = birthYear; year <= currentYear; year++) {
    const age = year - birthYear;
    ages.push(age);
  }

  return ages;
};

export const formatDate = (date: string) => {
  const formattedDate = new Date(date).toLocaleDateString("de-DE");
  return formattedDate;
};

export const getLabelFromPercentile = (percentile: number) => {
  switch (true) {
    case percentile > 150:
      return "+++";
    case percentile > 97.5:
      return "++";
    case percentile > 80:
      return "+";
    default:
      return "-";
  }
};

export const convertToStandardDate = (str: string) => {
  const parts = str.split("-");
  const [day, month, year] = parts.map(Number);
  return new Date(year, month - 1, day);
};
export const italianTermsAndConditions = {
  termsofuse: {
    title: "Termini e condizioni generali per l'analisi NfL",
    semi: "Le presenti Condizioni Generali sono state aggiornate l'ultima volta il 1° ottobre 2023",
    description: `APST Research GmbH ("APST Research") ha sviluppato una piattaforma/app di ricerca sulla SLA
     ("ALS Analytics App"). APST Research fornisce l'app ALS Analytics agli utenti interessati che
      soffrono di sclerosi laterale amiotrofica (“Utenti”). Nell'app ALS Analytics, gli utenti possono
       utilizzare la scala di valutazione funzionale ALS rivista (ALSFRSr) ("punteggio di valutazione funzionale ALS")
        per registrare lo stato della loro malattia e valutarne la progressione.
         Inoltre gli utenti possono determinare il biomarcatore NfL (catena leggera dei neurofilamenti) ("valore NfL")
          prelevando un campione di sangue e ottenendo l'analisi della progressione dopo aver valutato il NfL
           valore visualizzato nell'app ("analisi NfL").`,
  },
  applicationOfSubject: {
    title: "1. Generale, applicazione e oggetto delle CG",
    list: [
      {
        data: `APST Research GmbH ("APST Research") ha sviluppato una piattaforma/app di ricerca sulla SLA ("ALS Analytics App"). APST Research fornisce la SLA
        App Analytics agli utenti interessati affetti da sclerosi laterale amiotrofica ("Utenti") ai sensi dell'art 
        <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "terms-of-use"}">Termini di utilizzo</a> dell'ALS Analytics
        App. Gli utenti hanno accesso ai servizi gratuiti nell'app ALS Analytics. Inoltre, a pagamento, gli utenti possono determinare il
        biomarcatore NfL (catena leggera dei neurofilamenti) ("valore NfL") prelevando un campione di sangue e facendo analizzare l'andamento della progressione ottenuta dopo la valutazione di
        il valore NfL visualizzato nell'app ("Analisi NfL").`,
      },
      {
        data: `Le presenti Condizioni Generali ("CGC") nella versione valida al momento della conclusione del contratto si applicano a tutti i contratti tra
        APST Research e l'utente che si concludono tramite l'analisi NfL offerta nell'app ALS Analytics`,
      },
      {
        data: `Inviando un ordine per servizi a pagamento, l'utente conferma di accettare le Condizioni Generali e di acconsentire alla loro validità. Le Condizioni Generali diventano
        parte del contratto per i servizi a pagamento offerti da APST Research nell'ambito dell'app ALS Analytics. Non è possibile ordinare l'analisi NfL nel
         ALS Analytics App senza accettare le Condizioni Generali.`,
      },
    ],
  },
  conclusionOfContract: {
    title: "2. Ordine, conclusione del contratto, testo del contratto",
    list: [
      {
        data: `La presentazione della funzionalità di analisi NfL nell'App ALS Analytics non costituisce un'offerta vincolante da parte di APST Research. Cliccando su "Ordine a pagamento"
         pulsante, l'utente presenta un'offerta per la conclusione di un contratto per l'analisi NfL a pagamento offerta da APST Research come parte dell'app ALS Analytics. L'utente viene quindi inoltrato a
          presso il prestatore di servizi di pagamento e devono inserire lì i propri dati di pagamento. Immediatamente dopo aver inviato l'ordine, l'utente riceve una conferma automatica del proprio ordine.
         La conferma non costituisce accettazione della proposta contrattuale. L'accettazione avviene tramite e-mail separata dopo che APST Research ha ricevuto conferma di ricezione del pagamento per l'analisi NfL.`,
      },
      {
        data: `L'utente può stampare o salvare le presenti Condizioni Generali utilizzando la funzione browser. L'utente può anche scaricare e archiviare questo documento in formato PDF come parte del processo di ordinazione. Alla conferma dell'ordine,
         APST Research invierà all'utente le Condizioni Generali nella loro versione attuale. APST Research non salva il testo del contratto.`,
      },
    ],
  },
  scopeOfService: {
    title: "3. Descrizione del servizio",
    list: [
      {
        data: `Il servizio di analisi NfL a pagamento offerto nell'app ALS Analytics offre agli utenti la possibilità di determinare il marcatore di progressione NfL (valore NfL) prelevando un campione di sangue e visualizzando il
         analisi della progressione ottenuta dopo aver valutato il valore NfL nell'app. Il servizio include le seguenti funzionalità:`,
        subList: [
          {
            data: `L'invio all'utente di un kit per il prelievo del sangue, comprensivo di una scheda informativa per il medico che preleva il campione di sangue.`,
          },
          {
            data: `L'invio del campione di sangue da parte del medico consultato dall'utente ad APST Research e ad un laboratorio incaricato di esaminare il campione di sangue.`,
          },
          {
            data: `L'esame del campione di sangue in laboratorio per determinare il valore NfL, compreso il trasferimento dei valori ad APST Research.`,
          },
          {
            data: `La valutazione del risultato del test, in particolare del valore NfL, da parte di APST Research in relazione ad altri valori, incluso il punteggio di valutazione funzionale ALS determinato dall'utente nell'app ALS Analytics.`,
          },
          {
            data: `La visualizzazione dell'analisi NfL nell'app ALS Analytics.`,
          },
        ],
      },

      {
        data: `L'utente si rivolge a un medico di sua scelta per il prelievo di sangue. I servizi medici per il prelievo di sangue sono servizi a pagamento e non sono inclusi nel servizio di analisi NfL a pagamento offerto nell'app ALS Analytics.`,
      },
      {
        data: `L'utente può ripetere l'analisi NfL ogni sei mesi. Le presenti Condizioni Generali si applicano ad ogni ordine`,
      },
    ],
  },
  accessRequirements: {
    title: "4. Requisiti di accesso",
    description: `Per poter utilizzare l'analisi NfL offerta nell'app ALS Analytics, l'utente deve essersi registrato nell'app ALS Analytics e creato un account utente in conformità con l'app ALS Analytics 
    <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "terms-of-use"}">Termini di utilizzo</a>.`,
  },
  revocation: {
    title: "5. Revoca",
    description: `In quanto consumatore, l'utente gode del diritto di recesso previsto dalla legge: <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "revocation"}">Politica di revoca</a>.`,
  },
  costOfDelivery: {
    title: "6. Costi, consegna",
    list: [
      {
        data: "Il costo per l'utilizzo dell'analisi NfL è di EUR 120,00 IVA inclusa. I costi comprendono i servizi secondo la cifra 3.1",
      },
      {
        data: "L'importo di EUR 120,00 ai sensi del punto 6.1 non comprende tuttavia le prestazioni mediche nell'ambito del prelievo di sangue ai sensi del punto 3.2. Il costo del prelievo di sangue verrà fatturato separatamente dal medico all'utente come servizio a pagamento. APST Research non è coinvolta in queste procedure.",
      },
      {
        data: "Per il pagamento ad APST Research possono essere utilizzati solo i metodi di pagamento visualizzati nell'app ALS Analytics al termine del processo d'ordine ai sensi della sezione 6.1. Per ogni ordine, APST Research si riserva il diritto di non offrire determinate modalità di pagamento o di fare riferimento ad altre modalità di pagamento.",
      },
      {
        data: "Il prodotto ordinato verrà consegnato all'indirizzo di consegna specificato dall'utente durante il processo di ordinazione.",
      },
    ],
  },
  liability: {
    title: "7. Responsabilità",
    list: [
      {
        data: `Nonostante le seguenti limitazioni di responsabilità, APST Research sarà responsabile senza limitazioni per danni a vita, corpo e salute che si fondano su una negligenza
         o violazione intenzionale dei doveri da parte di APST Research, i suoi rappresentanti legali o agenti ausiliari, nonché per danni coperti dalla responsabilità ai sensi della legge sulla responsabilità del prodotto,
         nonché per danni derivanti da violazioni contrattuali intenzionali o gravemente negligenti e intenti fraudolenti da parte di APST Research, dei suoi rappresentanti legali o agenti delegati.`,
      },
      {
        data: `In caso di negligenza, la responsabilità di APST Research sarà limitata al risarcimento dei danni tipicamente prevedibili in relazione all'utilizzo dell'App ALS Analytics e dei Servizi.
         In caso di negligenza lieve, tuttavia, APST Research sarà responsabile solo se APST Research ha violato un obbligo il cui adempimento era essenziale per
         il corretto utilizzo dell'App ALS Analytics e dei servizi, tenendo conto degli interessi di entrambe le parti e sul cui adempimento l'utente aveva il diritto di fare affidamento.`,
      },
      {
        data: `È esclusa ogni ulteriore responsabilità di APST Research indipendentemente dalla natura giuridica della pretesa fatta valere. Nella misura in cui la responsabilità di APST Research è esclusa o limitata, ciò vale anche per
         la responsabilità personale dei propri dipendenti, lavoratori, collaboratori, rappresentanti e agenti ausiliari.`,
      },
    ],
  },
  dataPrivacy: {
    title: "8. Riservatezza dei dati",
    description: `APST Research desidera sottolineare che quando si utilizza l'app ALS Analytics e la funzionalità di analisi NfL vengono elaborati i dati personali dell'utente. APST Research rispetta le normative applicabili sulla protezione dei dati.
     Le informazioni sulle modalità di trattamento dei dati personali sono reperibili nell'art <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "data-privacy-policy"}">Informativa sulla privacy dei dati</a>`,
  },
  changesToTerms: {
    title: "9. Modifiche alle Condizioni Generali",
    list: [
      {
        data: `APST Research si riserva il diritto di modificare o integrare le presenti Condizioni Generali in qualsiasi momento senza indicarne le ragioni con effetto per il futuro qualora ciò sia necessario per adeguarsi a mutate circostanze per le quali APST Research
         non è responsabile (in particolare modifiche legislative e difficoltà nell'esecuzione del contratto con l'utente imprevedibili al momento della conclusione del contratto) e la modifica è ragionevole per l'utente o per il
          la modifica è semplicemente vantaggiosa per l'utente. Una modifica prevista verrà inviata all'indirizzo e-mail fornito ad APST Research o comunicata tramite un annuncio nell'app ALS Analytics. I cambiamenti avranno luogo
           effetto entro e non oltre 14 giorni dalla pubblicazione della modifica nell'app o dalla messa a conoscenza della modifica da parte dell'utente in altro modo, a meno che l'utente non si opponga alla modifica entro 14 giorni. L'utente è
            si ritiene di aver accettato i nuovi termini e condizioni se il servizio viene utilizzato dopo la scadenza di questi 14 giorni.`,
      },
      {
        data: `In caso di contestazione entro il termine, APST Research potrà risolvere il rapporto contrattuale con l'utente senza preavviso. Se APST Research non risolve il precedente rapporto contrattuale
         dopo l'effettiva opposizione, le precedenti CG continueranno ad applicarsi senza modifiche nei confronti dell'utente corrispondente.`,
      },
    ],
  },
  applicableLaw: {
    title: "10. Legge applicabile, foro competente, risoluzione delle controversie",
    list: [
      {
        data: `Le presenti Condizioni Generali, il loro oggetto e la loro creazione (così come tutte le controversie o pretese extracontrattuali) sono soggetti al diritto della Repubblica Federale Tedesca, ad esclusione della Convenzione delle Nazioni Unite sui contratti per i
         Vendita internazionale di beni (CISG), a meno che la legge imperativa sulla tutela dei consumatori non prescriva diversamente.`,
      },
      {
        data: `Il foro competente è Berlino se l'utente è un commerciante ai sensi del Codice commerciale tedesco, una persona giuridica di diritto pubblico o un fondo speciale di diritto pubblico. Lo stesso vale per gli utenti che
         non hanno un foro competente generale in Germania o in un altro Stato membro dell'UE o che hanno spostato la loro residenza all'estero dopo la conclusione del contratto o il cui luogo di residenza o dimora abituale è
         non noto al momento della proposizione del ricorso. Per il resto valgono le disposizioni di legge applicabili alla giurisdizione locale e internazionale.`,
      },
      {
        data: `La Commissione Europea fornisce una piattaforma per la risoluzione delle controversie online (OS). Puoi trovare il link alla piattaforma qui: <a className="uk-text-primary gtc-text" target="_blank" href="https://ec.europa.eu/consumers/odr/">ec. europa.eu/consumers/odr/</a>.
         APST Research non è disposta né obbligata a partecipare a procedimenti di risoluzione delle controversie dinanzi a un collegio arbitrale dei consumatori.`,
      },
    ],
  },
  finalProvisions: {
    title: "11. Disposizioni finali",
    list: [
      {
        data: `Qualora singole disposizioni delle presenti Condizioni Generali fossero invalide o inapplicabili oppure lo diventassero dopo la conclusione del contratto, ciò non pregiudicherà la validità del resto del contratto.
         La disposizione invalida o inapplicabile sarà sostituita dalle pertinenti disposizioni di legge. Se la legge legale non è disponibile nel rispettivo caso, la disposizione valida o applicabile sostituirà quella non valida o
          disposizione inapplicabile i cui effetti si avvicinano di più all'obiettivo economico perseguito da APST Research e dall'utente con la disposizione invalida o inapplicabile.`,
      },
      {
        data: "La clausola 11.1 si applicherà di conseguenza nel caso in cui una o più condizioni risultino incomplete.",
      },
    ],
  },
}

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Asset1 from '../images/Asset1.svg';
import i18next from "../locales/config";
import { englishtermsofuse } from "../locales/terms-of-use/englishtermsofuse";
import { germantermsofuse } from "../locales/terms-of-use/germantermsofuse";
import { italiantermsofuse } from "../locales/terms-of-use/italiantermsofuse";
import { useTranslation } from "react-i18next";
import { LoggedOutContentLaguageSwitcher } from "./GeneralTermsConditionsPage";
import  keycloak from "../keycloakConfig";

const TermsOfUsePage = observer(() => {

  const navigate = useNavigate();
  const { t: msg } = useTranslation(['home']);
  const [terms, setTerms] = useState(englishtermsofuse);
  useEffect(() => {
    const getTerms = () => {
      switch (i18next.language) {
        case 'en':
          return englishtermsofuse;
        case 'de':
          return germantermsofuse;
        case 'it':
          return italiantermsofuse;
        default:
          return englishtermsofuse;
      }
    };
    setTerms(getTerms());
  }, [msg]);

  const onButtonClick = (url: string) => {
    navigate(url);
  }

  return (
    <div className="uk-section reads">
      <div className="uk-container uk-container-large">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="uk-text-right">
            {!keycloak.authenticated && <LoggedOutContentLaguageSwitcher />}
            <div className="app-image">
              <img className="logo" src={Asset1} alt="" />
            </div>
          </div>
          <h4>{terms.termsofuse.title}</h4>
          <div>
            <div>
              <h5>{terms.termsofuse.semi}</h5>
              <p className="text">{terms.termsofuse.description}</p>
            </div>

            <h5>{terms.applicationOfSubject.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.applicationOfSubject.list.map((item, index) => (
                <li className="text" key={index}>{item.data}</li>
              ))}
            </ul>

            <h5>{terms.conclusionOfContract.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.conclusionOfContract.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

            <h5>{terms.scopeOfService.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.scopeOfService.list.map((item, index) => (
                <li className="text" key={index}>
                  {item.data}
                  {item.subList && (
                    <ul className="uk-list uk-list-decimal">
                      {item.subList.map((subItem, subIndex) => (
                        <li className="text" key={subIndex} dangerouslySetInnerHTML={{ __html: subItem.data }} />
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>

            <h5>{terms.accessRequirements.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.accessRequirements.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{terms.obligationsOfUser.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.obligationsOfUser.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{terms.blockingOfAccount.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.blockingOfAccount.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{terms.rightsOfUser.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.rightsOfUser.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{terms.rightsOfResponsibility.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.rightsOfResponsibility.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{terms.costs.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.costs.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

            <h5>{terms.revocationsAndTermination.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.revocationsAndTermination.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{terms.noWarranty.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.noWarranty.list.map((item, index) => (
                <li className="text" key={index}>
                  {item.data}
                  {item.subList && (
                    <ul className="uk-list uk-list-decimal">
                      {item.subList.map((subItem, subIndex) => (
                        <li className="text" key={subIndex} dangerouslySetInnerHTML={{ __html: subItem.data }} />
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>

            <h5>{terms.liability.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.liability.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{terms.dataPrivacy.title}</h5>
            <p className="text" dangerouslySetInnerHTML={{ __html: terms.dataPrivacy.description }} />

            <h5>{terms.applicableLaw.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.applicableLaw.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>
            <h5>{terms.invalidityOfIndividual.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.invalidityOfIndividual.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>
            <h5>{terms.changesToTerms.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.changesToTerms.list.map((item, index) => (
                <li className="text" key={index}>
                  <p dangerouslySetInnerHTML={{ __html: item.data }} />
                  {item.subList && (
                    <ul className="uk-list uk-list-decimal">
                      {item.subList.map((subItem, subIndex) => (
                        <li className="text" key={subIndex} dangerouslySetInnerHTML={{ __html: subItem.data }} />
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>

            <h5 >{terms.contact.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {terms.contact.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

          </div>
          <div className="uk-text-right">
            <button
              className="btn btn-primary"
              type="button" onClick={() => onButtonClick("/")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TermsOfUsePage;



import { createContext, useContext } from "react";
import AppApi from "../../apis/AppApi";
import AppStore from "../../stores/AppStore";
import UiStore from "../../stores/UiStore";

interface AppContextType {
  store: AppStore;
  api: AppApi;
  ui: UiStore;
}

export const AppContext = createContext<AppContextType | null>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);
  return context as AppContextType;
};

import ReactDOM from "react-dom";
import ErrorBoundary from "./error/ErrorBoundary";

interface IProps {
  modalId: string;
  cssClass?: string;
  closeOnBackgroundClick?: boolean;
  children: any;
}
const Modal = (props: IProps) => {
  const { modalId, cssClass, children } = props;
  const newClass = cssClass ? cssClass : "";

  const modal = (
    <ErrorBoundary>
      <div
        id={modalId}
        className={`uk-flex-top custom-modal-style ${newClass}`}
        data-uk-modal
        data-bg-close={false}>
        {children}
      </div>
    </ErrorBoundary>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default Modal; 


export const OffCanvasModal = (props: IProps) => {
  const { modalId, cssClass, children, closeOnBackgroundClick } = props;
  const newClass = cssClass ? cssClass : "";

  const offCanvas = (
    <div
      id={modalId}
      className={`custom-modal-style ${newClass}`}
      data-uk-offcanvas="flip: true; overlay: true"
      data-bg-close={closeOnBackgroundClick}
    >
      {children}
    </div>
  );

  return ReactDOM.createPortal(offCanvas, document.body);
};
import Store from "./Store";
import { runInAction } from "mobx";
import AppStore from "./AppStore";
import NotificationModel, { INotification } from "../models/Notification";

export default class NotificationStore extends Store<
  INotification,
  NotificationModel
> {
  items = new Map<number, NotificationModel>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: INotification[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new NotificationModel(this.store, item))
      );
    });
  }
}

export const englishrevocation = {

  title: "Revocation",
  subtitle: "As a consumer, the user has a legal right of withdrawal",

  policy: {
    title: "Revocation policy ",
    subtitle: "Right of revocation",
    description: `You have the right to withdraw from this contract within fourteen days without giving any reason. The withdrawal period is fourteen days from the date of conclusion of the contract. To exercise
     the right to cancel, you must inform us (APST Research GmbH, Westhafenstraße 1, 13353 Berlin, Germany, Phone: +49 (0) 30 81031410, E-Mail:<a href="mailto: info@apstresearch.com">info@apstresearch.com</a>) of your decision to cancel this contract by a 
     clear statement (e.g. a letter sent by post or e-mail). You can use the attached sample withdrawal form, but this is not mandatory. To meet the withdrawal deadline, it is sufficient for you to send your
      communication concerning your exercise of the right of withdrawal before the withdrawal period has expired.`,
  },

  consequences: {
    title: "Consequences of revocation",
    subtitle: "",
    description: `If you withdraw from this contract, we shall reimburse to you all payments received from you, including the costs of delivery (with the exception of the supplementary costs resulting from your choice
       of a type of delivery other than the least expensive type of standard delivery offered by us), without undue delay and in any event not later than 14 days from the day on which we are informed about your decision
        to withdraw from this contract. For this repayment, we will use the same means of payment that you used for the original transaction, unless expressly agreed otherwise with you; under no circumstances will you
         be charged any fees for this repayment.`,
  },
  expiryofrevocation: {
    title: "Expiry of the right of revocation",
    subtitle: "",
    description: `In the case of a contract for the delivery of digital content that is not on a physical data carrier, your right of withdrawal also expires if we have started to execute the contract after you have
     expressly agreed that we will start to execute the contract before the expiry of the withdrawal period and you have confirmed your knowledge that you lose your right of withdrawal by giving your consent at the
      beginning of the execution of the contract.`,
  },
  samplerevocation: {
    title: "Sample revocation form",
    description: `(If you wish to withdraw from the contract, please fill out this form and send it back to us.)`,
    list: [
      {
        data: `APST Research GmbH, Westhafenstraße 1, 13353 Berlin, Telephone: <a href="tel:+49(0)30-81031410">+49 (0) 30-81031410</a>, e-mail: <a href="mailto: info@apstresearch.com">info@apstresearch.com</a>`,
      },
    ],
    labelOne: "I/we (*) hereby revoke the contract concluded by me/us (*) for the purchase of the following goods (*)/the provision of the following service (*)",
    labelTwo: "Ordered on (*)/received on (*)",
    labelThree: "Name of the consumer(s)",
    labelFour: "Address of the consumer(s)",
    labelFive: "Signature of the consumer(s) (only for notification on paper) ",
    labelSix: "Date",
    labelSeven: "(*)Delete as applicable",
  },
};


import keycloak from "../keycloakConfig";
import { IPatient } from "../models/Patient";
import AppStore from "../stores/AppStore";
import UiStore from "../stores/UiStore";
import AppApi from "./AppApi";

export default class AuthApi {
  constructor(
    private api: AppApi,
    private store: AppStore,
    public ui: UiStore
  ) {
    this.handleAuthStateChange();
  }

  private handleAuthStateChange() {
    this.handleUserBasicInfo();
    window.localStorage.setItem('Authorisation', `${keycloak.token}`);
  }

  private async handleUserBasicInfo() {
    if (keycloak.authenticated) {
      try {
        const user = await keycloak.loadUserProfile();
        const _user: IPatient = {
          keycloak_id: user.id!,
          username: user.username!,
          email: user.email!,
          first_name: user.firstName!,
          last_name: user.lastName!,

          user_id: null,
          date_created: "",
          gender: "",
          date_of_birth: "",
          phone: "",
          diagnosis: "",
          time_of_symptoms_onset: "",
          request_account_deletion: false,
          request_data_deletion: false,
        };
        this.store.patient.load(_user);
      } catch (error) { }
    }
  }
}

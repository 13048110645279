export const italianprivacypolicy = {
  title: "Politica sulla riservatezza",
  subtitle: `Informativa sulla privacy per la ricerca medica di APST Research GmbH e l'utilizzo dell'applicazione mobile "ALS Analytics App"`,
  sub: `La presente informativa sulla privacy è stata aggiornata l'ultima volta il 01 settembre 2023`,

  generalInformation: {
    title: "1. Informazioni generali",
    list: [
      {
        data: `APST Research GmbH, Westhafenstraße 1, 13353 Berlin, e-mail: <a href="mailto:info@apstresearch.com">info@apstresearch.com</a> ("APST Research") sostiene la ricerca medica nel campo delle malattie neurologiche rare e gravi, in particolare della sclerosi laterale amiotrofica ("SLA"). In particolare, APST Research supporta progetti scientifici attraverso il coordinamento di servizi e applicazioni mobile (“app”).`,
      },
      {
        data: `"ALS Analytics App" è un'applicazione software mobile che consente ai pazienti affetti da SLA di raccogliere e visualizzare (a) dati sul decorso della malattia ("dati clinici"), (b) dati di analisi di laboratorio e (c) risultati dei parametri di misurazione biologica ("biomarcatori ") catena leggera di neurofilamenti ("NfL").`,
      },
    ],
  },

  responsibilities: {
    title: "2. Responsibilities",
    list: [
      {
        data: `APST Research is responsible for data protection and data security when using the ALS Analytics App. `,
      },
      {
        data: `I dati personali trattati dall'app ALS Analytics sono sensibili e sono pertanto protetti da misure di protezione e sicurezza dei dati efficaci e affidabili. APST Research garantisce il rispetto delle leggi sulla protezione dei dati ed è soggetto al controllo sulla protezione dei dati da parte dello Stato di Berlino. APST Research attribuisce la massima priorità anche alla protezione dei dati interni. I dipendenti di APST Research sono tenuti a mantenere la riservatezza e a rispettare le norme sulla protezione dei dati.`,
      },
      {
        data: `Puoi contattare il nostro responsabile della protezione dei dati all'indirizzo <a href="mailto:dataprotection@apst-research.com">dataprotection@apst-research.com</a> o tramite posta al nostro indirizzo con l'aggiunta "il responsabile della protezione dei dati" .`,
      },
    ],
  },

  dataProcessing: {
    title: "3. Trattamento dei dati",

    list: [
      {
        data: "Principio di limitazione delle finalità, base giuridica",
        subList: [
          {
            data: `APST Research tratta i tuoi dati personali solo nella misura necessaria per lo svolgimento dei progetti di ricerca e per il funzionamento dell'App ALS Analytics`,
          },
          {
            data: `La base giuridica per il trattamento dei dati è il rapporto contrattuale in essere (art. 6 cpv. 1 frase 1 lett. b GDPR) e il consenso (art. 6 cpv. 1 frase 1 lett. a GDPR).`,
          },
        ],
      },
      {
        data: "L'elaborazione dei dati nella ricerca medica",
        description: `Il trattamento dei dati personali che va oltre il funzionamento dell'App ALS Analytics, ovvero per finalità di ricerca medica, pubblicazione, didattica e utilizzazione economica sanitaria (vedi Tabella 2 al paragrafo 3.6), avviene esclusivamente in forma pseudonimizzata e/o anonimizzata , a condizione che il paziente abbia dato il suo consenso. A seconda del tuo consenso, i tuoi dati pseudonimizzati e/o anonimizzati verranno utilizzati in particolare per i seguenti scopi:`,
        subList: [
          {
            data: `Valutazione dei dati per la ricerca medica in collaborazione con istituzioni accademiche al fine di promuovere l’ulteriore sviluppo delle cure mediche (ad es. analisi sistematica della progressione della malattia ed esperienze con i trattamenti);`,
          },
          {
            data: `Valutazione di dati per la ricerca medica in collaborazione con aziende del settore sanitario, in particolare aziende biotecnologiche e di tecnologia medica basate sulla ricerca, al fine di promuovere lo sviluppo o l'ulteriore sviluppo di farmaci innovativi, tecnologia medica e altri prodotti medici o procedure terapeutiche.`,
          },
        ],
      },
      {
        data: "Elaborazione dei dati nelle analisi e valutazioni di laboratorio.",
        description1: `1. I dati personali secondo la tabella 2 nella sezione 3.6 vengono utilizzati per eseguire analisi di laboratorio NfL. Tali dati vengono trattati in particolare per le seguenti finalità:`,
        subList: [
          {
            data: `Elaborazione, conservazione e valutazione delle analisi di laboratorio NfL provenienti da laboratori certificati;`,
          },
          {
            data: `Comunicazione di informazioni sulle analisi di laboratorio, inclusa la presentazione e la comunicazione delle analisi di laboratorio nell'App ALS Analytics.`,
          },
        ],
        description2: "2. Per l'analisi i dati personali vengono trasferiti in forma pseudonimizzata ad un laboratorio accreditato e ivi conservati fino al termine dell'analisi.",
      },
      {
        data: "Elaborazione dati per acquisti e logistica.",
        description: "I dati personali in conformità con la Tabella 1 nella Sezione 3.6 vengono utilizzati per elaborare gli acquisti dei nostri prodotti e servizi, inclusa l'elaborazione dei pagamenti.",
      },
      {
        data: "Trattamento dei dati quando ci contatti.",
        description: `Quando si contatta APST Research tramite e-mail, SMS, telefono o tramite un modulo di contatto, i dati forniti (ad esempio indirizzo e-mail, nome, numero di telefono o contenuto della richiesta) vengono elaborati da APST Research al fine di rispondere alle richieste e/o elaborare le preoccupazioni. Ad esempio, comunicazione telefonica con l'interessato per discutere i risultati o inviare un'e-mail o un messaggio di testo per informarlo che i risultati sono disponibili.`,
      },
      {
        data: "Ambito e gamma di utilizzo dei dati",
        description1: `1. La tabella 1 mostra i dati personali utilizzati esclusivamente per l'amministrazione degli utenti e il funzionamento dell'app ALS Analytics e per l'elaborazione degli acquisti effettuati all'interno dell'app. Non viene utilizzato per scopi di ricerca medica.`,
        table1: {
          title: "Tabella 1:",
          header: [
            {
              row1: `Categoria di dati`,
              row2: `Campi dati`,
            },
          ],
          body: [
            {
              row1: `Dati di contatto del paziente`,
              row2: `• Cognome, nome, titolo e indirizzo • Indirizzo postale • Indirizzo e-mail • Numeri di telefono`,
            },
            {
              row1: `Dati per l'acquisto di analisi di laboratorio`,
              row2: `• Indirizzo di fatturazione • Dettagli di pagamento • Indirizzo di consegna`,
            },
          ],
        },
        description2: "2. La tabella 2 mostra i dati utilizzati pseudonimizzati e/o anonimizzati a fini di ricerca medica.",
        table2: {
          title: "Tabella 2:",
          header: [
            {
              row1: `Categoria di dati`,
              row2: `Campi dati`,
            },
          ],
          body: [
            {
              row1: `Dati demografici`,
              row2: `• Età • Sesso`,
            },
            {
              row1: `Caratteristiche cliniche`,
              row2: `• Diagnosi • Insorgenza dei sintomi (DD.MM.YYYY)`,
            },
            {
              row1: `Rapporti da laboratori certificati`,
              row2: `• Catene leggere dei neurofilamenti (NfL) nel siero`,
            },
            {
              row1: `Autovalutazioni`,
              row2: `• ALSFRS-r – Scala di valutazione funzionale ALS rivista`,
            },
          ],
        },
      },
    ],
  },

  dataProcessingOnWebsite: {
    title: "4. Trattamento dei dati durante la visita del sito web",
    list: [
      {
        data: `Quando si utilizza il sito web di APST Research per scopi informativi (ad es. www.apstresearch.com), cioè se non avviene alcuna registrazione tramite l'account personale dell'utente o vengono trasmesse altre informazioni personali, APST Research elabora i dati che il browser dell'utente trasmette al server di il sito web di APST Research e che è tecnicamente necessario per visualizzare il sito web di APST Research e per garantirne stabilità e sicurezza:`,
        subList: [
          {
            data: `Indirizzo IP,`,
          },
          {
            data: `Data e ora della richiesta,`,
          },
          {
            data: `Durata della visita al sito web,`,
          },
          {
            data: `Differenza di fuso orario rispetto all'ora di Greenwich (GMT),`,
          },
          {
            data: `Contenuto della richiesta (pagina specifica),`,
          },
          {
            data: `Stato di accesso/codice di stato HTTP,`,
          },
          {
            data: `Quantità di dati trasferiti in ciascun caso,`,
          },
          {
            data: `Sito web da cui proviene la richiesta,`,
          },
          {
            data: `Pagine web che visiti sul nostro sito,`,
          },
          {
            data: `Fornitore di servizi Internet,`,
          },
          {
            data: `tipo di browser,`,
          },
          {
            data: `File di registro del server,`,
          },
          {
            data: `Sistema operativo e sua interfaccia`,
          },
          {
            data: `Lingua e versione del software del browser.`,
          },
        ],
      },
      {
        data: `La base giuridica è l’art. 6 par. 1 frase 1 lett. f GDPR, vale a dire il nostro legittimo interesse nella presentazione dei siti web a cui si accede.`,
      },
    ],
  },

  durationOfStorage: {
    title: "5. Durata della conservazione",
    list: [
      {
        data: `APST Research conserva tutti i dati personali raccolti con il consenso dei pazienti a fini di ricerca medica (Tabella 2 nella Sezione 3.6) fino al raggiungimento dello scopo della ricerca o fino alla revoca del consenso (vedere Sezione 6 (3)).`,
      },
      {
        data: `APST Research conserva tutti i dati personali in conformità con la Tabella 1 nella Sezione 3.6 fino al momento in cui lo scopo per il quale i dati sono stati raccolti (ad esempio fornitura di prodotti e servizi) cessa di essere applicabile.`,
      },
      {
        data: `Il trattamento dei dati continuerà anche dopo la morte dell'interessato (paziente), se il rapporto contrattuale non viene risolto dal/i successore/i legale/i del paziente o se il/i consenso/i del paziente non viene revocato/i.`,
      },
      {
        data: `Se i dati non vengono cancellati perché necessari per altri scopi consentiti dalla legge (ad es. i dati contabili sono soggetti all'obbligo di conservazione ai sensi del diritto commerciale/fiscale ai sensi del § 257 comma 1 HGB e/o del § 147 comma 1 AO) , il suo trattamento è limitato. Ciò significa che i dati sono bloccati per uso operativo.`,
      },
    ],
  },

  rightsOfAffectedPerson: {
    title: "6. Diritti delle persone interessate",
    list: [
      {
        data: `Gli interessati hanno i seguenti diritti nei confronti di APST Research in relazione ai loro dati personali:`,
        subList: [
          {
            data: `Diritto di accesso (art. 15 GDPR),`,
          },
          {
            data: `Diritto di rettifica e cancellazione (artt. 16 e 17 GDPR),`,
          },
          {
            data: `Diritto alla limitazione del trattamento (art. 18 GDPR),`,
          },
          {
            data: `Diritto di opposizione al trattamento (art. 21 GDPR),`,
          },
          {
            data: `Diritto alla portabilità dei dati (art. 20 GDPR).`,
          },
        ],
      },
      {
        data: `Le persone interessate hanno inoltre il diritto di presentare reclamo all'autorità di controllo della protezione dei dati in merito al trattamento dei loro dati da parte di APST Research.`,
      },
      {
        data: `APST Research desidera sottolineare che gli interessati possono revocare in qualsiasi momento qualsiasi consenso alla protezione dei dati fornito ad APST Research con effetto per il futuro. Ad esempio, inviando un messaggio a APST Research GmbH, Westhafenstraße 1, 13353 Berlino o un'e-mail a <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>. La rispettiva revoca può comportare che le offerte di APST Research non possano più essere messe a disposizione dell'interessato o solo in misura limitata.`,
      },
      {
        data: `Nella misura in cui APST Research basa il trattamento dei dati personali sulla ponderazione degli interessi (art. 6 cpv. 1 frase 1 lett. f GDPR), gli interessati possono opporsi al trattamento. Nell'esercitare tale opposizione, APST Research chiede una spiegazione dei motivi per cui APST Research non dovrebbe trattare i dati personali. In caso di opposizione motivata, APST Research esaminerà la situazione e interromperà o adatterà il trattamento dei dati oppure indicherà all'interessato i motivi legittimi impellenti in base ai quali APST Research continuerà il trattamento.`,
      },
    ],
  },

  finalProvisions: {
    title: "7. Disposizioni finali",
    list: [
      {
        data: `APST Research utilizza misure di sicurezza tecniche e organizzative per proteggere i dati trattati, in particolare contro manipolazioni accidentali o intenzionali, perdita, distruzione o contro attacchi da parte di persone non autorizzate. Le misure di sicurezza vengono continuamente migliorate in linea con gli sviluppi tecnologici.`,
      },
      {
        data: `Se APST Research si avvale di subappaltatori, APST Research adotta misure legali, tecniche e organizzative adeguate per garantire la protezione dei dati personali in conformità con i requisiti legali pertinenti. Si tratta, in particolare, di fornitori di servizi tecnici che supportano APST Research nell'erogazione dei propri servizi.`,
      },
      {
        data: `APST Research aggiornerà periodicamente la politica sulla privacy in base al progresso tecnico e all'ulteriore sviluppo dei suoi servizi. Nella misura in cui la modifica della dichiarazione sulla protezione dei dati non pregiudica l’utilizzo dei dati esistenti, la nuova dichiarazione sulla protezione dei dati entrerà in vigore a partire dalla data del suo aggiornamento. Una modifica all'informativa sulla privacy che influisca sull'uso dei dati già raccolti verrà apportata solo se ragionevole per le persone interessate. In tal caso APST Research avviserà tempestivamente le persone interessate. Le persone interessate hanno il diritto di opporsi alla validità della nuova politica sulla privacy entro quattro (4) settimane dal ricevimento della notifica. In caso di contestazione APST Research si riserva il diritto di risolvere il contratto d'uso. Se non viene sollevata alcuna opposizione entro il termine sopra indicato, la dichiarazione sulla protezione dei dati modificata si considera accettata dall'interessato. APST Research informerà gli interessati nella notifica del loro diritto di opposizione e dell'importanza del termine di opposizione.`,
      },
      {
        data: `APST Research è a disposizione degli interessati tramite l'e-mail <a href="mailto:info@apstresearch.com">info@apstresearch.com</a> e l'indirizzo postale APST Research GmbH, Westhafenstraße 1, 13353 Berlino per ulteriori domande e informazioni sulla protezione dei dati e sul trattamento dei propri dati personali. Per domande, suggerimenti e informazioni sulla protezione dei dati gli interessati possono rivolgersi anche al responsabile esterno della protezione dei dati di APST Research all'indirizzo <a href="mailto:datenschutz@apst-research.de">datenschutz@apst-research.de</a>.`,
      },
    ],
  },
}

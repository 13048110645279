import Store from "./Store";
import { runInAction } from "mobx";
import AppStore from "./AppStore";
import LabResultModel, { ILabResult } from "../models/LabResult";

export default class LabResultStore extends Store<ILabResult, LabResultModel> {
  items = new Map<number, LabResultModel>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: ILabResult[] = []) {
    runInAction(() => {
      items.forEach((item, index) =>
        this.items.set(index, new LabResultModel(this.store, item))
      );
    });
  }
}

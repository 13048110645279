import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { observer } from 'mobx-react-lite';
import { useAppContext } from '../context/Context';
import { formatDate } from '../components/utils/utils';
import { useTranslation } from 'react-i18next';
import InfoText from '../components/info/InfoText';
import { DropdownViewingOption } from '../components/dropdown/Dropdown';
import ErrorBoundary from "../components/error/ErrorBoundary";

ChartJS.register(...registerables, zoomPlugin);

export const ALSFRSDiagram = observer(() => {
    const { store } = useAppContext();
    const { t: msg } = useTranslation(['home']);

    const [selectedValue, setSelectedValue] = useState("alsscore");

    const initialZoomValue = 0;
    const minZoomValue = 0;
    const maxZoomValue = 2;
    const [zoomValue, setZoomValue] = useState(initialZoomValue);
    const adjustedZoomValue = 1 / zoomValue;

    const alsChartRef = React.createRef();
    const surveys = store.survey.all.map((sv) => sv.asJson);
    const [stepSize, setStepSize] = useState(4);
    const [maxValue, setMaxValue] = useState(48);

    const defaultData = surveys.map((sv) => sv.alsscore);
    const [data, setData] = useState(defaultData);
    const labels = surveys.map((sv) => formatDate(sv.date));

    const handleChange = (event) => {
        const selectField = event.currentTarget.value
        setSelectedValue(selectField);

        if (selectField === "alsscore") {
            setData(defaultData);
            setMaxValue(48);
            setStepSize(4);

        } else {
            const extractedFieldValues = surveys.map(item => item[selectField]);
            const selectedField = extractedFieldValues.map(value => Number(value));
            setMaxValue(4);
            setStepSize(1);
            setData(selectedField);
        }
    };

    const handleZoomChange = (event) => {
        const newZoomValue = parseFloat(event.target.value);
        setZoomValue(newZoomValue);
    };

    const dataFormat = {
        labels: labels,
        datasets: [
            {
                label: 'Score',
                data: data,
                borderColor: '#b8e2f6',
                backgroundColor: '#016caa',
                fill: false,
                pointRadius: 7,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                min: -10 * adjustedZoomValue,
                max: 10 * adjustedZoomValue,
            },
            y: {
                ticks: {
                    beginAtZero: true,
                    stepSize: stepSize,
                    max: maxValue,
                },
                beginAtZero: true,
                stepSize: stepSize,
                max: maxValue,
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: "",
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    mode: "x",
                },
                pan: {
                    enabled: true,
                    mode: "x"
                }
            }
        }
    };

    const questionoptions = [
        { value: "alsscore", label: msg("overallScore") },
        { value: "language", label: msg("language") },
        { value: "salvation", label: msg("salvation") },
        { value: "swallow", label: msg("swallow") },
        { value: "handwriting", label: msg("handwriting") },
        { value: "useOfCutlery", label: msg("useOfCutlery") },
        { value: "useOfFeedingTube", label: msg("useOfFeedingTube") },
        { value: "dressingAndPersonalHygiene", label: msg("dressingAndPersonalHygiene") },
        { value: "turningOverInBedAndStraighteningTheBedspread", label: msg("turningOverInBedAndStraighteningTheBedspread") },
        { value: "walking", label: msg("walking") },
        { value: "climbingStairs", label: msg("climbingStairs") },
        { value: "shortnessOfBreath", label: msg("shortnessOfBreath") },
        { value: "shortnessOfBreathWhenLyingDown", label: msg("shortnessOfBreathWhenLyingDown") },
        { value: "breathingAids", label: msg("breathingAids") },
    ];

    const handleResetZoom = () => {
        setZoomValue(initialZoomValue);
    };

    useEffect(() => {
        setData(defaultData);
        setSelectedValue("alsscore");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveys.length]);

    return (
        <div className='alsfrs'>
            <div className="uk-flex uk-flex-between">
                <div>
                    <p className="uk-margin-remove-top uk-text-bold">ALSFRS {msg("diagramTitle")}</p>
                </div>
                <div>
                    <InfoText>
                        <div>
                            <p>
                                {msg("alsfrsDiagramInfoTextOne")}
                            </p>
                            <p>
                                {msg("alsfrsDiagramInfoTextTwo")}
                            </p>
                        </div>
                    </InfoText>
                </div>
            </div>
            <div className="uk-flex uk-flex-between">
                <div>
                    <button className='btn btn-primary' onClick={handleResetZoom}>
                        {msg("resetZoomButton")}
                    </button>
                </div>
                <div>
                    <button className="btn btn-primary" type="button">{msg("viewOptionsButton")}</button>
                    <DropdownViewingOption>
                        {questionoptions.map((option, index) => (
                            <div key={index} >
                                <button
                                    className={`btn btn-primary ${selectedValue === option.value ? 'active' : ''}`}
                                    type="button"
                                    value={option.value}
                                    onClick={handleChange}
                                >
                                    {option.label}
                                </button>
                            </div>
                        ))}
                    </DropdownViewingOption>
                </div>
            </div>
            <ErrorBoundary>
                <div style={{ minHeight: "60vh", height: '500px' }}>
                    <Line ref={alsChartRef} options={options} data={dataFormat} />
                    <input
                        className="uk-range uk-width-1-1"
                        type="range"
                        min={minZoomValue}
                        max={maxZoomValue}
                        step={0.1}
                        value={zoomValue}
                        onChange={handleZoomChange}
                    />
                </div>
            </ErrorBoundary>
        </div>
    );
});
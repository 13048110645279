import  keycloak  from "../keycloakConfig";
import { SUCCESS_ACTION, FAILED_ACTION } from "../models/Snackbar";
import { ISurvey } from "../models/Survey";
import AppStore from "../stores/AppStore";
import UiStore from "../stores/UiStore";
import AppApi from "./AppApi";

export class SurveyApi {
  constructor(
    private api: AppApi,
    private store: AppStore,
    public ui: UiStore
  ) { }

  private createPath() {
    return `${process.env.REACT_APP_API_ENDPOINT}/survey/`;
  }

  private scorePath() {
    return `${process.env.REACT_APP_API_ENDPOINT}/scoreals/`;
  }

  private headers() {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    _headers.append("Accept", "application/json");
    _headers.append("Authorization", `Bearer ${keycloak.token}`);
    return _headers;
  }

  async create(item: ISurvey) {
    const url = this.createPath();
    const options = {
      method: 'POST',
      headers: this.headers(),
      body: JSON.stringify({ ...item }),
    };

    try {
      const response = await fetch(url, {
        ...options,
        mode: "cors",
      });

      if (response.ok) {
        await this.getAll();
        SUCCESS_ACTION(this.ui);
      } else {
        FAILED_ACTION(this.ui);
      }
    } catch (error) { }
  }

  async getAll() {
    const url = this.scorePath();
    this.store.survey.removeAll();

    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: this.headers(),
      });
      if (response.ok) {
        const data = await response.json();
        this.store.survey.load(data);
      }
    } catch (error) { }
  }
}

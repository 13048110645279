import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";

export const config: KeycloakConfig = {
    url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
    realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
    clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT}`,

    // url: `http://localhost:8080/`,
    // realm: `NeuroFilament`,
    // clientId: `neurofilament`,
}

const keycloak = new Keycloak(config);
export default keycloak;

export const initOptions: KeycloakInitOptions = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
    responseMode: "fragment",
    checkLoginIframe: false,
    pkceMethod: 'S256',
    adapter: "default",
    flow: "standard",
    redirectUri: window.location.origin,
}

// url: `https://keycloak.adaire.dev:8443/`,
// realm: `APST`,
// clientId: `neurofilamentapp`,

// url: `http://192.168.1.44:8080/`,
// realm: `NeuroFilament`,
// clientId: `neurofilament`,
import { languages } from "../locales/laguages";
import icons from "../shared/components/utils/icons";
import Asset1 from '../images/Asset1.svg'
import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";

const LandingPage = () => {

    const { t: msg, i18n } = useTranslation(['home']);
    const { keycloak } = useKeycloak();

    function onLoggin() {
        keycloak.login()
    }

    function onRegister() {
        keycloak.register()
    }

    function onChangeLang(e: ChangeEvent<HTMLSelectElement>) {
        i18n.changeLanguage(e.target.value);
        window.localStorage.setItem('lng', e.target.value);
    }

    useEffect(() => {
        const lang = window.localStorage.getItem("lng");
        i18n.changeLanguage(`${lang}`)
    }, [i18n]);

    return (
        <div className="signin-page">
            <div className="sticky" data-uk-sticky="sel-target: .uk-navbar; cls-active: uk-navbar-sticky">
                <nav className="lg-navbar uk-visible@s" data-uk-navbar>
                    <div className="uk-navbar-left uk-margin-left">
                        <div className="account-settings">
                            <img className="logo" src={Asset1} alt="APST" />
                        </div>
                    </div>
                    <div className="navbar-right uk-navbar-right">
                        <ul className="uk-navbar-nav">
                            <li className="btn-li-item uk-margin-right">
                                <select className="uk-select uk-form-medium"
                                    aria-label="Select"
                                    style={{ height: "35px", marginTop: "10px" }}
                                    onChange={onChangeLang}
                                    value={i18n.language}
                                >
                                    {languages.map((lang) => (
                                        <option key={lang.value} value={lang.value}>{lang.label}</option>
                                    ))}
                                </select>
                            </li>
                            <li className="btn-li-item uk-margin-right">
                                <button
                                    className="btn btn-primary"
                                    onClick={onRegister}>
                                    <img alt="st-icon" src={icons.account_circle} className="st-icon uk-margin-small-right" />
                                    {msg('registerButton')}
                                    <img alt="nd-icon" src={icons.chevron_right} className="nd-icon" />
                                </button>
                            </li>
                            <li className="btn-li-item">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={onLoggin} >
                                    <img alt="icon" src={icons.account_circle} className="st-icon uk-margin-small-right" />
                                    {msg('signinButton')}
                                    <img alt="nd-icon" src={icons.chevron_right} className="nd-icon" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </nav>
                <nav className="sm-navbar uk-hidden@s" data-uk-navbar>
                    <div className="account-settings">
                        <img className="logo" src={Asset1} alt="APST" />
                    </div>
                    <div className="uk-margin-small uk-text-right">
                        <select className="uk-select uk-form-small"
                            aria-label="Select"
                            style={{ height: "35px", width: "min-content", position: "absolute", top: "2px", right: "5px" }}
                            onChange={onChangeLang}
                            value={i18n.language}
                        >
                            {languages.map((lang) => (
                                <option key={lang.value} value={lang.value}>{lang.label}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <ul className="uk-navbar-nav ">
                            <li className="btn-li-item uk-margin-right">
                                <button className="btn btn-primary" onClick={onRegister}>
                                    <img alt="st-icon" src={icons.account_circle} className="st-icon uk-margin-small-right" />
                                    {msg('registerButton')}
                                    <img alt="nd-icon" src={icons.chevron_right} className="nd-icon" />
                                </button>
                            </li>
                            <li className="btn-li-item">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={onLoggin}
                                >
                                    <img alt="icon" src={icons.account_circle} className="st-icon uk-margin-small-right" />
                                    {msg('signinButton')}
                                    <img alt="nd-icon" src={icons.chevron_right} className="nd-icon" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div className="sign-in-section">
                <div className="uk-card uk-card-default uk-width-1-2@s uk-width-1-1">
                    <div className="uk-card-body">
                        <h3 className="sign-in-title">{msg('landingTitle')}</h3>
                        <p className="sign-in-text">{msg('landingTextOne')}</p>
                        <p className="sign-in-text">{msg('landingTextTwo')}</p>
                        <p className="sign-in-text">{msg('landingTextThree')}</p>
                    </div>
                </div>
                <div className="uk-card uk-card-default uk-width-1-2@s">
                    <div className="footer">
                        <div className="uk-flex">
                            <div>
                                <p className="text">&copy; APST Research {new Date().getFullYear()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LandingPage
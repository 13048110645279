export const germanrevocation = {

  title: "Widerruf",
  subtitle: "Als Verbraucher steht dem Nutzer ein gesetzliches Widerrufsrecht zu:",

  policy: {
    title: "Widerrufsbelehrung",
    subtitle: "Widerrufsrecht",
    description: `Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (APST Research GmbH, Westhafenstraße 1, 13353 Berlin, Telefon: +49 (0) 30 81031410, E-Mail: <a href="mailto: info@apstresearch.com">info@apstresearch.com</a>) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder eine E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
    `,
  },

  consequences: {
    title: "Folgen des Widerrufs",
    subtitle: "",
    description: `Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.`,
  },
  expiryofrevocation: {
    title: "Erlöschen des Widerrufsrechts",
    subtitle: "",
    description: `Ihr Widerrufsrecht erlischt bei einem Vertrag über die Lieferung von nicht auf einem körperlichen Datenträger befindlichen digitalen Inhalten auch dann, wenn wir mit der Ausführung des Vertrags begonnen haben, nachdem Sie ausdrücklich zugestimmt haben, dass wir mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnen, und Sie Ihre Kenntnis davon bestätigt haben, dass Sie durch Ihre Zustimmung mit Beginn der Ausführung des Vertrags Ihr Widerrufsrecht verlieren.`,
  },
  samplerevocation: {
    title: "Muster-Widerrufsformular",
    description: `(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)`,
    list: [
      {
        data: `APST Research GmbH, Westhafenstraße 1, 13353 Berlin, Telefon: <a href="tel:+49(0)30-81031410">+49 (0) 30-81031410</a>, E-Mail: <a href="mailto: info@apstresearch.com">info@apstresearch.com</a>`,
      },
    ],
    labelOne: "Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*) /die Erbringung der folgenden Dienstleistung (*)",
    labelTwo: "Bestellt am (*) /erhalten am (*)",
    labelThree: "Name des/der Verbraucher(s)",
    labelFour: "Anschrift des/der Verbraucher(s)",
    labelFive: "Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)",
    labelSix: "Datum",
    labelSeven: "(*) Unzutreffendes streichen.",
  },
};

import  keycloak from "../keycloakConfig";
import { ILabResult } from "../models/LabResult";
import AppStore from "../stores/AppStore";
import UiStore from "../stores/UiStore";
import AppApi from "./AppApi";

export class LabResultApi {
  constructor(
    private api: AppApi,
    private store: AppStore,
    public ui: UiStore
  ) { }

  private path() {
    return `${process.env.REACT_APP_API_ENDPOINT}/labresult/`;
  }

  private headers() {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    _headers.append("Accept", "application/json");
    _headers.append("Authorization", `Bearer ${keycloak.token}`);
    return _headers;
  }

  async getAll() {
    const url = this.path();
    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: this.headers(),
      });
      if (response.ok) {
        const data = await response.json();
        this.store.result.load(data as ILabResult[]);
      }
    } catch (error) { }
  }
}

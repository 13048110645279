import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export const defaultPatient: IPatient = {
  user_id: 0,
  keycloak_id: "",
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  gender: "M",
  date_of_birth: "",
  phone: "",
  date_created: "",
  diagnosis: "",
  time_of_symptoms_onset: "",
  request_account_deletion: false,
  request_data_deletion: false,
};

export interface IPatient {
  user_id: number | null;
  keycloak_id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  date_created: string;
  gender: string;
  date_of_birth: string;
  phone: string;
  diagnosis: string;
  time_of_symptoms_onset: string;
  request_account_deletion: boolean;
  request_data_deletion: boolean;
}

export default class PatientModel {
  private patient: IPatient;

  constructor(private store: AppStore, patient: IPatient) {
    makeAutoObservable(this);
    this.patient = patient;
  }

  get asJson(): IPatient {
    return toJS(this.patient);
  }
}

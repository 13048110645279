import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { LabItem } from "./LabItem";
import MODAL_NAMES from "../dialogs/ModalName";
import ViewLabPDFDialog from "../dialogs/pdfdialog/ViewLabPDFDialog";
import LabTabs from "./LabTabs";
import { FAILED_ACTION } from "../../models/Snackbar";
import { NFLDiagramGraph } from "../../shared/charts/NFLDiagramGraph";
import Modal from "../../shared/components/Modal";
import ErrorBoundary from "../../shared/components/error/ErrorBoundary";
import showModalFromId from "../../shared/components/functions/ModalShow";
import { Empty } from "../../shared/components/nodata/Empty";
import Toolbar from "../../shared/components/toolbar/Toolbar";
import { useAppContext } from "../../shared/context/Context";
import useTitle from "../../shared/hooks/useTitle"
import { useTranslation } from "react-i18next";
import  keycloak  from "../../keycloakConfig";

export const LabResults = observer(() => {

    const { t: msg } = useTranslation(['home']);
    const { store, ui } = useAppContext();
    const [pdfBinaryData, setPdfBinaryData] = useState<Blob | null>(null);
    const [selectedTab, setselectedTab] = useState("results-tab");
    const [os, setOs] = useState('');
    useTitle("Lab Result");

    const detectOS = () => {
        const platform = navigator.userAgent;
        if (platform.indexOf('Win') !== -1) return 'Windows';
        if (platform.indexOf('Mac') !== -1) return 'Mac OS';
        if (platform.indexOf('Linux') !== -1) return 'Linux';
        if (platform.indexOf('iPhone') !== -1) return 'iOS';
        if (platform.indexOf('Android') !== -1) return 'Android';
        if (platform.indexOf('iPad') !== -1) return 'iPad';
        return 'Unknown';
    }

    const downloadBlob = (fileName: string, blob: Blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    }

    const onViewPDF = async (id: number, date: string) => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/labpdf/${id}/`;
        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*",
                    Authorization: `Bearer ${keycloak.token}`,
                },
            });
            if (!response.ok) {
                FAILED_ACTION(ui);
                return;
            }
            const data = await response.blob();

            if (os !== "iOS" || "Android") {
                setPdfBinaryData(data);
                showModalFromId(MODAL_NAMES.PATIENT.LAB_PDF_MODAL);
            } else {
                downloadBlob(`Lab Results Document ${date}.pdf`, data);
            }
        } catch (error) {
            FAILED_ACTION(ui);
        }
    };

    const sortedData = [...store.result.all].sort((a, b) => {
        return new Date(b.asJson.date).getTime() - new Date(a.asJson.date).getTime();
    });

    useEffect(() => {
        const detectedOs = detectOS();
        setOs(detectedOs);
    }, []);

    return (
        <div className="uk-section profile">
            <div className="uk-container uk-container-large">
                <div className="sticky-top">
                    <ErrorBoundary>
                        <Toolbar
                            leftControls={
                                <LabTabs
                                    selectedTab={selectedTab}
                                    setselectedTab={setselectedTab}
                                />
                            }
                            rightControls={<></>}
                        />
                    </ErrorBoundary>
                </div>
                {selectedTab === "results-tab" &&
                    <>
                        <div className="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-grid-small uk-margin" data-uk-grid>
                            {sortedData.map((re, index) => (
                                <div key={index}>
                                    <LabItem key={index} result={re.asJson} onViewPDF={onViewPDF} msg={msg} />
                                </div>
                            ))}
                        </div>
                        {sortedData.length === 0 && <Empty />}
                    </>
                }
                {selectedTab === "graph-tab" &&
                    <div className="uk-margin custombg uk-border-rounded">
                        <NFLDiagramGraph />
                    </div>
                }
            </div>
            <Modal modalId={MODAL_NAMES.PATIENT.LAB_PDF_MODAL}>
                <ViewLabPDFDialog pdfBinaryData={pdfBinaryData} setPdfBinaryData={setPdfBinaryData} />
            </Modal>
        </div >
    )
});
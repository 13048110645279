import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IHeadProp {
  title: string;
  children?: JSX.Element;
}
export const CustomCloseAccordion = ({ title, children }: IHeadProp) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation(['home']);

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="adb-made-accordion">
      <div className="position-relative" onClick={onClick}>
        <h4 className="adb-title" onClick={onClick}>
          {title} {!isExpanded ? <small className="uk-text-primary">{t("questionnaireReadMore")}</small> : <small className="uk-text-primary">{t("questionnaireShowLess")}</small>}
        </h4>
        <div className="more">
          <span
            onClick={onClick}
            className={`icon ${isExpanded ? "expanded" : ""}`}
            data-uk-icon="chevron-down"
          ></span>
        </div>
      </div>
      <div className={`adb-accordion ${isExpanded ? "expanded" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export const CustomOpenAccordion = ({ title, children }: IHeadProp) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="adb-made-accordion">
      <div className="position-relative">
        <h4 className="adb-title" onClick={onClick}>
          {title}
        </h4>
        <div className="more">
          <span
            onClick={onClick}
            className={`icon ${isExpanded ? "expanded" : ""}`}
            data-uk-icon="chevron-down"
          ></span>
        </div>
      </div>
      <div
        className={`adb-accordion ${isExpanded ? "expanded" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};

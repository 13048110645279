export const italianinfolibrary = {
  aboutAllAbbrev: {
    title: "ALS, NFL ed ALSFRS",
    description: `La proteina <b>catena leggera dei neurofilamenti (NfL)</b> è un componente importante nella struttura delle cellule nervose (neuroni) e delle loro estensioni (assoni) nel cervello e nel midollo spinale, nonché nei cordoni nervosi nel corpo umano. Quando le cellule nervose sono danneggiate, come nel caso della rottura dei motoneuroni nella <b>SLA</b>, NfL viene rilasciato nel liquido cerebrospinale (CSF) e nel sangue (siero).
              L'NfL può essere rilevato nel siero del sangue e nel liquido cerebrospinale utilizzando metodi di analisi speciali.
              <br /><br />
              La NfL è considerata un <b>marcatore prognostico e di progressione della SLA</b>. Il valore NfL nel sangue è, oltre ai sintomi della SLA, una caratteristica del decorso della malattia. Un valore elevato di NfL nel siero è spesso associato ad una maggiore velocità nella progressione dei sintomi della SLA.
              <br /><br />
              Il livello di NfL nel sangue aumenta con l’età. Gli studi hanno concordato sui valori soglia al di sopra dei quali il livello di NfL è patologico nella SLA. Il diagramma nell'app ALS Analytics mostra i singoli valori NfL nel sangue nel corso della malattia in funzione dell'età. I valori NfL al di sopra dell'area blu nel diagramma sono considerati decisamente patologici.
              <br /><br />
              Secondo le attuali scoperte scientifiche, il valore NfL nel sangue è abbastanza stabile, per cui un cambiamento nella concentrazione sierica è prevedibile solo nel corso di diversi mesi. Pertanto, l'intervallo tra gli esami NfL dovrebbe essere di circa 6 mesi.
              <br /><br />
              La <b>ALS Functional Rating Scale (ALSFRS)</b> è una scala clinica utilizzata per valutare la gravità e la progressione della SLA. La scala ALSFRS è un questionario composto da 12 domande sui sintomi tipici della SLA e 5 opzioni di risposta (0-4 punti). Alle domande può rispondere sia il medico che il paziente.
              <br /><br />
              La valutazione online del paziente dell'ALSFRS consente di registrare il decorso individuale della malattia e di distinguere tra diverse forme di progressione della SLA. Viene fatta una distinzione tra diverse velocità di progressione come progressione rapida o lenta, compromissione precoce o tardiva della funzione respiratoria, sintomi bulbari precoci o tardivi, limitazione spaziale o diffusione della malattia.
              paresi.
              <br /><br />
              Il <b>valore di progressione</b> nell'app ALS Analytics offre alle persone affette da SLA l'opportunità di classificare la velocità della progressione della propria malattia come veloce, lenta o media. Serve come guida per la persona colpita, nonché per i suoi medici e terapisti. Il valore di progressione viene calcolato dal valore totale ALSFRS attuale e dalla data di insorgenza dei sintomi della malattia.
              <br /><br />
              I dati di autovalutazione dell'ALSFRS sono visibili solo nel tuo account dell'app ALS Analytics.
              <br /><br />
              Previo tuo consenso, i dati potranno essere resi disponibili anche a istituzioni universitarie e produttori di dispositivi medici o medicinali per <b>scopi di ricerca e sviluppo</b>. A tale scopo gli istituti di ricerca e i produttori ricevono i dati solo in forma pseudonimizzata o anonimizzata.`,
  },
  howToOrder: {
    title: "Come ordinare",
    description: `
              Puoi ordinare un test per la NfL visitando il nostro negozio online utilizzando l'app ALS Analytics.
              <br /><br />
              Per accedere al negozio online, clicca sulla scheda "Ordina kit di analisi NfL" nel menu di navigazione nella pagina iniziale dell'app.
              <br /><br />
              Dopo l'acquisto ti consegneremo un kit per la raccolta dei campioni di sangue. Il kit per la raccolta dei campioni di sangue verrà consegnato entro 5-7 giorni con spedizione standard, a seconda della tua posizione.
              <br /><br />
              Il kit viene fornito con tutto il necessario per restituire il campione ad APST Research. Il kit includerà una busta di reso prepagata o un'etichetta di reso sulla scatola. Consultare le istruzioni prima di sigillare la scatola per la raccolta dei campioni.
              <br /><br />Non è necessaria alcuna prescrizione quando si ordina il nostro test NfL.
              <br /><br />
              <br /><br />
              Accettiamo i seguenti metodi di pagamento:
              <ul>
                <li>Visa</li>
                <li>Mastercard</li>
                <li>American Express</li>
                <li>Paypal</li>
                <li>Apple Pay</li>
              </ul>
              Al momento non accettiamo assicurazioni, tuttavia, lavoriamo per mantenere i nostri test il più convenienti possibile.`,
  },
  sendingKits: {
    title: "Invio dei kit",
    description: `Ti consegneremo un kit per la raccolta dei campioni a domicilio con tutto il necessario per restituire il campione di sangue ad APST Research.
              <br /><br />
              Il nostro kit per la raccolta dei campioni di sangue viene consegnato in una confezione semplice generica senza alcun riferimento all'app ALS Analytics sulla confezione.
              <br /><br />
              Generalmente, il kit per la raccolta dei campioni di sangue arriverà entro 5 giorni lavorativi, ma ciò dipende dalla tua posizione.
              <br /><br />
              La spedizione al cliente e la spedizione di restituzione ad APST Research sono incluse nel prezzo del kit di test.
              <br /><br />
              Una volta raccolto il campione, sigillare il campione di sangue nella busta fornita nel kit. Riporre quindi la busta con il campione nella scatola del kit. Se sulla borsa è inclusa un'etichetta, assicurati di averla compilata completamente. Chiudere la scatola del kit. Il kit includerà una busta di reso prepagata o un'etichetta di reso sulla scatola per rispedire il campione ad APST Research. Per favore
              rispedire immediatamente il kit. Per ulteriori informazioni consultare le istruzioni contenute nel kit.
              <br /><br />
              Al momento spediamo solo nei seguenti paesi:
              <ul>
                <li>Austria</li>
                <li>Germania</li>
                <li>Italia</li>
                <li>Svizzera</li>
              </ul>
              Resta sintonizzato sul nostro sito Web e sui social media per gli aggiornamenti, poiché vorremmo supportare le persone in più località man mano che ci espandiamo.`,
  },
  bloodSample: {
    title: "Estrazione del campione di sangue",
    description: `Raccogli il campione di sangue dal tuo medico.
              <br /><br />
              Il kit include tutto ciò di cui il tuo medico ha bisogno per raccogliere il campione.
              <br /><br />
              Il nostro kit per la raccolta dei campioni di sangue viene fornito con istruzioni che ti guideranno su come raccogliere il campione dal tuo medico.`,
  },
  aboutLabAnalysis: {
    title: "Informazioni sull'analisi di laboratorio",
    description: `I vostri campioni vengono elaborati nello stesso laboratorio utilizzato dagli specialisti ambulatoriali e dagli ospedali.
              <br /><br />
              Tutti i campioni vengono analizzati presso il Labor Berlin -Charité Vivantes GmbH di Berlino che è un laboratorio certificato e accreditato nell'UE.
              <br /><br />
              Il nostro laboratorio è sottoposto a frequenti controlli di qualità per garantire i più elevati standard di test. Le apparecchiature e gli analizzatori utilizzati nel nostro laboratorio partner sono tutti convalidati per i test NfL specifici del vostro campione.
              <br /><br />
              Una volta che APST Research riceve il campione, i risultati verranno consegnati tra 5 e 14 giorni lavorativi al tuo account dell'app ALS Analytics.
              <br /><br />
              Quando i risultati saranno pronti, riceverai un'e-mail o un messaggio push sul tuo cellulare per informarti che i risultati sono disponibili. Puoi quindi accedere ai risultati nel tuo account dell'app ALS Analytics.
              <br /><br />
              Ti forniamo anche una copia PDF dei risultati che puoi stampare e portare con te dal tuo medico.`,
  },
  cancellingKit: {
    title: "Come annullare l'acquisto",
    description: `Se non vuoi più utilizzare il tuo kit o cambi idea, hai diritto di recedere dal presente contratto di acquisto entro quattordici giorni senza fornire alcuna motivazione. Il periodo di recesso è di quattordici giorni dalla data di conclusione del contratto di acquisto.
              <br /><br />
              Per esercitare il diritto di recesso, devi informarci della tua decisione di recedere dal presente contratto d'acquisto mediante una dichiarazione chiara (ad esempio lettera inviata per posta o e-mail):
              <br /><br />
              <br /><br />
              APST Research GmbH
              <br /><br />
              Westhafenstraße 1<br /><br />
              13353 Berlin, Germany.
              <br /><br />
              <br /><br />
              E-Mail: <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>
              <br /><br />
              Puoi trovare ulteriori informazioni sulla cancellazione e sul rimborso nella nostra politica di revoca.
              <br /><br />
              Noi di APST Research ci impegniamo a fornire un buon servizio clienti e vorremmo conoscere la tua esperienza. Se hai feedback, suggerimenti per migliorare o se la tua esperienza con l'app ALS Analytics non ha soddisfatto le tue aspettative, faccelo sapere scrivendo a <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>.`,
  },
}

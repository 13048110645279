import { useState } from "react";
import MODAL_NAMES from "../ModalName";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ISurvey, defaultSurvey } from "../../../models/Survey";
import { hideModalFromId } from "../../../shared/components/functions/ModalShow";
import { formatDate } from "../../../shared/components/utils/utils";
import { useAppContext } from "../../../shared/context/Context";
import i18next from "../../../locales/config";

const ViewScoresModal = observer(() => {
  const { store } = useAppContext()
  const [survey, setSurvey] = useState<ISurvey>({ ...defaultSurvey });
  const { t: msg } = useTranslation(['home']);

  const onCancel = () => {
    hideModalFromId(MODAL_NAMES.PATIENT.VIEW_SCORES_MODAL)
  }

  const getYesMessage = () => {
    switch (i18next.language) {
      case 'en':
        return <p className="score">Yes</p>;
      case 'de':
        return <p className="score">Ja</p>;
      case 'it':
        return <p className="score">Si</p>;
      default:
        return <p className="score">Yes</p>;
    }
  }

  const getNoMessage = () => {
    switch (i18next.language) {
      case 'en':
        return <p className="score">No</p>;
      case 'de':
        return <p className="score">Nien</p>;
      case 'it':
        return <p className="score">No</p>;
      default:
        return <p className="score">No</p>;
    }
  }

  useEffect(() => {
    if (store.survey.selected)
      setSurvey(store.survey.selected);
    else {
      setSurvey({ ...defaultSurvey });
    }
  }, [store.survey.selected])

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button className="uk-modal-close-default" type="button" data-uk-close></button>
      <h3 className="uk-text-small uk-text-bold">{formatDate(survey.date)}</h3>
      <div className="dialog-content uk-position-relative">
        <div className="results">
          <div className="uk-margin">
            <div className="result">
              <p className="title">{msg("language")}</p>
              <p className="score">{survey.language}</p>
            </div>
            <div className="result">
              <p className="title">{msg("salvation")}</p>
              <p className="score">{survey.salvation}</p>
            </div>
            <div className="result">
              <p className="title">{msg("swallow")}</p>
              <p className="score">{survey.swallow}</p>
            </div>
            <div className="result">
              <p className="title">{msg("handwriting")}</p>
              <p className="score">{survey.handwriting}</p>
            </div>
            <div className="result">
              <p className="title">{msg("useFeedingTube")}</p>
              {survey.useFeedingTube === "Yes" ? getYesMessage() : getNoMessage()}
            </div>
            <div className="result">
              <p className="title">{msg("useOfCutlery")}</p>
              <p className="score">{survey.useOfCutlery}</p>
            </div>
            <div className="result">
              <p className="title">{msg("useOfFeedingTube")}</p>
              <p className="score">{survey.useOfFeedingTube}</p>
            </div>
            <div className="result">
              <p className="title">{msg("dressingAndPersonalHygiene")}</p>
              <p className="score">{survey.dressingAndPersonalHygiene}</p>
            </div>
            <div className="result">
              <p className="title">{msg("turningOverInBedAndStraighteningTheBedspread")}</p>
              <p className="score">{survey.turningOverInBedAndStraighteningTheBedspread}</p>
            </div>
            <div className="result">
              <p className="title">{msg("walking")}</p>
              <p className="score">{survey.walking}</p>
            </div>
            <div className="result">
              <p className="title">{msg("climbingStairs")}</p>
              <p className="score">{survey.climbingStairs}</p>
            </div>
            <div className="result">
              <p className="title">{msg("shortnessOfBreath")}</p>
              <p className="score">{survey.shortnessOfBreath}</p>
            </div>
            <div className="result">
              <p className="title">{msg("shortnessOfBreathWhenLyingDown")}</p>
              <p className="score">{survey.shortnessOfBreathWhenLyingDown}</p>
            </div>
            <div className="result">
              <p className="title">{msg("breathingAids")}</p>
              <p className="score">{survey.breathingAids}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-text-right">
        <button
          className="btn btn-warning"
          type="button" onClick={onCancel}>
          {msg("cancelButton")}
        </button>
      </div>
    </div>
  );
});

export default ViewScoresModal;
export const englishSurvey = {
  page: {
    title: `ALSFRS-R-SE, Amyotrophic Lateral Sclerosis Functional Rating Scale – Revised – self-explanatory)`,
    description: `The self-explanatory Amyotrophic Lateral Sclerosis Functional Rating Scale – Revised (ALSFRS-R-SE) is comprised 
of various motor functioning items that typically have limiting characteristics in ALS. The ALSFRS-R-SE 
assessment can be performed by patients themselves as well as by others (e.g., an attending physician, a 
relative, a healthcare professional) following an interview with the affected. Please carefully read the 
explanations and options and provide an assessment of functionality, and, respectively, relevant ALS-related 
limitations that reflect actual capacities at the time of filling in the questionnaire. If the cause of a limitation in any functional area is attributable to a medical condition other than ALS, or if a
limitation was already present before the onset of ALS (e.g., gait impairment following hip replacement surgery) 
the respective item can be assessed as “normal” (4 score points). Functionality should always be assessed 
relative to one’s status before the onset of initial ALS symptoms. The affected may deviate from this 
recommendation if they are experiencing additional limitations that are likely to be due to ALS. Once an 
approach is chosen, please be consistent in following it when answering all questions on this and on future 
ALSFRS-R-SE assessments.`,
  },
  speech: {
    no: "1.",
    title: "Speech",
    qid: "speech",
    options: [
      {
        title: "Normal",
        description: "No excess saliva accumulates in the mouth.",
        value: 4,
        letter: "a",
      },
      {
        title: "Detectable speech disturbance",
        description: "Speech, articulation or phonation have changed, as perceived either by the affected themselves or by their immediate contacts. This may present as slurred speech or hoarseness of voice.",
        value: 3,
        letter: "b",
      },
      {
        title: "Intelligible speech with repetition",
        description:
          "Frequent repetition of single words or parts of a sentence are required to convey meaning.",
        value: 2,
        letter: "c",
      },
      {
        title: "Speech combined with non-vocal communication",
        description:
          "Writing things down, use of communication aids, and similar methods are needed to convey meaning.",
        value: 1,
        letter: "d",
      },
      {
        title: "Loss of useful speech",
        description:
          "Use of communication aids or similar methods are always required.",
        value: 0,
        letter: "e",
      },
    ],
  },
  salvation: {
    no: "2.",
    title: "Salivation",
    qid: "salivation",
    options: [
      {
        title: "Normal",
        description: "No excess saliva accumulates in the mouth.",
        value: 4,
        letter: "a",
      },
      {
        title: "Slight but definite excess of saliva in mouth; night-time drooling may take place",
        description: "Increased accumulation of saliva in the mouth; however, subjectively not an impairment or impediment and no loss of saliva during the day.",
        value: 3,
        letter: "b",
      },
      {
        title: "Moderately excessive saliva; may experience minimal drooling",
        description:
          "During the day, a tissue is occasionally used to dab the edges of the mouth.",
        value: 2,
        letter: "c",
      },
      {
        title: "Marked excess of saliva with some drooling",
        description:
          "Regular loss of saliva, a tissue is used often but not constantly. ",
        value: 1,
        letter: "d",
      },
      {
        title: "Marked drooling",
        description:
          "Permanent use of tissues or a suction device is required.",
        value: 0,
        letter: "e",
      },
    ],
  },
  swallowing: {
    no: "3.",
    title: "Swallowing",
    qid: "swallowing",
    options: [
      {
        title: "Normal",
        description: "Swallowing any type of food or liquid is unproblematic.",
        value: 4,
        letter: "a",
      },
      {
        title: "Minor swallowing problems - occasional choking",
        description:
          "Food intake takes longer; food must be cut into smaller bites and swallowed with care. Occasionally, choking on food or higher frequency of coughing is observed.",
        value: 3,
        letter: "b",
      },
      {
        title: "Dietary consistency change",
        description: `Difficulty swallowing (dysphagia) and trouble with certain consistencies of food and beverages results in the avoidance of some types of food consistencies (e.g., meat, dry biscuits, nuts). Dietary supplements or thickeners may be used due to difficulty in swallowing.`,
        value: 2,
        letter: "c",
      },
      {
        title: "Supplemental tube feeding",
        description:
          "Due to dysphagia, food intake has become so difficult that an enteral feeding tube (PEG) must be fitted or is highly recommended by the physician to supplement caloric intake and/or prevent choking on food.",
        value: 1,
        letter: "d",
      },
      {
        title: "Exclusively enteral tube feeding",
        description:
          "Food and liquid intake happen exclusively via a feeding tube; oral food intake is impossible due to high-grade dysphagia",
        value: 0,
        letter: "e",
      },
    ],
  },
  handwriting: {
    no: "4.",
    title: "Handwriting",
    desc: "The subject of this assessment is writing with one’s dominant hand (writing hand) in the usual posture.",
    qid: "handwriting",
    options: [
      {
        title: "Normal",
        description:
          "Writing with the dominant writing hand causes no problems",
        value: 4,
        letter: "a",
      },
      {
        title: "Slow or sloppy but all words are legible",
        description:
          "Writing is more difficult, or alternately, the appearance of a person's written text has changed even though the words remain legible.",
        value: 3,
        letter: "b",
      },
      {
        title: "Not all words are legible",
        description:
          "Some written words are illegible. Writing aids are used to promote legibility.",
        value: 2,
        letter: "c",
      },
      {
        title: "No words are legible, but can still grip pen",
        description:
          "Holding a pen is possible; however, anything beyond signing or writing one's own letter is not.",
        value: 1,
        letter: "d",
      },
      {
        title: "Unable to grip pen",
        description: "Holding a pen is impossible.",
        value: 0,
        letter: "e",
      },
    ],
  },
  useFeedingTube: {
    no: "5.",
    title: "Regularly using a feeding tube?",
    qid: "usefeedingtube",
    options: [
      {
        title: "Yes", //handleFeedingTubeAndUtensils
        value: "Yes",
      },
      {
        title: "No", //useOfCutlery
        value: "No",
      },
    ],
  },
  useOfCutlery: {
    no: "5a.",
    title: "(No) Use of cutlery.",
    desc: "Pertaining to persons not regularly using an enteral feeding tube for caloric intake.",
    qid: "useofcutlery",
    options: [
      {
        title: "Normal",
        description:
          "The use of cutlery is not problematic. Problems would be, for example, the use of knives and forks instead of chopsticks or the inclination to use a spoon more often.",
        value: 4,
        letter: "a",
      },
      {
        title: "Somewhat slow and clumsy, but no help needed",
        description:
          "Eating takes more time due to impairment of the hands. Method of handing cutlery has changed, but its use is still possible without assistance.",
        value: 3,
        letter: "b",
      },
      {
        title:
          "Can cut most foods, although slowly and clumsily; some help is needed",
        description:
          "Assistance is needed on occasion when cutting certain types of foods; alternately, eating aids such as special cutlery are in use.",
        value: 2,
        letter: "c",
      },
      {
        title:
          "Food must be cut by someone else, but can still feed themself slowly",
        description:
          "Assistance is needed to cut solid food and on most other occasions. However, eating on one's own is still possible (e.g., using a fork or a spoon).",
        value: 1,
        letter: "d",
      },
      {
        title: "Total dependence",
        description:
          "The affected person is unable to use cutlery (e.g., a fork or a spoon) on their own, and can only eat when fed.",
        value: 0,
        letter: "e",
      },
    ],
  },
  useOfFeedingTube: {
    no: "5b.",
    title: "(Yes) Handle feeding tube and utensils.",
    desc: "For persons regularly in need of a feeding tube for caloric intake. The subject of this assessment is manual dexterity.",
    qid: "useOfFeedingTube",
    options: [
      {
        title: "Normal",
        description:
          "The tube can be handled autonomously, and locks and packets can be opened and closed without assistance.",
        value: 4,
        letter: "a",
      },
      {
        title: "Clumsy but able to perform all manipulations independently",
        description:
          "No assistance is needed when handling the feeding tube, however use is somewhat difficult.",
        value: 3,
        letter: "b",
      },
      {
        title: "Some help needed with closures and fasteners",
        description:
          "Handling the feeding tube is done more or less independently. Assistance is needed when opening locks and fasteners",
        value: 2,
        letter: "c",
      },
      {
        title: "Provides minimal assistance to caregiver",
        description:
          "Another person mostly handles the feeding tube. The affected can only carry out minimal actions themselves.",
        value: 1,
        letter: "d",
      },
      {
        title: "Total dependence",
        description:
          "Handling the feeding tube is done entirely by another person. No actions can be executed by the affected.",
        value: 0,
        letter: "e",
      },
    ],
  },
  dressingAndHygiene: {
    no: "6.",
    title: " Dressing and hygiene",
    qid: "dressingandhygiene",
    options: [
      {
        title: "Normal",
        description:
          "Getting dressed and tending to personal hygiene are unproblematic.",
        value: 4,
        letter: "a",
      },
      {
        title:
          "Independent and complete self-care requires effort and is less efficient",
        description:
          "Getting (un)dressed and tending to personal hygiene are executed more slowly than before but are performed autonomously and require neither aids nor assistance from another person.",
        value: 3,
        letter: "b",
      },
      {
        title: "Intermittent assistance or substitute methods",
        description:
          "At times, another person is called upon to assist, or strategies are developed to counteract impairment (e.g. wearing clothes that are easy to put on or take off, getting (un)dressed or showering while sitting down, use of aids).",
        value: 2,
        letter: "c",
      },
      {
        title: "Needs attendant for self-care",
        description:
          "Another person is required on a regular basis to (un)dress and attend to the affected person’s personal hygiene.",
        value: 1,
        letter: "d",
      },
      {
        title: "Total dependence",
        description:
          "Dressing, undressing and personal hygiene must be entirely performed by another person.",
        value: 0,
        letter: "e",
      },
    ],
  },
  turningInBedAndAdjustingTheBedClothes: {
    no: "7.",
    title: "Turning in bed and adjusting bed clothes.",
    qid: "turninginbed",
    options: [
      {
        title: "Normal",
        description:
          "Turning in bed and handling blankets do not cause problems.",
        value: 4,
        letter: "a",
      },
      {
        title: "Somewhat slow and clumsy, but no help needed",
        description: "Turning in bed or handling blankets is difficult",
        value: 3,
        letter: "b",
      },
      {
        title:
          "Can turn on their own and adjust sheets, but with great difficulty",
        description:
          "Turning in bed and handling blankets is possible but requires great effort. Either action may require support, or a grip may be used when turning in bed. ",
        value: 2,
        letter: "c",
      },
      {
        title:
          "Can initiate action, but not turn or adjust sheets without assistance",
        description:
          "The actions of turning in bed and handling blankets can be initiated, however another person's assistance is required to complete these actions.",
        value: 1,
        letter: "d",
      },
      {
        title: "Total dependence",
        description:
          "Assistance is consistently required when turning in bed or handling blankets.",
        value: 0,
        letter: "e",
      },
    ],
  },
  walking: {
    no: "8.",
    title: "Walking",
    qid: "walking",
    options: [
      {
        title: "Normal",
        description: "No change in walking ability",
        value: 4,
        letter: "a",
      },
      {
        title: "Minor ambulation difficulties",
        description:
          "Changes, such as walking more slowly, stumbling, or a loss of stability, are apparent, although the affected does not require outside assistance on a regular basis, either in the form of another person, a walking aid (e.g., foot lifter, cane, walkers) or holding on to a stable object. ",
        value: 3,
        letter: "b",
      },
      {
        title: "Walks with assistance",
        description:
          "The affected regularly requires assistance when walking — either in the form of holding on to something, or, outside the home, use of a foot lifter, walking aids or help from another person",
        value: 2,
        letter: "c",
      },
      {
        title: "Nonambulatory functional movement",
        description:
          "Targeted leg movements are still possible. Standing with support, e.g., for transfer, can be possible. The affected has no ambulatory capacity, not even with the assistance of another person.",
        value: 1,
        letter: "d",
      },
      {
        title: "No purposeful leg movement",
        description:
          "The legs cannot support the weight of the body (e.g., for transfer), no purposeful movements can be executed, such as helping with care activities.",
        value: 0,
        letter: "e",
      },
    ],
  },
  climbingTheStairs: {
    no: "9.",
    title: "Climbing the stairs",
    qid: "climbingthestairs",
    options: [
      {
        title: "Normal",
        description: "No change is observed when climbing the stairs.",
        value: 4,
        letter: "a",
      },
      {
        title: "Slow",
        description:
          "Climbing the stairs without taking a break or feeling unstable is possible if done slowly.",
        value: 3,
        letter: "b",
      },
      {
        title: "Mild unsteadiness or fatigue",
        description:
          "Climbing the stairs is accompanied by a feeling of instability, and breaks might be necessary. Use of a handrail or assistance from another person are not absolutely necessary.",
        value: 2,
        letter: "c",
      },
      {
        title: "Needs assistance",
        description:
          "Climbing the stairs cannot be executed without use of a handrail or assistance from another person.",
        value: 1,
        letter: "d",
      },
      {
        title: "Cannot do",
        description:
          "Stairs cannot be climbed, even with assistance or support.",
        value: 0,
        letter: "e",
      },
    ],
  },
  dyspneaAndshortnessOfBreath: {
    no: "10.",
    title: "Dyspnea and shortness of breath.",
    qid: "dyspneaandshortness",
    options: [
      {
        title: "None",
        description:
          "No dyspnea or shortness of breath when performing daily routines at normal intensity",
        value: 4,
        letter: "a",
      },
      {
        title: "Occurs when walking",
        description:
          "Dyspnea or shortness of breath may occur when walking at a normal pace or performing activities at moderate intensity.",
        value: 3,
        letter: "b",
      },
      {
        title:
          "Occurs with one or more of the following: eating, bathing, dressing (ADL)",
        description:
          "Dyspnea or shortness of breath may occur when performing activities at low intensity or when talking for longer periods of time.",
        value: 2,
        letter: "c",
      },
      {
        title:
          "Difficulty breathing when at rest, including either sitting or lying down",
        description:
          "Dyspnea or shortness of breath in the absence of any physical strain when either sitting and/or lying down.",
        value: 1,
        letter: "d",
      },
      {
        title:
          "Significant difficulty breathing, mechanical respiratory support may be needed",
        description:
          "Significant dyspnea or shortness of breath is present when at rest; mask ventilation (non-invasive ventilation) or ventilation via tracheostomy must be applied to alleviate dyspnea and shortness of breath.",
        value: 0,
        letter: "e",
      },
    ],
  },
  sleepDisturbanceBreathingProblems: {
    no: "11.",
    title: "Sleep disturbance due to breathing problems.",
    desc: "If mechanical ventilation is usually provided during the night, but sleep is possible without it, nighttime breathing should be assessed without the use of ventilation.",
    qid: "sleepdisturbance",
    options: [
      {
        title: "None",
        description:
          "Falling asleep and sleeping through the night are unimpaired by dyspnea or shortness of breath.",
        value: 4,
        letter: "a",
      },
      {
        title:
          "Some difficulty sleeping at night due to shortness of breath, more than two pillows are not routinely used.",
        description:
          "Dyspnea and shortness of breath are present at night and when lying down. Breathing may be improved by sleeping on one side. To support the torso, a maximum of two pillows are used or the head section of the bed may be elevated by no more than 30 degrees.",
        value: 3,
        letter: "b",
      },
      {
        title: "More than two pillows are needed in order to sleep",
        description:
          "When lying down flat on one's back, breathing is noticeably bothersome, which in turn disturbs the process of falling asleep and sleeping through the night. To support the torso, three or more pillows are used or the head section of the bed is elevated by more than 30 degrees.",
        value: 2,
        letter: "c",
      },
      {
        title: "Can only sleep sitting up",
        description:
          "A seated position must be assumed, either in bed or on a chair, to sleep.",
        value: 1,
        letter: "d",
      },
      {
        title: "Unable to sleep",
        description:
          "Due to dyspnea or shortness of breath, sleep is impossible without mask ventilation (non-invasive ventilation) or ventilation via tracheostomy. Mechanical ventilation is in regular use to alleviate symptoms. ",
        value: 0,
        letter: "e",
      },
    ],
  },
  mechanicalVentilation: {
    no: "12.",
    title: "Mechanical ventilation",
    qid: "mechanicalventilation",
    options: [
      {
        title: "None",
        description:
          "Breathing is always an autonomous action, not requiring use of mechanical ventilation. Nocturnal air pressure support (i.e., CPAP therapy to treat sleep apnea syndrome) does not constitute mechanical ventilation.",
        value: 4,
        letter: "a",
      },
      {
        title: "Intermittent use of non-invasive ventilation",
        description:
          "Mask ventilation (non-invasive ventilation, e.g., BiPAP) is in use at irregular intervals or for a shorter period of time than the normal nocturnal sleep cycle.",
        value: 3,
        letter: "b",
      },
      {
        title: "Continuous use of non-invasive ventilation during the night",
        description:
          "Mask ventilation (non-invasive ventilation) is in regular use at night and possibly on an hourly basis during the day (a total of 8 to 22 hours in any 24-hour cycle).",
        value: 2,
        letter: "c",
      },
      {
        title:
          "Continuous use of non-invasive ventilation during the night and day",
        description:
          "Mask ventilation (non-invasive ventilation) is in use almost all of the time (more than 22 hours in any 24-hour cycle).",
        value: 1,
        letter: "d",
      },
      {
        title: "Invasive mechanical ventilation by intubation or tracheostomy",
        description:
          "Continuous mechanical ventilation via a ventilation tube (intubation) or tracheostomy.",
        value: 0,
        letter: "e",
      },
    ],
  },
};

import AppStore from "../stores/AppStore";
import AuthApi from "./AuthApi";
import { LabResultApi } from "./LabResultApi";
import { PatientApi } from "./PatientApi";
import { SurveyApi } from "./SurveyApi";
import { NotificationApi } from "./NotificationApi";
import UiStore from "../stores/UiStore";

export default class AppApi {
  auth: AuthApi;
  result: LabResultApi;
  survey: SurveyApi;
  patient: PatientApi;
  notification: NotificationApi;

  constructor(store: AppStore, ui: UiStore) {
    this.auth = new AuthApi(this, store, ui);
    this.result = new LabResultApi(this, store, ui);
    this.survey = new SurveyApi(this, store, ui);
    this.patient = new PatientApi(this, store, ui);
    this.notification = new NotificationApi(this, store, ui);
  }
}

import { useState } from "react";
import icons from "../utils/icons";

interface IProps {
  children: JSX.Element;
}
const InfoText = (props: IProps) => {
  const { children } = props;

  const [isVisible, setIsVisible] = useState(false);

  const toggleDescription = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={`field-info ${isVisible ? 'active' : ''} align-center`}>
      <img alt="icon"
        src={isVisible ? icons.cancel : icons.help_info}
        className="icon"
        onClick={toggleDescription} />
      <div className={`description`}>
        {children}
      </div>
    </div>
  );
};

export default InfoText;

export const italianSurvey = {
  page: {
    title: "ALSFRS-R-SE, scala di valutazione funzionale della sclerosi laterale amiotrofica – rivista – autoesplicativa)",
    description: `La scala di valutazione funzionale della sclerosi laterale amiotrofica – rivista (ALSFRS-R-SE) autoesplicativa comprende vari elementi del funzionamento motorio che tipicamente hanno caratteristiche
     limitanti nella SLA. La valutazione ALSFRS-R-SE può essere eseguita dai pazienti stessi così come da altri (ad esempio, un medico curante, un parente, un operatore sanitario) a seguito di un colloquio
      con la persona interessata. Si prega di leggere attentamente le spiegazioni e le opzioni e di fornire una valutazione della funzionalità e, rispettivamente, delle limitazioni rilevanti relative
       all'ALS che riflettono le capacità effettive al momento della compilazione del questionario. Se la causa di una limitazione in qualsiasi area funzionale è attribuibile a una condizione medica diversa dalla SLA, 
       o se la limitazione era già presente prima dell'insorgenza della SLA (ad esempio, disturbi dell'andatura a seguito di un intervento di sostituzione dell'anca), il rispettivo elemento può essere valutato come “normale”. ” (4 punti).
        La funzionalità dovrebbe sempre essere valutata in relazione al proprio stato prima della comparsa dei sintomi iniziali della SLA. Le persone colpite possono discostarsi da questa raccomandazione se riscontrano ulteriori limitazioni
         probabilmente dovute alla SLA. Una volta scelto un approccio, sii coerente nel seguirlo quando rispondi a tutte le domande su questa e sulle future valutazioni ALSFRS-R-SE."
}`
  },
  speech: {
    no: "1.",
    title: "Discorso",
    qid: "discorso",
    options: [
      {
        title: "Normale",
        description: "In bocca non si accumula saliva in eccesso.",
        value: 4,
        letter: "a"
      },
      {
        title: "Disturbi del linguaggio rilevabili",
        description: "La parola, l'articolazione o la fonazione sono cambiate, come percepite sia dalle persone colpite che dai loro contatti immediati. ",
        value: 3,
        letter: "b"
      },
      {
        title: "Discorso comprensibile con ripetizione",
        description: "Per trasmettere il significato è necessaria la ripetizione frequente di singole parole o parti di una frase.",
        value: 2,
        letter: "c"
      },
      {
        title: "Discorso combinato con comunicazione non vocale",
        description: "Per trasmettere il significato sono necessari la scrittura delle cose, l'uso di ausili comunicativi e metodi simili.",
        value: 1,
        letter: "d"
      },
      {
        title: "Perdita del linguaggio utile",
        description: "È sempre richiesto l'uso di ausili comunicativi o metodi simili.",
        value: 0,
        letter: "e"
      }
    ]
  },
  salvation: {
    no: "2.",
    title: "Salivazione",
    qid: "salivazione",
    options: [
      {
        title: "Normale",
        description: "In bocca non si accumula saliva in eccesso.",
        value: 4,
        letter: "a"
      },
      {
        title: "Lieve ma deciso eccesso di saliva in bocca; ",
        description: "Aumento dell'accumulo di saliva in bocca; ",
        value: 3,
        letter: "b"
      },
      {
        title: "Saliva moderatamente eccessiva; ",
        description: "Durante il giorno, occasionalmente, viene utilizzato un fazzoletto per tamponare i bordi della bocca.",
        value: 2,
        letter: "c"
      },
      {
        title: "Marcato eccesso di saliva con qualche sbavatura",
        description: "Perdita regolare di saliva, un fazzoletto viene utilizzato spesso ma non costantemente. ",
        value: 1,
        letter: "d"
      },
      {
        title: "Sbavatura marcata",
        description: "È richiesto l'uso permanente di fazzoletti o di un dispositivo di aspirazione.",
        value: 0,
        letter: "e"
      }
    ]
  },
  swallowing: {
    no: "3.",
    title: "Deglutizione",
    qid: "deglutizione",
    options: [
      {
        title: "Normale",
        description: "Ingoiare qualsiasi tipo di cibo o liquido non è problematico.",
        value: 4,
        letter: "a"
      },
      {
        title: "Piccoli problemi di deglutizione - soffocamento occasionale",
        description: "L'assunzione di cibo richiede più tempo; ",
        value: 3,
        letter: "b"
      },
      {
        title: "Cambiamento di consistenza alimentare",
        description: "La difficoltà a deglutire (disfagia) e i problemi con determinate consistenze di cibi e bevande portano ad evitare alcuni tipi di consistenze alimentari (ad es. Carne, biscotti secchi, noci). ",
        value: 2,
        letter: "c"
      },
      {
        title: "Alimentazione tramite sonda supplementare",
        description: "A causa della disfagia, l'assunzione di cibo è diventata così difficile che è necessario inserire una sonda per alimentazione enterale (PEG) o che è altamente raccomandata dal medico per integrare l'apporto calorico e/o prevenire il soffocamento con il cibo.",
        value: 1,
        letter: "d"
      },
      {
        title: "Alimentazione esclusivamente enterale",
        description: "L'assunzione di cibo e liquidi avviene esclusivamente tramite una sonda di alimentazione; ",
        value: 0,
        letter: "e"
      }
    ]
  },
  handwriting: {
    no: "4.",
    title: "Scrittura a mano",
    "desc": "Oggetto di questa valutazione è scrivere con la mano dominante (mano che scrive) nella postura abituale.",
    qid: "grafia",
    options: [
      {
        title: "Normale",
        description: "Scrivere con la mano dominante non causa problemi",
        value: 4,
        letter: "a"
      },
      {
        title: "Lento o sciatto ma tutte le parole sono leggibili",
        description: "Scrivere è più difficile o, in alternativa, l'aspetto del testo scritto da una persona è cambiato anche se le parole rimangono leggibili.",
        value: 3,
        letter: "b"
      },
      {
        title: "Non tutte le parole sono leggibili",
        description: "Alcune parole scritte sono illeggibili. ",
        value: 2,
        letter: "c"
      },
      {
        title: "Nessuna parola è leggibile, ma può ancora impugnare la penna",
        description: "È possibile tenere una penna; ",
        value: 1,
        letter: "d"
      },
      {
        title: "Impossibile afferrare la penna",
        description: "Tenere una penna è impossibile.",
        value: 0,
        letter: "e"
      }
    ]
  },
  useFeedingTube: {
    no: "5.",
    title: "Usi regolarmente una sonda per l'alimentazione?",
    qid: "utilizzare il tubo di alimentazione",
    options: [
      {
        title: "Si",
        value: "Yes"
      },
      {
        title: "No",
        value: "No"
      }
    ]
  },
  useOfCutlery: {
    no: "5a.",
    title: "(No) Uso delle posate.",
    desc: "Riguardante le persone che non utilizzano regolarmente una sonda per alimentazione enterale per l'apporto calorico.",
    qid: "uso delle posate",
    options: [
      {
        title: "Normale",
        description: "L'uso delle posate non è problematico. ",
        value: 4,
        letter: "a"
      },
      {
        title: "Un po' lento e goffo, ma non è necessario alcun aiuto",
        description: "Mangiare richiede più tempo a causa della menomazione delle mani. ",
        value: 3,
        letter: "b"
      },
      {
        title: "Può tagliare la maggior parte degli alimenti, anche se lentamente e in modo goffo; ",
        description: "Talvolta è necessario un aiuto quando si tagliano alcuni tipi di alimenti; ",
        value: 2,
        letter: "c"
      },
      {
        title: "Il cibo deve essere tagliato da qualcun altro, ma può comunque nutrirsi lentamente",
        description: "L'aiuto è necessario per tagliare gli alimenti solidi e nella maggior parte delle altre occasioni. ",
        value: 1,
        letter: "d"
      },
      {
        title: "Dipendenza totale",
        description: "La persona colpita non è in grado di usare da sola le posate (ad esempio, una forchetta o un cucchiaio) e può mangiare solo quando è nutrita.",
        value: 0,
        letter: "e"
      }
    ]
  },
  useOfFeedingTube: {
    no: "5b.",
    title: "(Sì) Maneggiare il tubo di alimentazione e gli utensili.",
    desc: "Per le persone che necessitano regolarmente di una sonda per l'apporto calorico. ",
    qid: "useOfFeedingTube",
    options: [
      {
        title: "Normale",
        description: "Il tubo può essere movimentato autonomamente e le serrature e i pacchetti possono essere aperti e chiusi senza assistenza.",
        value: 4,
        letter: "a"
      },
      {
        title: "Goffo ma in grado di eseguire tutte le manipolazioni in modo indipendente",
        description: "Non è necessaria alcuna assistenza per maneggiare il tubo di alimentazione, tuttavia l'utilizzo risulta alquanto difficoltoso.",
        value: 3,
        letter: "b"
      },
      {
        title: "È necessario aiuto con chiusure e dispositivi di fissaggio",
        description: "La gestione del tubo di alimentazione avviene più o meno in modo indipendente. ",
        value: 2,
        letter: "c"
      },
      {
        title: "Fornisce un'assistenza minima al caregiver",
        description: "Un'altra persona si occupa principalmente del tubo di alimentazione. ",
        value: 1,
        letter: "d"
      },
      {
        title: "Dipendenza totale",
        description: "La gestione del tubo di alimentazione viene effettuata interamente da un'altra persona. ",
        value: 0,
        letter: "e"
      }
    ]
  },
  dressingAndHygiene: {
    no: "6.",
    title: "Vestizione e igiene",
    qid: "vestizione e igiene",
    options: [
      {
        title: "Normale",
        description: "Vestirsi e prendersi cura dell'igiene personale non sono problematici.",
        value: 4,
        letter: "a"
      },
      {
        title: "La cura di sé indipendente e completa richiede sforzo ed è meno efficiente",
        description: "Vestirsi e prendersi cura dell'igiene personale vengono eseguiti più lentamente di prima ma vengono eseguiti in modo autonomo e non richiedono né aiuti né assistenza da parte di un'altra persona.",
        value: 3,
        letter: "b"
      },
      {
        title: "Assistenza intermittente o metodi sostitutivi",
        description: "A volte, un'altra persona viene chiamata ad assistere, oppure vengono sviluppate strategie per contrastare l'invalidità (ad esempio indossare abiti facili da indossare o togliere, vestirsi (spogliarsi) o fare la doccia stando seduti, uso di ausili).",
        value: 2,
        letter: "c"
      },
      {
        title: "Ha bisogno di un assistente per la cura di sé",
        description: "Un’altra persona è tenuta regolarmente a (s)vestirsi e ad occuparsi dell’igiene personale della persona colpita.",
        value: 1,
        letter: "d"
      },
      {
        title: "Dipendenza totale",
        description: "La vestizione, la svestizione e l'igiene personale devono essere interamente eseguite da un'altra persona.",
        value: 0,
        letter: "e"
      }
    ]
  },
  turningInBedAndAdjustingTheBedClothes: {
    no: "7.",
    title: "Girarsi nel letto e sistemare le coperte.",
    qid: "girarsi nel letto",
    options: [
      {
        title: "Normale",
        description: "Girarsi nel letto e maneggiare le coperte non causa problemi.",
        value: 4,
        letter: "a"
      },
      {
        title: "Un po' lento e goffo, ma non è necessario alcun aiuto",
        description: "Girarsi nel letto o maneggiare le coperte è difficile",
        value: 3,
        letter: "b"
      },
      {
        title: "Possono girarsi da soli e aggiustare le lenzuola, ma con grande difficoltà",
        description: "Girarsi nel letto e maneggiare le coperte è possibile ma richiede un grande sforzo.  ",
        value: 2,
        letter: "c"
      },
      {
        title: "Può iniziare l'azione, ma non girare o aggiustare le lenzuola senza assistenza",
        description: "È possibile avviare le azioni di girarsi nel letto e maneggiare le coperte, tuttavia è necessaria l'assistenza di un'altra persona per completare queste azioni.",
        value: 1,
        letter: "d"
      },
      {
        title: "Dipendenza totale",
        description: "È costantemente necessaria assistenza quando si gira nel letto o si maneggiano le coperte.",
        value: 0,
        letter: "e"
      }
    ]
  },
  walking: {
    no: "8.",
    title: "Camminare",
    qid: "a piedi",
    options: [
      {
        title: "Normale",
        description: "Nessun cambiamento nella capacità di camminare",
        value: 4,
        letter: "a"
      },
      {
        title: "Piccole difficoltà di deambulazione",
        description: "Cambiamenti, come camminare più lentamente, inciampare o perdita di stabilità, sono evidenti, sebbene la persona colpita non necessiti regolarmente di assistenza esterna, né sotto forma di un'altra persona, né di un ausilio per la deambulazione (ad es.  ",
        value: 3,
        letter: "b"
      },
      {
        title: "Cammina con assistenza",
        description: "La persona colpita necessita regolarmente di assistenza quando cammina, sotto forma di aggrapparsi a qualcosa o, fuori casa, dell'uso di un sollevatore per piedi, di ausili per la deambulazione o dell'aiuto di un'altra persona",
        value: 2,
        letter: "c"
      },
      {
        title: "Movimento funzionale non deambulante",
        description: "Sono ancora possibili movimenti mirati delle gambe. ",
        value: 1,
        letter: "d"
      },
      {
        title: "Nessun movimento intenzionale delle gambe",
        description: "Le gambe non possono sostenere il peso del corpo (ad esempio per il trasferimento), non possono essere eseguiti movimenti mirati, come aiutare nelle attività di cura.",
        value: 0,
        letter: "e"
      }
    ]
  },
  climbingTheStairs: {
    no: "9.",
    title: "Salire le scale",
    qid: "salire le scale",
    options: [
      {
        title: "Normale",
        description: "Non si osserva alcun cambiamento quando si salgono le scale.",
        value: 4,
        letter: "a"
      },
      {
        title: "Lento",
        description: "Salire le scale senza fare una pausa o sentirsi instabili è possibile se fatto lentamente.",
        value: 3,
        letter: "b"
      },
      {
        title: "Lieve instabilità o affaticamento",
        description: "Salire le scale è accompagnato da una sensazione di instabilità e potrebbero essere necessarie delle pause. ",
        value: 2,
        letter: "c"
      },
      {
        title: "Ha bisogno di assistenza",
        description: "Non è possibile salire le scale senza l'uso del corrimano o senza l'aiuto di un'altra persona.",
        value: 1,
        letter: "d"
      },
      {
        title: "Non poter fare",
        description: "Non è possibile salire le scale, nemmeno con assistenza o supporto.",
        value: 0,
        letter: "e"
      }
    ]
  },
  dyspneaAndshortnessOfBreath: {
    no: "10.",
    title: "Dispnea e mancanza di respiro.",
    qid: "dispnea e mancanza di respiro",
    options: [
      {
        title: "Nessuno",
        description: "Nessuna dispnea o mancanza di respiro durante l'esecuzione delle routine quotidiane a intensità normale",
        value: 4,
        letter: "a"
      },
      {
        title: "Si verifica quando si cammina",
        description: "Dispnea o mancanza di respiro possono verificarsi quando si cammina a un ritmo normale o si svolgono attività a intensità moderata.",
        value: 3,
        letter: "b"
      },
      {
        title: "Si verifica con uno o più dei seguenti elementi: mangiare, lavarsi, vestirsi (ADL)",
        description: "Dispnea o mancanza di respiro possono verificarsi quando si svolgono attività a bassa intensità o quando si parla per periodi di tempo più lunghi.",
        value: 2,
        letter: "c"
      },
      {
        title: "Difficoltà a respirare a riposo, anche quando si è seduti o sdraiati",
        description: "Dispnea o mancanza di respiro in assenza di sforzo fisico sia da seduti che da sdraiati.",
        value: 1,
        letter: "d"
      },
      {
        title: "Difficoltà respiratoria significativa, può essere necessario un supporto respiratorio meccanico",
        description: "A riposo è presente una significativa dispnea o mancanza di respiro; ",
        value: 0,
        letter: "e"
      }
    ]
  },
  sleepDisturbanceBreathingProblems: {
    no: "11.",
    title: "Disturbi del sonno dovuti a problemi respiratori.",
    "desc": "Se la ventilazione meccanica viene solitamente fornita durante la notte, ma è possibile dormire senza di essa, la respirazione notturna deve essere valutata senza l'uso della ventilazione.",
    qid: "disturbi del sonno",
    options: [
      {
        title: "Nessuno",
        description: "L'addormentarsi e il dormire tutta la notte non sono compromessi dalla dispnea o dalla mancanza di respiro.",
        value: 4,
        letter: "a"
      },
      {
        title: "Qualche difficoltà nel dormire la notte a causa della mancanza di respiro, non vengono utilizzati abitualmente più di due cuscini.",
        description: "Dispnea e mancanza di respiro sono presenti durante la notte e quando si è sdraiati. ",
        value: 3,
        letter: "b"
      },
      {
        title: "Per dormire sono necessari più di due cuscini",
        description: "Quando si è distesi sulla schiena, la respirazione è notevolmente fastidiosa, il che a sua volta disturba il processo di addormentamento e il sonno notturno. ",
        value: 2,
        letter: "c"
      },
      {
        title: "Riesco a dormire solo seduto",
        description: "Per dormire è necessario assumere la posizione seduta, a letto o su una sedia.",
        value: 1,
        letter: "d"
      },
      {
        title: "Incapace di dormire",
        description: "A causa della dispnea o della mancanza di respiro, il sonno è impossibile senza la ventilazione con maschera (ventilazione non invasiva) o la ventilazione tramite tracheostomia.  ",
        value: 0,
        letter: "e"
      }
    ]
  },
  mechanicalVentilation: {
    no: "12.",
    title: "Ventilazione meccanica",
    qid: "ventilazione meccanica",
    options: [
      {
        title: "Nessuno",
        description: "La respirazione è sempre un'azione autonoma, che non richiede l'uso della ventilazione meccanica. ",
        value: 4,
        letter: "a"
      },
      {
        title: "Uso intermittente della ventilazione non invasiva",
        description: "La ventilazione con maschera (ventilazione non invasiva, ad esempio BiPAP) viene utilizzata a intervalli irregolari o per un periodo di tempo più breve rispetto al normale ciclo di sonno notturno.",
        value: 3,
        letter: "b"
      },
      {
        title: "Uso continuo della ventilazione non invasiva durante la notte",
        description: "La ventilazione con maschera (ventilazione non invasiva) viene utilizzata regolarmente durante la notte e possibilmente su base oraria durante il giorno (per un totale di 8-22 ore in qualsiasi ciclo di 24 ore).",
        value: 2,
        letter: "c"
      },
      {
        title: "Uso continuo della ventilazione non invasiva durante il giorno e la notte",
        description: "La ventilazione con maschera (ventilazione non invasiva) è in uso quasi sempre (più di 22 ore in qualsiasi ciclo di 24 ore).",
        value: 1,
        letter: "d"
      },
      {
        title: "Ventilazione meccanica invasiva mediante intubazione o tracheostomia",
        description: "Ventilazione meccanica continua tramite un tubo di ventilazione (intubazione) o tracheostomia.",
        value: 0,
        letter: "e"
      }
    ]
  }
}
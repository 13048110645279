import { NavLink, useNavigate } from "react-router-dom";
import ErrorBoundary from "../../shared/components/error/ErrorBoundary";
import MODAL_NAMES from "../dialogs/ModalName";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import showModalFromId, { hideNavbar, showOffcanvasFromId } from '../../shared/components/functions/ModalShow';
import icons from '../../shared/components/utils/icons';
import { useAppContext } from '../../shared/context/Context';
import { TFunction } from "i18next";
import keycloak from "../../keycloakConfig";
import AppStore from "../../stores/AppStore";

interface IDraweContentProps {
  msg: TFunction<[string]>
  accountUrl: string
  onLogout(): void
}

const DrawerContent = observer((props: IDraweContentProps) => {

  const { msg, accountUrl, onLogout } = props
  const navigate = useNavigate();

  function onRegistrationPrivacy() {
    showModalFromId(MODAL_NAMES.PATIENT.ACCOUNT_AND_PRIVACY_MODAL);
  }

  function onButtonClick(url: string) {
    navigate(url);
    hideNavbar();
  }

  const viewContact = async () => {
    showOffcanvasFromId(MODAL_NAMES.PATIENT.CONTACTS_MODAL);
  }

  return (
    <div className="drawer-content">
      <div className="drawer-list">
        <div className="main-list">
          <ul className="uk-nav-default" data-uk-nav>
            <li className="list-item" >
              <NavLink onClick={hideNavbar} to={"dashboard"} className="navlink">
                <img alt="icon" src={icons.dashboard} className="icon uk-margin-small-right" />
                {msg('dashboard')}
              </NavLink>
            </li>
            <li className="list-item uk-parent">
              <NavLink to={"info"} className="navlink">
                <img alt="icon" src={icons.local_library} className="icon uk-margin-small-right" />
                {msg('informationLibrary')}
                <span className="down-arrow" data-uk-icon="triangle-down"></span>
              </NavLink>
              <ul className="uk-nav-sub">
                <li>
                  <NavLink onClick={hideNavbar} to={"info/how-to-order"} className="navlink">
                    {msg('howToOrder')}
                    <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={hideNavbar} to={"info/sending-kit"} className="navlink">
                    {msg('sendingKits')}
                    <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={hideNavbar} to={"info/blood-sample"} className="navlink">
                    {msg('extractionOfBlood')}
                    <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={hideNavbar} to={"info/about-lab"} className="navlink">
                    {msg('aboutLabAnalysis')}
                    <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={hideNavbar} to={"info/about-all"} className="navlink">
                    {msg('aboutALSNFLALSFRS')}
                    <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={hideNavbar} to={"info/cancel-kit"} className="navlink">
                    {msg('howToCancelPurchase')}
                    <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={hideNavbar} to={"info/cancel-kit"} className="navlink">
                    {msg('howToCancelPurchase')}
                    <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="list-item">
              <NavLink onClick={hideNavbar} to={"order"} className="navlink">
                <img alt="icon" src={icons.shopping_basket} className="icon uk-margin-small-right" />
                {msg('orderNFL')}
              </NavLink>
            </li>
            <li className="list-item" >
              <NavLink onClick={hideNavbar} to={"lab"} className="navlink">
                <img alt="icon" src={icons.statistics} className="icon uk-margin-small-right" />
                {msg('labResults')}
              </NavLink>
            </li>
            <li className="list-item" >
              <NavLink onClick={hideNavbar} to={"survey"} className="navlink">
                <img alt="icon" src={icons.pen} className="icon uk-margin-small-right" />
                {msg('alsfrs')}
              </NavLink>
            </li>
            <li className="list-item" >
              <NavLink onClick={hideNavbar} to={"profile"} className="navlink">
                <img alt="icon" src={icons.useraccount} className="icon uk-margin-small-right" />
                {msg('userProfile')}
              </NavLink>
            </li>

            {/* contact */}
            <li className="list-item">
              <span onClick={viewContact} className="navlink">
                <img alt="icon" src={icons.useraccount} className="icon uk-margin-small-right" />
                {msg('contactInformation')}
              </span>
            </li>
            {/* contact */}

            {keycloak.authenticated &&
              <li className="list-item uk-parent uk-hidden@s">
                <NavLink to={"account"} className="navlink">
                  <img alt="icon" src={icons.security} className="icon uk-margin-small-right" />
                  {msg('account')}
                  <span className="down-arrow" data-uk-icon="triangle-down"></span>
                </NavLink>
                <ul className="uk-nav-sub">
                  <li>
                    <NavLink onClick={hideNavbar} target="_blank" to={accountUrl} className="navlink">
                      {msg('accountSecurity')}
                      <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                    </NavLink>
                  </li>
                  <li>
                    <div onClick={onRegistrationPrivacy} className="navlink">
                      {msg('accountAndDataPrivacy')}
                      <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                    </div>
                  </li>
                  <li className="list-item">
                    <div onClick={onLogout} className="navlink">
                      {msg('signOutButton')}
                      <span className="sub-icon" data-uk-icon="chevron-double-right"></span>
                    </div>
                  </li>
                </ul>
              </li>
            }
          </ul>
        </div>
        <div className="nav-footer">
          <ul className=" uk-nav-default" data-uk-nav>
            <li className="list-item">
              <div className="footer-link" onClick={() => onButtonClick("/data-privacy-policy")}>
                {msg('privacyPolicy')}
              </div>
            </li>
            <li className="list-item">
              <div className="footer-link" onClick={() => onButtonClick("/general-terms-and-conditions")}>
                {msg('gtcLink')}
              </div>
            </li>
            <li className="list-item">
              <div className="footer-link" onClick={() => onButtonClick("/terms-of-use")}>
                {msg('termsOfUse')}
              </div>
            </li>
            <li className="list-item">
              <div className="footer-link" onClick={() => onButtonClick("/revocation")}>
                {msg('revocation')}
              </div>
            </li>
            <p className="uk-margin-left uk-text-primary">&copy; APST Research {new Date().getFullYear()}</p>
          </ul>
        </div>
      </div>
    </div>
  );
});

interface IOverlayDrawerProps {
  msg: TFunction<[string]>
  accountUrl: string;
  store: AppStore;
  onLogout(): void
}

const OverlayDrawer = observer((props: IOverlayDrawerProps) => {

  const { store, accountUrl, msg, onLogout } = props;
  const user = store.patient.patientJson;
  const firstName = user ? user.first_name : " ";
  const lastName = user ? user.last_name : " ";


  return (
    <div id="apstnavbardrawer" data-uk-offcanvas="overlay: true">
      <div className="uk-offcanvas-bar">
        <button
          className="uk-offcanvas-close"
          type="button"
          data-uk-close
        ></button>
        <p className="avartar-username">
          <span className="name">{firstName}{" "}{lastName}</span>
        </p>
        <DrawerContent msg={msg} accountUrl={accountUrl} onLogout={onLogout} />
      </div>
    </div>
  );
});

const FixedDrawer = (props: IOverlayDrawerProps) => {

  const { accountUrl, msg, onLogout } = props;

  return (
    <div className="drawer-layout uk-visible@s uk-visible@m">
      <DrawerContent msg={msg} accountUrl={accountUrl} onLogout={onLogout} />
    </div>
  );
};

interface IProps {
  onLogout(): void
  accountUrl: string;
}

const Drawer = (props: IProps) => {

  const { onLogout, accountUrl } = props;
  const { store } = useAppContext();
  const { t: msg } = useTranslation(['home']);

  return (
    <ErrorBoundary>
      <OverlayDrawer
        msg={msg}
        accountUrl={accountUrl}
        store={store}
        onLogout={onLogout} />
      <FixedDrawer
        msg={msg}
        accountUrl={accountUrl}
        store={store}
        onLogout={onLogout} />
    </ErrorBoundary>
  );
};

export default Drawer;

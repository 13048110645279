export const germanSurvey = {
  page: {
    title: "Was ist die ALSFRS-Selbstbewertung?",
    description: "Die ALSFRS (Amyotrophic Lateral Sclerosis Functional Rating Scale) ist ein Fragebogen zur Selbstbewertung. Sie können damit den aktuellen Stand der ALS einschätzen und festhalten. Das ist wichtig, um die Entwicklung Ihrer Erkrankung über die Zeit zu beobachten."
  },
  speech: {
    no: "1.",
    title: "Sprechen",
    qid: "speech",
    options: [
      {
        title: "Ungestört",
        description: "Das Sprechen bzw. die Artikulation ist genauso wie vor dem Auftreten der ersten ALS-Symptome.",
        value: 4,
        letter: "a",
      },
      {
        title: "Wahrnehmbare Sprechstörung",
        description: "Eine neu aufgetretene Veränderung beim Sprechen, der Artikulation oder der Stimmgebung (Phonation), z. B. im Sinne verwaschenen Sprechens oder einer Heiserkeit, wird entweder selbst oder vom näheren Umfeld bemerkt.",
        value: 3,
        letter: "b",
      },
      {
        title: "Wiederholung zur Verständigung notwendig",
        description: "Es bedarf öfters einer Wiederholung einzelner Worte oder Satzteile, um sich verständlich zu machen.",
        value: 2,
        letter: "c",
      },
      {
        title: "Das Sprechen wird mit nonverbaler Kommunikation kombiniert",
        description: "Schreiben, Verwendung von Kommunikationshilfen oder ähnliche Methoden werden eingesetzt, um das Sprechen zu ergänzen.",
        value: 1,
        letter: "d",
      },
      {
        title: "Verlust des verständlichen Sprechens",
        description: "Der Einsatz von Kommunikationshilfen oder ähnlichen Methoden ist durchgehend notwendig.",
        value: 0,
        letter: "e",
      },
    ],
  },
  salvation: {
    no: "2.",
    title: "Speichelfluss",
    qid: "salivation",
    options: [
      {
        title: "Ungestört",
        description: "Es sammelt sich kein vermehrter Speichel im Mund.",
        value: 4,
        letter: "a",
      },
      {
        title: "Geringfügig, aber eindeutiges Übermaß an Speichel im Mund; nachts Speichelverlust möglich",
        description: "Vermehrte Speichelansammlung im Mund, jedoch ohne subjektive Beeinträchtigung und ohne Speichelverlust am Tage.",
        value: 3,
        letter: "b",
      },
      {
        title: "Mäßig vermehrter Speichelfluss; geringer Speichelverlust möglich",
        description: "Gelegentlich wird ein Taschentuch verwendet, um tagsüber die Mundwinkel abzutrocknen.",
        value: 2,
        letter: "c",
      },
      {
        title: "Deutlich vermehrter Speichelfluss mit etwas Speichelverlust",
        description: "Speichelverlust ist regelmäßig vorhanden, und ein Taschentuch wird oft, aber nicht immer, verwendet.",
        value: 1,
        letter: "d",
      },
      {
        title: "Ausgeprägter Speichelverlust aus dem Mund",
        description: "Die ständige Verwendung eines Taschentuchs oder einer Absaugung ist erforderlich.",
        value: 0,
        letter: "e",
      },
    ],
  },
  swallowing: {
    no: "3.",
    title: "Schlucken",
    qid: "swallowing",
    options: [
      {
        title: "Ungestört",
        description: "Das Schlucken jeglicher Nahrung und von Flüssigkeiten erfolgt ohne Probleme.",
        value: 4,
        letter: "a",
      },
      {
        title: "Beginnende Essprobleme mit gelegentlichem Verschlucken",
        description: "Es wird mehr Zeit zur Nahrungsaufnahme benötigt, oder es werden kleinere Bissen zu sich genommen und mit Vorsicht geschluckt. Es kommt gelegentlich zum Verschlucken oder zu vermehrtem Husten.",
        value: 3,
        letter: "b",
      },
      {
        title: "Änderung der Nahrungskonsistenz erforderlich",
        description: "Die Beschaffenheit von Speisen und Getränken wird verändert oder Schwierigkeiten beim Schlucken führen zur Vermeidung bestimmter Konsistenzen (z. B. Fleisch, trockene Kekse, Nüsse). Ggf. werden wegen einer Schluckstörung ergänzend Zusatznahrung oder Andickungsmittel verwendet.",
        value: 2,
        letter: "c",
      },
      {
        title: "Ergänzende Sondenernährung erforderlich",
        description: "Die Nahrungsaufnahme ist aufgrund der Schluckstörung so schwierig, dass eine Ernährungssonde (PEG) bereits angelegt oder aus ärztlicher Sicht dringend empfohlen wurde, um die Kalorienzufuhr zu ergänzen bzw. die Gefahr des Verschluckens zu vermindern.",
        value: 1,
        letter: "d",
      },
      {
        title: "Ernährung ausschließlich über eine Ernährungssonde",
        description: "Die Aufnahme von Nahrung und Flüssigkeit erfolgt ausschließlich über eine Ernährungssonde. Die orale Nahrungsaufnahme ist aufgrund einer hochgradigen Schluckstörung unmöglich.",
        value: 0,
        letter: "e",
      },
    ],
  },
  handwriting: {
    no: "4.",
    title: "Handschrift",
    desc: "Gegenstand dieser Beurteilung ist das Schreiben mit der dominanten Hand (Schreibhand) in gewohnter Haltung.",
    qid: "handwriting",
    options: [
      {
        title: "Ungestört",
        description: "Das Schreiben mit der üblich verwendeten Schreibhand ist ohne Probleme möglich.",
        value: 4,
        letter: "a",
      },
      {
        title: "Langsamer oder unsauber, alle Wörter sind lesbar",
        description: "Das Schreiben gestaltet sich schwierig oder die Schrift hat sich verändert, selbst wenn die geschriebenen Wörter gut lesbar sind.",
        value: 3,
        letter: "b",
      },
      {
        title: "Nicht alle Wörter sind lesbar",
        description: "Im geschriebenen Text sind einzelne Wörter nicht lesbar. Um lesbar zu schreiben, werden Hilfsmittel wie Schreibhilfen verwendet.",
        value: 2,
        letter: "c",
      },
      {
        title: "Das Halten des Stiftes ist möglich, aber nicht das lesbare Schreiben",
        description: "Der Stift kann gehalten werden. Das Schreiben über den eigenen Namen oder die eigene Unterschrift hinaus ist jedoch nicht möglich.",
        value: 1,
        letter: "d",
      },
      {
        title: "Kann Stift nicht halten",
        description: "Das Halten eines Stiftes, z. B. zum Unterschreiben, ist nicht möglich.",
        value: 0,
        letter: "e",
      },
    ],
  },
  useFeedingTube: {
    no: "5.",
    title: "Einsatz einer Ernährungssonde",
    qid: "usefeedingtube",
    options: [
      {
        title: "Ja", //handleFeedingTubeAndUtensils
        value: "Yes",
      },
      {
        title: "Nein", //useOfCutlery
        value: "No",
      },
    ],
  },
  useOfCutlery: {
    no: "5a.",
    title: "(Nein) Essen schneiden und Gebrauch von Besteck.",
    desc: "Für Personen, die nicht regelhaft eine Ernährungssonde zur Kalorienzufuhr nutzen.",
    qid: "useofcutlery",
    options: [
      {
        title: "Ungestört",
        description: "Die Benutzung von Besteck ist ohne Probleme möglich (als Probleme gelten z. B. Messer und Gabel statt Essstäbchen oder die Neigung, vermehrt einen Löffel zu benutzen).",
        value: 4,
        letter: "a",
      },
      {
        title: "Etwas langsam und unbeholfen, aber keine Hilfe erforderlich",
        description: "Aufgrund der Beeinträchtigung der Hände wird mehr Zeit zum Essen benötigt. Die Benutzung von Besteck ist verändert, jedoch selbständig möglich.",
        value: 3,
        letter: "b",
      },
      {
        title: "Kann die meisten Speisen schneiden, aber langsam und unbeholfen, braucht teilweise Hilfe",
        description: "Beim Schneiden bestimmter Speisen wird gelegentlich die Hilfe von einer anderen Person benötigt, oder es werden Hilfsmittel, z. B. in Form von speziellem Besteck, verwendet.",
        value: 2,
        letter: "c",
      },
      {
        title: "Essen muss geschnitten werden, kann langsam alleine essen",
        description: "Für das Schneiden von festen Lebensmitteln ist meistens oder immer eine Unterstützung erforderlich. Selbständiges Essen ist aber möglich (z. B. mit Gabel oder Löffel).",
        value: 1,
        letter: "d",
      },
      {
        title: "Essen muss gereicht werden",
        description: "Nahrung wird immer angereicht. Die selbständige Benutzung von Besteck (z. B. Gabel oder Löffel) ist nicht möglich.",
        value: 0,
        letter: "e",
      },
    ],
  },
  useOfFeedingTube: {
    no: "5b.",
    title: "(Ja) Ernährungssonde und Zubehör handhaben",
    desc: "Für Personen, die regelhaft eine Ernährungssonde zur Kalorienzufuhr nutzen. Bewertet werden soll die vorhandene manuelle Fähigkeit.",
    qid: "useOfFeedingTube",
    options: [
      {
        title: "Ungestört",
        description: "Die Sonde kann problemlos selbst versorgt und Verschlüsse und Packungen können selbständig geöffnet oder geschlossen werden.",
        value: 4,
        letter: "a",
      },
      {
        title: "Etwas langsam und unbeholfen, aber alle Handgriffe können selbständig ausgeführt werden",
        description: "Es wird keine Unterstützung bei der Handhabung der Ernährungssonde benötigt, allerdings ist der Umgang erschwert.",
        value: 3,
        letter: "b",
      },
      {
        title: "Beim Umgang mit Verschlüssen und Deckeln teilweise Hilfe erforderlich",
        description: "Der Umgang mit der Sonde ist überwiegend selbständig möglich. Hilfe wird jedoch z. B. beim Öffnen von Verschlüssen benötigt.",
        value: 2,
        letter: "c",
      },
      {
        title: "Minimale Unterstützung kann bei der Sondenversorgung gegeben werden",
        description: "Die Versorgung der Ernährungssonde muss überwiegend von einer anderen Person übernommen werden. Minimale Handgriffe können selbständig getätigt werden.",
        value: 1,
        letter: "d",
      },
      {
        title: "Kann an keiner Stelle bei der Sondenversorgung mithelfen",
        description: "Die Versorgung der Ernährungssonde muss vollständig von einer anderen Person übernommen werden. Es können keine Handgriffe selbst getätigt werden.",
        value: 0,
        letter: "e",
      },
    ],
  },
  dressingAndHygiene: {
    no: "6.",
    title: "Ankleiden und Körperpflege",
    qid: "dressingandhygiene",
    options: [
      {
        title: "Ungestört",
        description: "Das Ankleiden und die Körperpflege sind ohne Probleme möglich.",
        value: 4,
        letter: "a",
      },
      {
        title: "Keine Hilfe erforderlich, jedoch mit Mühe verbunden",
        description: "Das Ankleiden oder die Körperpflege sind langsamer als zuvor, bleiben jedoch unabhängig und ohne Unterstützung durch Hilfsverfahren oder durch eine andere Person möglich.",
        value: 3,
        letter: "b",
      },
      {
        title: "Zeitweilig sind Hilfe oder Hilfsverfahren erforderlich",
        description: "Es wird gelegentlich Hilfe durch eine Person in Anspruch genommen, oder es werden Strategien entwickelt, um Beeinträchtigungen auszugleichen (z. B. leicht an- und ausziehbare Kleidung, Sitzen beim Ankleiden oder Duschen, Nutzung von Hilfsmitteln).",
        value: 2,
        letter: "c",
      },
      {
        title: "Hilfe durch andere Personen erforderlich",
        description: "Beim Ankleiden oder der Körperpflege ist regelmäßig die Unterstützung durch eine andere Person notwendig.",
        value: 1,
        letter: "d",
      },
      {
        title: "Vollständige Abhängigkeit",
        description: "Das Ankleiden und die Körperpflege müssen vollständig von einer anderen Person übernommen werden.",
        value: 0,
        letter: "e",
      },
    ],
  },
  turningInBedAndAdjustingTheBedClothes: {
    no: "7.",
    title: "Umdrehen im Bett und Richten der Bettdecke",
    qid: "turninginbed",
    options: [
      {
        title: "Ungestört",
        description: "Das Umdrehen im Bett und Richten der Bettdecke lassen sich ohne Probleme durchführen.",
        value: 4,
        letter: "a",
      },
      {
        title: "Etwas langsam und unbeholfen, aber keine Hilfe erforderlich",
        description: "Es ist schwieriger, sich im Bett umzudrehen oder die Bettdecke zu richten.",
        value: 3,
        letter: "b",
      },
      {
        title: "Selbständig möglich, jedoch mit großer Mühe verbunden",
        description: "Das Umdrehen im Bett und das Richten der Bettdecke sind nur mit großer Mühe möglich. Eventuell wird eine der beiden Tätigkeiten unterstützt oder es wird zum Umdrehen ein Haltegriff verwendet.",
        value: 2,
        letter: "c",
      },
      {
        title: "Kann zum Umdrehen oder Richten der Bettdecke ansetzen, aber die Bewegung nicht selbständig vollenden",
        description: "Das Drehen im Bett oder Richten der Bettdecke kann begonnen werden. Es wird jedoch Unterstützung durch eine Person oder Hilfsmittel benötigt, um die jeweilige Tätigkeit zu vollenden.",
        value: 1,
        letter: "d",
      },
      {
        title: "Vollständige Abhängigkeit",
        description: "Beim Umdrehen im Bett und dem Richten der Bettdecke ist durchgehend Hilfe erforderlich.",
        value: 0,
        letter: "e",
      },
    ],
  },
  walking: {
    no: "8.",
    title: "Gehen",
    qid: "walking",
    options: [
      {
        title: "Ungestört",
        description: "Es besteht keine Veränderung der Gehfähigkeit.",
        value: 4,
        letter: "a",
      },
      {
        title: "Beginnende Gehschwierigkeiten",
        description: "Es zeigen sich Veränderungen, die eine Verlangsamung, Unsicherheit oder Stolpern einschließen. Hilfe in Form einer anderen Person, durch Festhalten an Gegenständen oder durch die Verwendung von Gehhilfen außerhalb des Wohnbereichs (z. B. Fußheberschiene, Gehstock, Rollator) wird jedoch nicht regelhaft benötigt.",
        value: 3,
        letter: "b",
      },
      {
        title: "Gehen nur mit Unterstützung möglich",
        description: "Das Gehen erfordert regelhaft ein Festhalten oder - außerhalb des Wohnbereichs - Hilfe durch Fußheberschienen, Gehhilfen oder durch eine andere Person.",
        value: 2,
        letter: "c",
      },
      {
        title: "Nicht gehfähig, aber gezielte Bewegungen der Beine möglich",
        description: "Die Beine können gezielt bewegt werden. Das Stehen mit Unterstützung, z. B. zum Transfer, kann möglich sein. Auch mit Unterstützung anderer Personen oder Gehhilfen besteht keine Gehfähigkeit.",
        value: 1,
        letter: "d",
      },
      {
        title: "Keine zielgerichtete Beinbewegung möglich",
        description: "Die Beine können das Körpergewicht nicht tragen (z. B. zum Transfer), und es können keine zweckdienlichen Beinbewegungen z. B. zur Erleichterung der Pflege ausgeführt werden.",
        value: 0,
        letter: "e",
      },
    ],
  },
  climbingTheStairs: {
    no: "9.",
    title: "Treppen hinaufsteigen",
    qid: "climbingthestairs",
    options: [
      {
        title: "Ungestört",
        description: "Es besteht keine Veränderung beim Hinaufsteigen einer Treppe.",
        value: 4,
        letter: "a",
      },
      {
        title: "Langsam",
        description: "Das Hinaufsteigen einer Treppe erfolgt verlangsamt, ist aber ohne Ruhepausen oder Unsicherheit möglich.",
        value: 3,
        letter: "b",
      },
      {
        title: "Leichte Unsicherheit oder Ermüdung",
        description: "Das Hinaufsteigen einer Treppe ist unsicherer. Eventuell sind Ruhepausen nötig. Hilfe, z. B. durch einen Handlauf oder eine andere Person, ist nicht zwingend erforderlich.",
        value: 2,
        letter: "c",
      },
      {
        title: "Unterstützung erforderlich",
        description: "Das Hinaufsteigen einer Treppe ist ohne die Verwendung eines Handlaufs oder die Hilfe einer Person nicht möglich.",
        value: 1,
        letter: "d",
      },
      {
        title: "Nicht möglich",
        description: "Treppen können auch mit Hilfe nicht hinaufgestiegen werden.",
        value: 0,
        letter: "e",
      },
    ],
  },
  dyspneaAndshortnessOfBreath: {
    no: "10.",
    title: "Luftnot und Kurzatmigkeit",
    qid: "dyspneaandshortness",
    options: [
      {
        title: "Ungestört",
        description: "Luftnot oder Kurzatmigkeit tritt bei üblicher Belastung im Alltag nicht auf.",
        value: 4,
        letter: "a",
      },
      {
        title: "Beim Gehen",
        description: "Luftnot oder Kurzatmigkeit tritt beim Gehen in Schrittgeschwindigkeit oder bei mäßiger Belastung auf.",
        value: 3,
        letter: "b",
      },
      {
        title: "Auftreten bei Aktivitäten des täglichen Lebens einschließlich Essen, Baden, Ankleiden",
        description: "Luftnot oder Kurzatmigkeit tritt bei geringer Belastung oder längerem Sprechen auf.",
        value: 2,
        letter: "c",
      },
      {
        title: "Auftreten in Ruhe, im Sitzen oder im Liegen",
        description: "Luftnot oder Kurzatmigkeit besteht ohne Belastung entweder im Sitzen oder im Liegen oder in beiden Positionen.",
        value: 1,
        letter: "d",
      },
      {
        title: "Erhebliche Luftnot oder Kurzatmigkeit in Ruhe. Eine mechanische Beatmung wird aufgrund der Luftnot oder einer Kurzatmigkeit genutzt oder empfohlen.",
        description: "Luftnot oder Kurzatmigkeit ist in Ruhe immer deutlich vorhanden und/oder eine Maskenbeatmung (nicht-invasive Beatmung) oder Beatmung über Luftröhrenschnitt (Tracheostoma) muss zur Linderung von Luftnot und Kurzatmigkeit eingesetzt werden.",
        value: 0,
        letter: "e",
      },
    ],
  },
  sleepDisturbanceBreathingProblems: {
    no: "11.",
    title: "Atmungsbedingte Schlafstörung",
    desc: "Wenn in der Regel nachts eine maschinelle Beatmung erfolgt, Schlaf aber auch ohne möglich ist, sollte die nächtliche Atmung ohne Beatmung beurteilt werden",
    qid: "sleepdisturbance",
    options: [
      {
        title: "Ungestört",
        description: "Das Ein- und Durchschlafen ist nicht durch Luftnot oder Kurzatmigkeit beeinträchtigt.",
        value: 4,
        letter: "a",
      },
      {
        title: "Wiederholte Schlafstörung durch Luftnot oder Kurzatmigkeit, keine Verwendung von mehr als zwei Kissen",
        description: "Es bestehen bereits beim Hinlegen oder während der Nacht Luftnot oder Kurzatmigkeit. Das Schlafen auf der Seite kann die Atmung verbessern. Der Oberkörper wird nicht durch mehr als zwei Kopfkissen oder mittels eines um mehr als 30 Grad hochgestellten Kopfteils aufgerichtet.",
        value: 3,
        letter: "b",
      },
      {
        title: "Regelmäßige Verwendung von mehr als zwei Kissen zum Schlafen erforderlich",
        description: "In flacher Rückenlage ist das Atmen merklich unangenehm und das Ein- oder Durchschlafen dadurch gestört. Der Oberkörper wird durch drei oder mehr Kissen oder mittels eines um mehr als 30 Grad hochgestellten Kopfteils aufgerichtet.",
        value: 2,
        letter: "c",
      },
      {
        title: "Schlafen nur im Sitzen möglich",
        description: "Zum Schlafen ist eine Sitzposition entweder im Bett oder in einem Stuhl notwendig.",
        value: 1,
        letter: "d",
      },
      {
        title: "Hochgradige Schlafstörung aufgrund von Luftnot oder Kurzatmigkeit",
        description: "Aufgrund von Luftnot oder Kurzatmigkeit ist das Schlafen ohne Maskenbeatmung (nicht-invasive Beatmung) oder Beatmung über Luftröhrenschnitt (Tracheostoma) nicht möglich. Eine mechanische Beatmung wird zur Beschwerdelinderung regelhaft genutzt.",
        value: 0,
        letter: "e",
      },
    ],
  },
  mechanicalVentilation: {
    no: "12.",
    title: "Mechanische Beatmung",
    qid: "mechanicalventilation",
    options: [
      {
        title: "Nicht erforderlich",
        description: "Die Atmung erfolgt immer selbständig ohne die Nutzung einer mechanischen Beatmung. Eine nächtliche Druckunterstützung (z. B. CPAP-Therapie zur Behandlung eines Schlafapnoe-Syndroms) zählt nicht als mechanische Beatmung.",
        value: 4,
        letter: "a",
      },
      {
        title: "Zeitweilige Nutzung einer nicht-invasiven Beatmung",
        description: "Eine Maskenbeatmung (nicht-invasive Beatmung, z. B. BiPAP) wird unregelmäßig oder für weniger als die Dauer des Nachtschlafs eingesetzt.",
        value: 3,
        letter: "b",
      },
      {
        title: "Ständige Nutzung einer nicht-invasiven Beatmung in der Nacht oder zusätzlich stundenweise am Tag",
        description: "Eine Maskenbeatmung (nicht-invasive Beatmung) wird regelmäßig während der Nacht oder zusätzlich stundenweise während des Tages eingesetzt (insgesamt 8 bis 22 Stunden täglich bezogen auf 24 Stunden).",
        value: 2,
        letter: "c",
      },
      {
        title: "Kontinuierliche Nutzung einer nicht-invasiven Beatmung (Tag und Nacht)",
        description: "Eine Maskenbeatmung (nicht-invasive Beatmung) wird nahezu die gesamt Zeit eingesetzt (mehr als 22 Stunden täglich bezogen auf 24 Stunden).",
        value: 1,
        letter: "d",
      },
      {
        title: "Mechanische Beatmung über Intubation oder Luftröhrenschnitt (Tracheostoma)",
        description: "Es erfolgt eine kontinuierliche mechanische Beatmung über einen Beatmungsschlauch (Intubation) oder einen Luftröhrenschnitt (Tracheostoma).",
        value: 0,
        letter: "e",
      },
    ],
  },
};

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Asset1 from '../images/Asset1.svg';
import i18next from "../locales/config";
import { englishprivacypolicy } from "../locales/data-privacy-policy/englishprivacypolicy";
import { germanprivacypolicy } from "../locales/data-privacy-policy/germanprivacypolicy";
import { italianprivacypolicy } from "../locales/data-privacy-policy/italianprivacypolicy";
import { useTranslation } from "react-i18next";
import { LoggedOutContentLaguageSwitcher } from "./GeneralTermsConditionsPage";
import  keycloak from "../keycloakConfig";

const PrivacyPolicyPage = observer(() => {

  const { t: msg } = useTranslation(['home']);
  const [data, setData] = useState(englishprivacypolicy);
  useEffect(() => {
    const getData = () => {
      switch (i18next.language) {
        case 'en':
          return englishprivacypolicy;
        case 'de':
          return germanprivacypolicy;
        case 'it':
          return italianprivacypolicy;
        default:
          return englishprivacypolicy;
      }
    };
    setData(getData());
  }, [msg]);

  const navigate = useNavigate();
  const onButtonClick = (url: string) => {
    navigate(url);
  }

  return (
    <div className="uk-section reads">
      <div className="uk-container uk-container-large">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="uk-text-right">
            {!keycloak.authenticated && <LoggedOutContentLaguageSwitcher />}
            <div className="app-image">
              <img className="logo" src={Asset1} alt="" />
            </div>
          </div>
          <h4>{data.title}</h4>
          <h5>{data.subtitle}</h5>
          <p className="text">{data.sub}</p>
          <div>

            <h5>{data.generalInformation.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.generalInformation.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

            <h5>{data.responsibilities.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.responsibilities.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

            <h5>{data.dataProcessing.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.dataProcessing.list.map((item, index) => (
                <li className="text" key={index}>
                  {item.data}
                  {item.description &&
                    <p className="text">{item.description}</p>
                  }
                  {item.description1 &&
                    <p className="text">{item.description1}</p>
                  }
                  {item.table1 &&
                    <table className="uk-table uk-table-small uk-table-striped">
                      <caption>{item.table1.title}</caption>
                      {item.table1.header.map((tab, index) => (
                        <thead key={index}>
                          <tr>
                            <th className="text">{tab.row1}</th>
                            <th className="text">{tab.row2}</th>
                          </tr>
                        </thead>
                      ))}
                      {item.table1.body.map((tab, index) => (
                        <tbody key={index}>
                          <tr>
                            <td className="text">{tab.row1}</td>
                            <td>
                              <ul>
                                {tab.row2.split('•').map((item, itemIndex) => {
                                  const trimmedItem = item.trim();
                                  if (trimmedItem !== '') {
                                    return <li key={itemIndex}>{trimmedItem}</li>;
                                  }
                                  return null;
                                })}
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  }
                  {item.subList && (
                    <ul className="uk-list uk-list-disc">
                      {item.subList.map((subItem, subIndex) => (
                        <li className="text" key={subIndex}>{subItem.data}</li>
                      ))}
                    </ul>
                  )}
                  {item.description2 &&
                    <p className="text">{item.description2}</p>
                  }
                  {item.table2 &&
                    <table className="uk-table uk-table-small uk-table-striped">
                      <caption>{item.table2.title}</caption>
                      {item.table2.header.map((tab, index) => (
                        <thead key={index}>
                          <tr>
                            <th className="text">{tab.row1}</th>
                            <th className="text">{tab.row2}</th>
                          </tr>
                        </thead>
                      ))}
                      {item.table2.body.map((tab, index) => (
                        <tbody key={index}>
                          <tr>
                            <td className="text">{tab.row1}</td>
                            <td>
                              <ul>
                                {tab.row2.split('•').map((item, itemIndex) => {
                                  const trimmedItem = item.trim();
                                  if (trimmedItem !== '') {
                                    return <li key={itemIndex}>{trimmedItem}</li>;
                                  }
                                  return null;
                                })}
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  }
                </li>
              ))}
            </ul>

            <h5>{data.dataProcessingOnWebsite.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.dataProcessingOnWebsite.list.map((item, index) => (
                <li className="text" key={index}>
                  {item.data}
                  {item.subList && (
                    <ul className="uk-list uk-list-disc">
                      {item.subList.map((subItem, subIndex) => (
                        <li className="text" key={subIndex}>{subItem.data}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>

            <h5>{data.durationOfStorage.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.durationOfStorage.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

            <h5>{data.rightsOfAffectedPerson.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.rightsOfAffectedPerson.list.map((item, index) => (
                <li className="text" key={index}>
                  <p dangerouslySetInnerHTML={{ __html: item.data }} />
                  {item.subList && (
                    <ul className="uk-list uk-list-disc">
                      {item.subList.map((subItem, subIndex) => (
                        <li className="text" key={subIndex}>{subItem.data}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>

            <h5>{data.finalProvisions.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.finalProvisions.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

          </div>
          <div className="uk-text-right">
            <button
              className="btn btn-primary"
              type="button" onClick={() => onButtonClick("/")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrivacyPolicyPage;

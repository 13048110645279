export const germanprivacypolicy = {

  title: "Datenschutzerklärung",
  subtitle: `Datenschutzerklärung für die medizinische Forschung durch die APST Research GmbH und die Nutzung der mobilen Applikation „ALS Analytics App“ `,
  sub: `Diese Datenschutzerklärung wurde zuletzt am 01. September 2023 aktualisiert.`,

  generalInformation: {
    title: "1. Allgemeines",
    list: [
      {
        data: `Die APST Research GmbH, Westhafenstraße 1, 13353 Berlin, E-Mail: info@apstresearch.com ( „APST Research“) unterstützt die medizinische Forschung auf dem Gebiet der seltenen und schweren neurologischen Erkrankungen, insbesondere bei der Amyotrophen Lateralsklerose („ALS“). APST-Research unterstützt insbesondere wissenschaftliche Vorhaben durch koordinative Dienstleistungen sowie durch mobile Applikationen („Apps“).`,
      },
      {
        data: `Die „ALS Analytics App“ ist eine mobile Softwareanwendungen, die für Patienten mit ALS die Erfassung und Darstellung von (a) Daten zum Krankheitsverlauf („klinische Daten“), (b) Laboranalysedaten und (c) Ergebnisdarstellung des biologischen Messparameters („Biomarker“) Neurofilament light chain („NfL“) ermöglicht.`,
      },
    ],
  },

  responsibilities: {
    title: "2. Verantwortlichkeiten",
    list: [
      {
        data: `Die APST Research ist für den Datenschutz und die Datensicherheit bei der Nutzung der ALS Analytics App verantwortlich. `,
      },
      {
        data: `Die personenbezogenen Daten, die von der ALS Analytics App verarbeitet werden, sind sensibel und werden daher mit effektiven und verlässlichen Maßnahmen des Datenschutzes und der Datensicherheit geschützt. Die APST Research sorgt für die Einhaltung der Datenschutzgesetze und unterliegt dabei der Datenschutzaufsicht des Landes Berlins. Auch dem unternehmensinternen Datenschutz räumt die APST Research höchste Priorität ein. Die Mitarbeiter der APST Research sind zur Verschwiegenheit und zur Einhaltung der datenschutzrechtlichen Bestimmungen verpflichtet. `,
      },
      {
        data: `Unseren Datenschutzbeauftragten erreichen Sie unter dataprotection@apst-research.com oder postalisch unter unserer Anschrift mit dem Zusatz „der Datenschutzbeauftragte“.`,
      },
    ],
  },

  dataProcessing: {
    title: "3. Datenverarbeitung ",

    list: [
      {
        data: "Grundsatz der Zweckgebundenheit, Rechtsgrundlage",
        subList: [
          {
            data: `Die APST Research verarbeitet Ihre personenbezogenen Daten nur, soweit es für die Erbringung der Forschungsvorhaben und für den Betrieb der ALS Analytics App erforderlich ist.`,
          },
          {
            data: `Die Rechtsgrundlage für die Datenverarbeitung ist das bestehende Vertragsverhältnis (Art. 6 Abs. 1 S. 1 lit. b DSGVO) sowie die Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO).`,
          },
        ],
      },
      {
        data: "Datenverarbeitung in der medizinischen Forschung",
        description: `Eine Verarbeitung der personenbezogenen Daten, die über den Betrieb der ALS Analytics App hinausgehen und zwar zu Zwecken der medizinischen Forschung, Publikation, Lehre und gesundheitswirtschaftlichen Verwertung (vgl. Tabelle 2 in Ziffer 3.6), erfolgt ausschließlich in pseudonymisierter und/oder anonymisierter Form, sofern eine entsprechende Einwilligung des Patienten vorliegt. Je nach Einwilligung werden Ihre pseudonymisierten und/oder anonymisierten Daten insbesondere für folgende Zwecke genutzt:`,
        subList: [
          {
            data: `Auswertung von Daten für die medizinische Forschung in Kooperation mit akademischen Einrichtungen, um eine Weiterentwicklung von medizinischer Behandlung zu fördern (z. B. systematische Analyse von Krankheitsverläufen und Erfahrungen mit erfolgten Behandlungen);`,
          },
          {
            data: `Auswertung von Daten für die medizinische Forschung in Kooperation mit Unternehmen der Gesundheitswirtschaft, insbesondere forschenden Biotech- und Medizintechnikunternehmen, um die Entwicklung oder Weiterentwicklung von innovativen Medikamenten, von Medizintechnik und anderen Medizinprodukten oder Behandlungsverfahren zu fördern.`,
          },
        ],
      },
      {
        data: "Datenverarbeitung in der Laboranalyse und Auswertung",
        description1: `1. Personenbezogene Daten nach Tabelle 2 in Ziffer 3.6 werden zur Durchführung von NfL-Laboranalysen verwendet. Verarbeitet werden diese Daten insbesondere für folgende Zwecke:`,
        subList: [
          {
            data: `Abwicklung, Speicherung und Auswertung von NfL-Laboranalysen aus zertifizierten Laboren;`,
          },
          {
            data: `Mitteilung von Labortestinformationen, einschließlich der Präsentation und Mitteilung der Laboranalysen in der ALS Analytics App.`,
          },
        ],
        description2: "2. Für die Analyse werden die personenbezogenen Daten in pseudonymisierter Form an ein akkreditiert	es Labor übertragen und dort bis zum Ende der Untersuchung gespeichert.",
      },
      {
        data: "Datenverarbeitung bei Einkäufen und Logistik.",
        description: "Personenbezogene Daten nach Tabelle 1 in Ziffer 3.6 werden für die Durchführung der Einkäufe unserer Produkte und Dienstleistungen verwendet, einschließlich der Abwicklung von Zahlungen.",
      },
      {
        data: "Datenverarbeitung bei der Kontaktaufnahme",
        description: `Bei der Kontaktaufnahme mit APST Research per E-Mail, SMS, Telefon oder über ein Kontaktformular werden die mitgeteilten Daten (z.B. E-Mail-Adresse, Name, Telefonnummer oder auch Inhalt der Anfrage) von der APST Research verarbeitet, um die Anfragen zu beantworten und/oder die Anliegen zu bearbeiten. Zum Beispiel telefonische Kommunikation mit dem Betroffenen, um die Ergebnisse zu besprechen oder um eine E-Mail oder SMS zu senden, für die Benachrichtigung, dass die Ergebnisse vorliegen.`,
      },
      {
        data: "Umfang und Reichweite der Datenverwendung",
        description1: `1. In der Tabelle 1 werden die personenbezogenen Daten dargestellt, die ausschließlich für die Nutzeradministration und den Betrieb der ALS Analytics App und für die Abwicklung der Einkäufe, die innerhalb der App getätigt werden, verwendet werden. Es findet keine Nutzung zum Zwecke der medizinischen Forschung statt.`,
        table1: {
          title: "Tabelle 1:",
          header: [
            {
              row1: `Datenkategorie`,
              row2: `Datenfelder`,
            },
          ],
          body: [
            {
              row1: `Kontaktdaten des Patienten`,
              row2: `•	Name, Vorname, Titel und Anrede •	Postadresse •	E-Mail-Adresse •	Telefonnummern`
            },
            {
              row1: `Daten für Einkauf von Laboranalysen`,
              row2: `•	Rechnungsanschrift •	Zahlungsdaten •	Lieferanschrift`
            },
          ]
        },
        description2: "2 .In der Tabelle 2 werden die Daten dargestellt, die pseudonymisiert und/oder anonymisiert zum Zwecke der medizinischen Forschung verwendet werden.",
        table2: {
          title: "Tabelle 2:",
          header: [
            {
              row1: `Datenkategorie`,
              row2: `Datenfelder`,
            }
          ],
          body: [
            {
              row1: `Demographische Daten`,
              row2: `•	Alter •	Geschlecht `
            },
            {
              row1: `Klinische Merkmale`,
              row2: `•	Diagnose  •	Symptombeginn (DD.MM.YYYY) `
            },
            {
              row1: `Befunde von zertifizierten Laboren `,
              row2: `•	Neurofilament light chain (NfL) im Serum`
            },
            {
              row1: `Selbstbewertungen`,
              row2: `•	ALSFRS-r – ALS Functional Rating Scale-Revised`
            },
          ]
        },
      }
    ],
  },

  dataProcessingOnWebsite: {
    title: "4. 	Datenverarbeitung beim Besuch der Webseite",
    list: [
      {
        data: `Bei der informatorischen Nutzung der Webseiten der APST Research (so z.B. www.apstresearch.com), also wenn keine Anmeldung über das persönliche Nutzerkonto erfolgt oder anderweitig personenbezogene Informationen übermittelt werden, verarbeitet die APST Research die Daten, die der Browser des Nutzers an den Server der APST Research-Webseite übermittelt und die technisch erforderlich sind, um die APST Research-Webseite anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:`,
        subList: [
          {
            data: `IP-Adresse`,
          },
          {
            data: `Datum und Uhrzeit der Anfrage`,
          },
          {
            data: `Dauer des Webseitenbesuchs`,
          },
          {
            data: `Zeitzonendifferenz zur Greenwich Mean Time (GMT),`,
          },
          {
            data: `Inhalt der Anforderung (konkrete Seite),`,
          },
          {
            data: `Zugriffsstatus/HTTP-Statuscode,`,
          },
          {
            data: `Jeweils übertragene Datenmenge,`,
          },
          {
            data: `Webseite, von der die Anforderung kommt,`,
          },
          {
            data: `Webseiten, die Sie bei uns besuchen,`,
          },
          {
            data: `Internet-Service-Provider,`,
          },
          {
            data: `Browsertyp,`,
          },
          {
            data: `Server Log Files,`,
          },
          {
            data: `Betriebssystem und dessen Oberfläche,`,
          },
          {
            data: `Sprache und Version der Browsersoftware.`,
          },
        ],
      },
      {
        data: `Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO und zwar unser berechtigtes Interesse an der Darstellung der aufgerufenen Webseiten.`,
      },
    ],

  },

  durationOfStorage: {
    title: "5. 	Speicherdauer",
    list: [
      {
        data: `Alle personenbezogenen Daten, die unter Einwilligung der Patienten zum Zwecke der medizinischen Forschung erhoben werden (Tabelle 2 in Ziffer 3.6), speichert die APST Research bis der Forschungszweck erreicht ist bzw. bis zum Widerruf der Einwilligung (siehe Ziffer 6 Abs. 3).`,
      },
      {
        data: `Alle personenbezogenen Daten nach Tabelle 1 in Ziffer 3.6 speichert die APST Research bis zu dem Zeitpunkt, in dem der Zweck, für den die Daten erhoben wurden (z.B. Bereitstellung der Produkte und Dienstleistungen), wegfällt.`,
      },
      {
        data: `Die Datenverarbeitung erfolgt auch nach dem Tod des Betroffenen (Patienten), wenn das Vertragsverhältnis nicht durch den/die Rechtsnachfolger des Patienten gekündigt bzw. die Einwilligung(-en) des Patienten nicht widerrufen werden.`,
      },
      {
        data: `Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind (z.B. die Abrechnungsdaten gemäß § 257 Abs. 1 HGB und/oder § 147 Abs. 1 AO der handels-/steuerrechtlichen Pflicht zur Aufbewahrung unterliegen), wird deren Verarbeitung eingeschränkt. D.h. die Daten werden für den operativen Betrieb gesperrt.`,
      },
    ],
  },

  rightsOfAffectedPerson: {
    title: "6. 	Rechte von Betroffenen ",
    list: [
      {
        data: `Betroffene haben gegenüber der APST Research die folgende Rechte hinsichtlich ihrer personenbezogenen Daten:`,
        subList: [
          {
            data: `Recht auf Auskunft (Art. 15 DSGVO),`,
          },
          {
            data: `Recht auf Berichtigung und Löschung (Art. 16 und 17 DSGVO),`,
          },
          {
            data: `Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO),`,
          },
          {
            data: `Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO),`,
          },
          {
            data: `Recht auf Datenübertragbarkeit (Art. 20 DSGVO).`,
          },
        ],
      },
      {
        data: `Betroffene haben zudem das Recht, sich bei der Datenschutzaufsichtsbehörde über die Verarbeitung ihrer Daten durch die APST Research zu beschweren.`,
      },
      {
        data: `APST Research weist darauf hin, dass Betroffene eine der APST Research gegebenenfalls erteilte datenschutzrechtliche Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen können.  So z.B. durch eine Nachricht an die APST Research GmbH, Westhafenstraße 1, 13353 Berlin oder eine E-Mail an <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>. Der jeweilige Widerruf kann dazu führen, dass die Angebote der APST Research gegenüber dem Betroffenen nicht mehr oder nur eingeschränkt zur Verfügung gestellt werden können.`,
      },
      {
        data: `Soweit die APST Research die Verarbeitung der personenbezogenen Daten auf eine Interessenabwägung (Art.  6 Abs. 1 S. 1 lit. f DSGVO) stützen, können Betroffene Widerspruch gegen die Verarbeitung einlegen. Bei Ausübung eines solchen Widerspruchs bittet die APST Research um Darlegung der Gründe, weshalb die APST Research die personenbezogenen Daten nicht verarbeiten sollte. Im Falle eines begründeten Widerspruchs prüft die APST Research die Sachlage und wird entweder die Datenverarbeitung einstellen bzw. anpassen oder dem Betroffenen die zwingenden schutzwürdigen Gründe der APST Research aufzeigen, aufgrund derer die APST Research die Verarbeitung fortführt.`,
      }
    ],
  },

  finalProvisions: {
    title: "7. Schlussbestimmungen",
    list: [
      {
        data: `APST Research setzt technische und organisatorische Sicherheitsmaßnahmen ein, um die verarbeiteten Daten zu schützen, insbesondere gegen zufällige oder vorsätzliche Manipulation, Verlust, Zerstörung oder gegen den Angriff unberechtigter Personen. Die Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.`,
      },
      {
        data: `Sofern die APST Research Subunternehmer einsetzt, ergreift die APST Research geeignete rechtliche wie auch technische und organisatorische Maßnahmen, um für den Schutz der personenbezogenen Daten gemäß den einschlägigen gesetzlichen Vorgaben zu sorgen. Dabei handelt es sich insbesondere um technische Dienstleister, die bei der Leistungserbringung der APST Research unterstützen.`,
      },
      {
        data: `Die APST Research wird die Datenschutzerklärung aufgrund des technischen Fortschritts und der Weiterentwicklung der Angebote von Zeit zu Zeit aktualisieren. Soweit die Änderung der Datenschutzerklärung nicht die Nutzung der bereits vorhandenen Daten betreffen, gilt die neue Datenschutzerklärung ab dem Datum ihrer Aktualisierung. Eine Änderung der Datenschutzerklärung, die sich auf die Nutzung der bereits erhobenen Daten auswirkt, erfolgt nur, sofern sie für die Betroffenen zumutbar ist. In einem solchen Fall wird die APST Research die Betroffenen rechtzeitig benachrichtigen. Betroffene haben das Recht innerhalb von vier (4) Wochen nach Erhalt der Benachrichtigung der Geltung der neuen Datenschutzerklärung zu widersprechen. Im Falle des Widerspruchs behält sich die APST Research die Kündigung des Nutzungsvertrages vor. Erfolgt innerhalb der genannten Frist kein Widerspruch, gilt die geänderte Datenschutzerklärung als vom Betroffenen angenommen. Die APST Research wird die Betroffenen in der Benachrichtigung auf ihr Widerspruchsrecht und die Bedeutung der Widerspruchsfrist hinweisen.`,
      },
      {
        data: `Die APST Research steht den Betroffenen über die E-Mail <a href="mailto:info@apstresearch.com">info@apstresearch.com</a> und die Postanschrift APST Research GmbH, Westhafenstraße 1, 13353 Berlin für weitergehende Fragen und Hinweise zum Datenschutz und zur Verarbeitung ihrer personenbezogenen Daten zur Verfügung. Für Fragen, Anregungen und Auskünfte zum Datenschutz steht den Betroffenen darüber hinaus der externe Datenschutzbeauftragte der APST Research unter <a href="mailto:datenschutz@apst-research.de">datenschutz@apst-research.de</a> zur Verfügung.`,
      }
    ],
  },
}  
export const englishTermsAndConditions = {
  termsofuse: {
    title: "General Terms and Conditions for the NfL analysis",
    semi: "These General Terms and Conditions were last updated on October 01, 2023",
    description: `APST Research GmbH ("APST Research") has developed an ALS research platform/app
     ("ALS Analytics App"). APST Research provides the ALS Analytics App to interested users who
      suffer from amyotrophic lateral sclerosis ("Users"). In the ALS Analytics App, users can
       use the ALS Functional Rating Scale revised (ALSFRSr) ("ALS Functional Rating Score")
        to record the status of their disease and assess the progression of their disease.
         In addition, users can determine the biomarker NfL (neurofilament light chain) ("NfL value")
          by taking a blood sample and have the progression analysis obtained after evaluating the NfL
           value displayed in the app ("NfL analysis").`
  },
  applicationOfSubject: {
    title: "1.	General, application and subject matter of the GTC",
    list: [
      {
        data: `APST Research GmbH ("APST Research") has developed an ALS research platform/app ("ALS Analytics App"). APST Research provides the ALS 
        Analytics App to interested users who suffer from amyotrophic lateral sclerosis ("Users") in accordance with the 
        <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "terms-of-use"}">Terms Of Use</a> of the ALS Analytics 
        App. Users have access to free services in the ALS Analytics App. In addition, for a fee, users can determine the 
        biomarker NfL (neurofilament light chain) ("NfL value") by taking a blood sample and have the progression analysis obtained after evaluation of 
        the NfL value displayed in the app ("NfL analysis").`,
      },
      {
        data: `These General Terms and Conditions ("GTC") in the version valid at the time of conclusion of the contract shall apply to all contracts between 
        APST Research and the user that are concluded via the NfL analysis offered in the ALS Analytics App`,
      }, {
        data: `By submitting an order for paid services, the user confirms that he accepts the GTC and that he agrees to their validity. The GTC hereby become 
        part of the contract for the fee-based services offered by APST Research as part of the ALS Analytics app. It is not possible to order the NfL analysis in the
         ALS Analytics App without agreeing to the GTC.`,
      },
    ],
  },
  conclusionOfContract: {
    title: "2.	Order, conclusion of contract, contract text",
    list: [
      {
        data: `The presentation of the NfL analysis functionality in the ALS Analytics App does not constitute a binding offer by APST Research. By clicking on the "Order for a fee"
         button, the user submits an offer to conclude a contract for the fee-based NfL analysis offered by APST Research as part of the ALS Analytics App. The user is then forwarded to
          the payment service provider and must enter their payment information there. Immediately after submitting the order, the user receives an automatic confirmation of their order.
         The confirmation does not constitute acceptance of the contract offer. Acceptance takes place in a separate e-mail after APST Research has received confirmation of receipt of payment for the NfL analysis.`,
      },
      {
        data: `The user can print or save these GTC using the browser function. The user can also download and archive this document in PDF form as part of the ordering process. Upon confirmation of the order,
         APST Research shall send the user the GTC in their current version. APST Research does not save the text of the contract.`,
      }
    ],
  },
  scopeOfService: {
    title: "3.	Service description",
    list: [
      {
        data: `The fee-based NfL analysis service offered in the ALS Analytics app offers users the option of determining the NfL progression marker (NfL value) by taking a blood sample and displaying the
         progression analysis obtained after evaluating the NfL value in the app. The service includes the following features:`,
        subList: [
          {
            data: `The sending of a blood collection kit to the user, including an information sheet for the doctor taking the blood sample.`,
          },
          {
            data: `The sending of the blood sample by the doctor consulted by the user to APST Research and a laboratory commissioned to examine the blood sample.`,
          },
          {
            data: `The examination of the blood sample in a laboratory to determine the NfL value, including the transfer of the values to APST Research.`,
          },
          {
            data: `The evaluation of the test result, in particular the NfL value, by APST Research in relation to other values, including the ALS Functional Rating Score determined by the user in the ALS Analytics App.`,
          },
          {
            data: `The display of the NfL analysis in the ALS Analytics app.`,
          },
        ]
      },

      {
        data: `The user visits a doctor of their choice for the blood sample. The medical services for the blood sample are self-pay services and are not included in the fee-based NfL analysis service offered in the ALS Analytics app.`,
      },
      {
        data: `The user may repeat the NfL analysis every six months. These General Terms and Conditions apply to every order`,
      }
    ],
  },
  accessRequirements: {
    title: "4.	Access requirements",
    description: `In order to be able to use the NfL analysis offered in the ALS Analytics App, the user must have registered in the ALS Analytics App and created a user account in accordance with the ALS Analytics App 
    <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "terms-of-use"}">Terms Of Use</a>.`
  },
  revocation: {
    title: "5.	Revocation",
    description: `As a consumer, the user has a legal right of withdrawal: <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "revocation"}">Revocation Policy</a>.`,
  },
  costOfDelivery: {
    title: "6.	Costs, delivery",
    list: [
      {
        data: "The cost of using the NfL analysis is EUR 120.00 including VAT. The costs include the services according to section 3.1",
      },
      {
        data: "However, the amount of EUR 120.00 within the meaning of Section 6.1 does not include the medical services within the scope of the blood sample in accordance with Section 3.2. The costs of the blood sample will be invoiced separately by the doctor to the user as a self-payer service. APST Research is not involved in these procedures.",
      },
      {
        data: "Only the payment methods displayed in the ALS Analytics app at the end of the order process can be used for payment to APST Research in accordance with section 6.1. For each order, APST Research reserves the right not to offer certain payment methods or to refer to other payment methods.",
      },
      {
        data: "The ordered product will be delivered to the delivery address specified by the user during the ordering process.",
      },
    ],
  },
  liability: {
    title: "7.	Liability",
    list: [
      {
        data: `Notwithstanding the following limitations of liability, APST Research shall be liable without limitation for damages to life, body and health that are based on a negligent
         or intentional breach of duty by APST Research, its legal representatives or vicarious agents, as well as for damages that are covered by liability under the Product Liability Act,
         as well as for damages that are based on intentional or grossly negligent breaches of contract and fraudulent intent by APST Research, its legal representatives or vicarious agents.`,
      },
      {
        data: `In the event of negligence, APST Research's liability shall be limited to compensation for typical foreseeable damage in connection with the use of the ALS Analytics App and the Services.
         In the event of simple negligence, however, APST Research shall only be liable if APST Research has breached an obligation, the fulfillment of which was essential for
         the proper use of the ALS Analytics App and the services, taking into account the interests of both parties, and on the fulfillment of which the user was entitled to rely.`,
      }, {
        data: `Any further liability of APST Research is excluded regardless of the legal nature of the asserted claim. Insofar as the liability of APST Research is excluded or limited, this shall also apply to
         the personal liability of its employees, workers, staff, representatives and vicarious agents.`,
      },
    ],
  },
  dataPrivacy: {
    title: "8.	Data privacy",
    description: `APST Research would like to point out that when using the ALS Analytics app and the NfL analysis functionality, the user's personal data is processed. APST Research complies with the applicable data protection regulations.
     Information on how personal data is processed can be found in the <a className="uk-text-primary gtc-text" target="_blank" href="${process.env.REACT_APP_FRONTEND_URL + "data-privacy-policy"}">Data Privacy Information</a>`
  },
  changesToTerms: {
    title: "9.	Changes to the GTC",
    list: [
      {
        data: `APST Research reserves the right to amend or supplement these GTC at any time without stating reasons with effect for the future if this is necessary to adapt to changed circumstances for which APST Research
         is not responsible (in particular changes in the law and difficulties in the execution of the user contract that were unforeseeable when the contract was concluded) and the amendment is reasonable for the user or the
          amendment is merely advantageous for the user. An intended change will be sent to the email address provided to APST Research or communicated via an announcement in the ALS Analytics app. Changes shall take
           effect no later than 14 days after the change has been published in the app or the user has been made aware of the change in some other way, unless the user objects to the change within 14 days. The user is
            deemed to have accepted the new terms and conditions if the service is used after these 14 days have expired.`,
      },
      {
        data: `In the event of an objection within the deadline, APST Research may terminate the contractual relationship with the user without notice. If APST Research does not terminate the previous contractual relationship
         after the effective objection, the previous GTC shall continue to apply unchanged in relation to the corresponding user.`,
      }
    ],
  },
  applicableLaw: {
    title: "10.	Applicable law, place of jurisdiction, dispute resolution",
    list: [
      {
        data: `These GTC, their subject matter and their creation (as well as all non-contractual disputes or claims) are subject to the law of the Federal Republic of Germany, excluding the UN Convention on Contracts for the
         International Sale of Goods (CISG), unless mandatory consumer protection law prescribes otherwise.`,
      },
      {
        data: `The place of jurisdiction is Berlin if the user is a merchant within the meaning of the German Commercial Code, a legal entity under public law or a special fund under public law. The same applies to users who
         do not have a general place of jurisdiction in Germany or another EU member state or who have moved their place of residence abroad after conclusion of the contract or whose place of residence or habitual abode is 
         not known at the time the action is filed. In all other respects, the applicable statutory provisions shall apply to local and international jurisdiction.`,
      }, {
        data: `The European Commission provides a platform for online dispute resolution (OS). You can find the link to the platform here: <a className="uk-text-primary gtc-text" target="_blank" href="https://ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</a>.
         APST Research is not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.`,
      },
    ],
  },
  finalProvisions: {
    title: "11.	Final provisions",
    list: [
      {
        data: `Should individual provisions of these GTC be invalid or unenforceable or become invalid or unenforceable after conclusion of the contract, this shall not affect the validity of the remainder of the contract.
         The invalid or unenforceable provision shall be replaced by the relevant statutory provisions. If statutory law is not available in the respective case, the valid or enforceable provision shall replace the invalid or
          unenforceable provision whose effects come closest to the economic objective pursued by APST Research and the user with the invalid or unenforceable provision.`,
      },
      {
        data: "Clause 11.1 shall apply accordingly in the event that one or more of the conditions prove to be incomplete.",
      }
    ],
  },
};

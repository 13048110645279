import { Outlet } from "react-router-dom";
import { LoadingEllipsis } from "../../shared/components/loading/Loading";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  fetchingData: boolean;
}
const MainLayout = (props: IProps) => {
  const { fetchingData } = props;

  const { i18n } = useTranslation(['home']);

  useEffect(() => {
    const lang = window.localStorage.getItem("lng");
    i18n.changeLanguage(`${lang}`)
  }, [i18n]);

  return (
    <main className="main-layout">
      {!fetchingData && <Outlet />}
      {fetchingData && <LoadingEllipsis fullHeight />}
    </main>
  );
};

export default MainLayout;
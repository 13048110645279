import useTitle from "../../../shared/hooks/useTitle"
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18next from "../../../locales/config"
import { useEffect, useState } from "react";
import { englishinfolibrary } from "../../../locales/library/english"
import { germaninfolibrary } from "../../../locales/library/german"
import { italianinfolibrary } from "../../../locales/library/italian"

export const AboutAllAbbrev = observer(() => {

    const navigate = useNavigate();
    const { t: msg } = useTranslation(['home']);

    const [data, setData] = useState(englishinfolibrary);
    useTitle(data.aboutAllAbbrev.title);

    useEffect(() => {
        const getData = () => {
            switch (i18next.language) {
                case 'en':
                    return englishinfolibrary;
                case 'de':
                    return germaninfolibrary;
                case 'it':
                    return italianinfolibrary;
                default:
                    return englishinfolibrary;
            }
        };
        setData(getData());
    }, [msg]);

    const onButtonClick = (url: string) => {
        navigate(url);
    }

    return (
        <div className="uk-section profile">
            <div className="uk-container uk-container-large">
                <h3 className="profile-title">{msg("informationLibrary")}</h3>
                <p className="sub-title">{data.aboutAllAbbrev.title}</p>
                <div className="profile-card uk-card uk-card-default uk-card-body">
                    <div>
                        <p className="text" dangerouslySetInnerHTML={{ __html: data.aboutAllAbbrev.description }}></p>
                    </div>
                    <div>
                        <div className="uk-margin uk-flex uk-flex-between">
                            <div>
                                <button className="btn btn-primary"
                                    type="button"
                                    onClick={() => onButtonClick("/info/about-lab")}>
                                     {msg("buttonPrevious")}
                                </button>
                            </div>
                            <div>
                                <button className="btn btn-primary"
                                    type="button"
                                    onClick={() => onButtonClick("/info/cancel-kit")}>
                                     {msg("buttonNext")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
})
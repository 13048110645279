import { FormEvent, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import useTitle from "../../shared/hooks/useTitle"
import { useTranslation } from "react-i18next";
import { IPatient, defaultPatient } from "../../models/Patient";
import { Loader } from "../../shared/components/loading/Loading";
import icons from "../../shared/components/utils/icons";
import { useAppContext } from "../../shared/context/Context";

export const Profile = observer(() => {
    const { api, store } = useAppContext();
    const { t: msg } = useTranslation(['home']);

    const [patient, setPatient] = useState<IPatient>({ ...defaultPatient });
    const [loading, setLoading] = useState(false);
    useTitle("Profile");

    const user = store.patient.patientJson;
    const max_date = new Date().toISOString().split('T')[0];

    const onSave = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);
        const _patient: IPatient = {
            ...patient,
            user_id: patient.user_id,
            gender: patient.gender,
            phone: patient.phone,
            date_of_birth: patient.date_of_birth,
            diagnosis: patient.diagnosis,
            time_of_symptoms_onset: patient.time_of_symptoms_onset,
        }

        await api.patient.update(_patient);
        setLoading(false)
    }

    useEffect(() => {
        if (store.patient.patientJson)
            setPatient({ ...store.patient.patientJson });
        else {
            setPatient({ ...defaultPatient })
        }
    }, [store.patient.patientJson]);


    return (
        <div className="uk-section profile">
            <div className="uk-container uk-container-large">
                <form onSubmit={onSave}>
                    <h3 className="profile-title"> <img alt="icon" src={icons.account_circle} className="icon" /></h3>
                    <div className="profile-card uk-card uk-card-default uk-card-small uk-margin-bottom">
                        <div className="uk-card-body">
                            <div className="uk-margin">
                                <legend className="uk-legend profile-card-title">{msg("userProfile")}</legend>
                            </div>
                            <div className="uk-grid-small" data-uk-grid>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="firstname">
                                        {msg("firstName")}
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            type="text"
                                            id="firstname"
                                            value={user?.first_name || ""}
                                            readOnly
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="lastname">
                                        {msg("lastName")}
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            type="text"
                                            id="lastname"
                                            value={user?.last_name || ""}
                                            readOnly
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="gender">
                                        {msg("gender")}
                                    </label>
                                    <div className="uk-form-controls">
                                        <select
                                            className="uk-select"
                                            id="gender"
                                            value={patient.gender || ""}
                                            onChange={(e) => setPatient({ ...patient, gender: (e.target.value) })}>
                                            <option value={"M"}>{msg("male")}</option>
                                            <option value={"F"}>{msg("female")}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="dob">
                                        {msg("dateOfBirth")}
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            id="dob"
                                            max={max_date}
                                            type="date"
                                            value={patient.date_of_birth || ""}
                                            onChange={(e) => setPatient({ ...patient, date_of_birth: (e.target.value) })}
                                        />
                                    </div>
                                </div>
                                <div className="uk-width-1-1@s">
                                    <label className="uk-form-label" htmlFor="username">
                                        {msg("userName")}
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            type="text"
                                            id="username"
                                            value={user?.username || ""}
                                            readOnly
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-card uk-card uk-card-default uk-card-small uk-margin-bottom">
                        <div className="uk-card-body">
                            <div className="uk-margin">
                                <legend className="uk-legend profile-card-title">{msg("contactInformation")}</legend>
                            </div>
                            <div className="uk-form-stacked uk-grid-small" data-uk-grid>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="email">
                                        {msg("emailAddress")}
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            type="email"
                                            id="email"
                                            defaultValue={user?.email}
                                            readOnly={true}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="phone">
                                        {msg("telephoneNumber")}
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            id="phone"
                                            value={patient.phone || ""}
                                            maxLength={15}
                                            onChange={(e) => setPatient({ ...patient, phone: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-card uk-card uk-card-default uk-card-small uk-margin-bottom">
                        <div className="uk-card-body">
                            <div className="uk-margin">
                                <legend className="uk-legend profile-card-title">{msg("medicalDataDiagnosis")}</legend>
                            </div>
                            <div className="uk-form-stacked uk-grid-small" data-uk-grid>
                                <div className="uk-width-1-1@s">
                                    <label className="uk-form-label" htmlFor="time_of_symptoms_onset">
                                        {msg("dateOfSymptomsOnset")}
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            id="time_of_symptoms_onset"
                                            type="date"
                                            max={max_date}
                                            value={patient.time_of_symptoms_onset || ""}
                                            onChange={(e) => setPatient({ ...patient, time_of_symptoms_onset: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <label className="uk-form-label" htmlFor="diagnosis">
                                        {msg("diagnosis")}
                                    </label>
                                    <div className="uk-form-controls">
                                        <textarea
                                            className="uk-textarea"
                                            id="diagnosis"
                                            rows={3}
                                            defaultValue={patient.diagnosis}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-margin uk-width-1-1">
                                <button
                                    style={{ width: "100%" }}
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={loading}>
                                    {msg("submitButton")}
                                    {loading && <Loader />}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
});

import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import MODAL_NAMES from "../ModalName";
import { observer } from "mobx-react-lite";
import useFunctions from "./useFunctions";
import { englishSurvey } from "./englishSurvey";
import { germanSurvey } from "./germanSurvey";
import { useTranslation } from "react-i18next";
import { italianSurvey } from "./ItalianSurvey";
import { ISurvey, defaultSurvey } from "../../../models/Survey";
import { CustomCloseAccordion } from "../../../shared/components/accordion/CustomAccordion";
import { hideModalFromId } from "../../../shared/components/functions/ModalShow";
import { Loader } from "../../../shared/components/loading/Loading";
import { useAppContext } from "../../../shared/context/Context";
import i18next from "../../../locales/config";

interface IItemProps {
  label: string;
  no: string;
  desc?: string;
  description?: string;
}

const ItemHeader = (props: IItemProps) => {
  const { label, no, desc, description } = props;
  return (
    <div className="sv-item-header uk-flex">
      <div className="sv-item-no">{no}</div>
      <div className="item-header-label">{label}<br />
        {desc &&
          <div>
            <small>{desc}</small>
          </div>
        }
        <small>{description}</small>
      </div>
    </div>
  );
};

interface IRadioProps {
  label: string;
  description: string;
  point: number;
  value: number;
  letter: string;
  qid: string;
  loading: boolean;
  onChange: (name: string, value: number) => void
}

const RadioInput = (props: IRadioProps) => {

  const { point, label, description, value, onChange, letter, qid, loading } = props;

  return (
    <div className="uk-margin sv-input-comp">
      <div className="comp-label">
        <div className="uk-flex uk-inline">
          <div> <small>{`(${point})`}</small><span>&nbsp;</span></div>
          <div>
            <input
              className="uk-radio"
              type="radio"
              name={qid}
              id={qid}
              value={value}
              onChange={(e) => onChange(letter, Number(e.target.value))}
              disabled={loading}
              required
            />
          </div>
          <div><span>&nbsp;</span>{label}</div>
        </div>
        <div className="uk-margin-medium-left comp-description">
          <small>{description}</small>
        </div>
      </div>
    </div>
  );
};

const SurveyModal = observer(() => {
  const { api, store } = useAppContext();
  const patient = store.patient.patientJson;
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const { t: msg } = useTranslation(['home']);
  const [questions, setQuestions] = useState(englishSurvey);

  useEffect(() => {
    const getData = () => {
      switch (i18next.language) {
        case 'en':
          return englishSurvey;
        case 'de':
          return germanSurvey;
        case 'it':
          return italianSurvey;
        default:
          return englishSurvey;
      }
    };
    setQuestions(getData());
  }, [msg]);

  const { onQ1Change, onQ2Change, onQ3Change, onQ4Change, onQ5AChange,
    onQ5BChange, onQ6Change, onQ7Change, onQ8Change, onQ9Change,
    onQ10Change, onQ11Change, onQ12Change, survey, setSurvey }
    = useFunctions();

  const handleQ5Change = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
  };

  const onSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true)
    const _survey: ISurvey = {
      ...survey,
      useFeedingTube: selectedOption,
      patient_id: patient!.user_id,
    }
    await api.survey.create(_survey);
    setLoading(false);
    onCancel();
  }

  const onCancel = () => {
    setSurvey({ ...defaultSurvey })
    hideModalFromId(MODAL_NAMES.PATIENT.SURVEY_MODAL);
  }

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button className="uk-modal-close-default" type="button" data-uk-close></button>
      <div className="uk-padding-small">
        <div className="sv-title-div">
          <p className="uk-text-small">{questions.page.title}</p>
          <CustomCloseAccordion title={msg("questionnaireDesciption")}>
            <div className="sv-description">
              <small>{questions.page.description}</small>
            </div>
          </CustomCloseAccordion>
        </div>
        <form onSubmit={onSave}>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.speech.no} label={questions.speech.title} description={msg("questionnaireInstruction")} />
            {questions.speech.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.speech.qid}
                onChange={onQ1Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.salvation.no} label={questions.salvation.title} description={msg("questionnaireInstruction")} />
            {questions.salvation.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.salvation.qid}
                onChange={onQ2Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.swallowing.no} label={questions.swallowing.title} description={msg("questionnaireInstruction")} />
            {questions.swallowing.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.swallowing.qid}
                onChange={onQ3Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.handwriting.no} label={questions.handwriting.title}
              desc={questions.handwriting.desc}
              description={msg("questionnaireInstruction")} />
            {questions.handwriting.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.handwriting.qid}
                onChange={onQ4Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.useFeedingTube.no} label={questions.useFeedingTube.title} description={msg("questionnaireInstruction")} />
            {questions.useFeedingTube.options.map((op, index) => (
              <div className="uk-margin sv-input-comp" key={index}>
                <label className="comp-label">
                  <input
                    className="uk-radio"
                    type="radio"
                    name={questions.useFeedingTube.qid}
                    value={op.value}
                    disabled={loading}
                    onChange={handleQ5Change}
                    required
                  /> {op.title}
                </label>
              </div>
            ))}
          </div>
          {selectedOption === "No" &&
            <div className="uk-margin-top sv-question">
              <ItemHeader no={questions.useOfCutlery.no} label={questions.useOfCutlery.title} desc={questions.useOfCutlery.desc}
                description={msg("questionnaireInstruction")} />
              {questions.useOfCutlery.options.map((op, index) => (
                <RadioInput
                  key={index}
                  letter={op.letter}
                  qid={questions.useOfCutlery.qid}
                  onChange={onQ5AChange}
                  point={op.value}
                  label={op.title}
                  description={op.description}
                  value={op.value}
                  loading={loading}
                />
              ))}
            </div>
          }
          {selectedOption === "Yes" &&
            <div className="uk-margin-top sv-question">
              <ItemHeader no={questions.useOfFeedingTube.no} label={questions.useOfFeedingTube.title}
                desc={questions.useOfFeedingTube.desc}
                description={msg("questionnaireInstruction")} />
              {questions.useOfFeedingTube.options.map((op, index) => (
                <RadioInput
                  key={index}
                  letter={op.letter}
                  qid={questions.useOfFeedingTube.qid}
                  onChange={onQ5BChange}
                  point={op.value}
                  label={op.title}
                  description={op.description}
                  value={op.value}
                  loading={loading}
                />
              ))}
            </div>
          }
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.dressingAndHygiene.no} label={questions.dressingAndHygiene.title} description={msg("questionnaireInstruction")} />
            {questions.dressingAndHygiene.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.dressingAndHygiene.qid}
                onChange={onQ6Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.turningInBedAndAdjustingTheBedClothes.no} label={questions.turningInBedAndAdjustingTheBedClothes.title}
              description={msg("questionnaireInstruction")} />
            {questions.turningInBedAndAdjustingTheBedClothes.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.turningInBedAndAdjustingTheBedClothes.qid}
                onChange={onQ7Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.walking.no} label={questions.walking.title}
              description={msg("questionnaireInstruction")} />
            {questions.walking.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.walking.qid}
                onChange={onQ8Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.climbingTheStairs.no} label={questions.climbingTheStairs.title}
              description={msg("questionnaireInstruction")} />
            {questions.climbingTheStairs.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.climbingTheStairs.qid}
                onChange={onQ9Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.dyspneaAndshortnessOfBreath.no} label={questions.dyspneaAndshortnessOfBreath.title}
              description={msg("questionnaireInstruction")} />
            {questions.dyspneaAndshortnessOfBreath.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.dyspneaAndshortnessOfBreath.qid}
                onChange={onQ10Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.sleepDisturbanceBreathingProblems.no} label={questions.sleepDisturbanceBreathingProblems.title}
              desc={questions.sleepDisturbanceBreathingProblems.desc}
              description={msg("questionnaireInstruction")} />
            {questions.sleepDisturbanceBreathingProblems.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.sleepDisturbanceBreathingProblems.qid}
                onChange={onQ11Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-margin-top sv-question">
            <ItemHeader no={questions.mechanicalVentilation.no} label={questions.mechanicalVentilation.title}
              description={msg("questionnaireInstruction")} />
            {questions.mechanicalVentilation.options.map((op, index) => (
              <RadioInput
                key={index}
                letter={op.letter}
                qid={questions.mechanicalVentilation.qid}
                onChange={onQ12Change}
                point={op.value}
                label={op.title}
                description={op.description}
                value={op.value}
                loading={loading}
              />
            ))}
          </div>
          <div className="uk-text-right">
            <button
              className="btn btn-warning"
              disabled={loading}
              type="button" onClick={onCancel}>
              {msg("cancelButton")}
            </button>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading}>
              {msg("submitButton")}
              {loading && <Loader />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default SurveyModal;
export const englishprivacypolicy = {

  title: "Privacy policy",
  subtitle: `Privacy policy for medical research by APST Research GmbH and the use of the mobile application "ALS Analytics App"`,
  sub: `This privacy policy was last updated on September 01, 2023`,

  generalInformation: {
    title: "1. General information ",
    list: [
      {
        data: `APST Research GmbH, Westhafenstraße 1, 13353 Berlin, e-mail: <a href="mailto:info@apstresearch.com">info@apstresearch.com</a> ("APST Research") supports medical research in the field of rare and severe neurological diseases, in particular amyotrophic lateral sclerosis ("ALS"). In particular, APST Research supports scientific projects through coordinating services and mobile applications ("apps").`,
      },
      {
        data: `"ALS Analytics App" is a mobile software application that enables patients with ALS to collect and display (a) data on the course of the disease ("clinical data"), (b) laboratory analysis data and (c) results of the biological measurement parameter ("biomarker") neu-rofilament light chain ("NfL").`,
      },
    ],
  },

  responsibilities: {
    title: "2. Responsibilities",
    list: [
      {
        data: `APST Research is responsible for data protection and data security when using the ALS Analytics App. `,
      },
      {
        data: `The personal data processed by the ALS Analytics app is sensitive and is therefore protected by effective and reliable data protection and data security measures. APST Research ensures compliance with data protection laws and is subject to data protection supervision by the state of Berlin. APST Research also gives top priority to internal data protection. The employees of APST Research are obliged to maintain confidentiality and to comply with data protection regulations.`,
      },
      {
        data: `You can contact our data protection officer at <a href="mailto:dataprotection@apst-research.com">dataprotection@apst-research.com</a> or by post at our address with the addition "the data protection officer". `,
      },
    ],
  },

  dataProcessing: {
    title: "3. 		Data processing",

    list: [
      {
        data: "Principle of purpose limitation, legal basis",
        subList: [
          {
            data: `APST Research processes your personal data only to the extent necessary for the performance of the research projects and for the operation of the ALS Analytics App`,
          },
          {
            data: `The legal basis for data processing is the existing contractual relationship (Art. 6 para. 1 sentence 1 lit. b GDPR) and consent (Art. 6 para. 1 sentence 1 lit. a GDPR). `,
          },
        ],
      },
      {
        data: "Data processing in medical research",
        description: `The processing of personal data that goes beyond the operation of the ALS Analytics App, namely for the purposes of medical research, publication, teaching and health economic utilization (see Table 2 in Section 3.6), takes place exclusively in pseudonymized and/or anonymized form, provided that the patient has given his or her consent. Depending on your consent, your pseudonymized and/or anonymized data will be used for the following purposes in particular:`,
        subList: [
          {
            data: `Evaluation of data for medical research in cooperation with academic institutions in order to promote the further development of medical treatment (e.g. systemic analysis of disease progression and experiences with treatments);`,
          },
          {
            data: `Evaluation of data for medical research in cooperation with companies in the healthcare industry, in particular research-based biotech and medical technology companies, in order to promote the development or further development of innovative drugs, medical technology and other medical products or treatment procedures.`,
          },
        ],
      },
      {
        data: "Data processing in laboratory analysis and evaluation.",
        description1: `1. Personal data according to Table 2 in Section 3.6 are used to carry out NfL laboratory analyses. This data is processed in particular for the following purposes:`,
        subList: [
          {
            data: `Processing, storage and evaluation of NfL laboratory analyses from certified laboratories;`,
          },
          {
            data: `Communication of laboratory test information, including the presentation and communication of laboratory analyses in the ALS Analytics App.`,
          },
        ],
        description2: "2.  For the analysis, the personal data is transferred to an accredited laboratory in pseudonymized form and stored there until the end of the analysis.",
      },
      {
        data: "Data processing for purchases and logistics.",
        description: "Personal data in accordance with Table 1 in Section 3.6 is used to process purchases of our products and services, including the processing of payments.",
      },
      {
        data: "Data processing when contacting us.",
        description: `When contacting APST Research by e-mail, SMS, telephone or via a contact form, the data provided (e.g. e-mail address, name, telephone number or the content of the enquiry) is processed by APST Research in order to answer the inquiries and/or process the concerns. For example, telephone communication with the data subject to discuss the results or to send an email or text message to notify them that the results are available.`,
      },
      {
        data: "Scope and range of data use",
        description1: `1. Table 1 shows the personal data that is used exclusively for user administration and operation of the ALS Analytics app and for processing purchases made within the app. It is not used for the purposes of medical research.`,
        table1: {
          title: "Table 1: ",
          header: [
            {
              row1: `Data category`,
              row2: `Data fields `,
            },
          ],
          body: [
            {
              row1: `Contact details of the patient`,
              row2: `•	Surname, first name, title and form of address •	Postal address •	e-Mail-address •	Telephone numbers`
            },
            {
              row1: `Data for purchasing laboratory analyses`,
              row2: `•	Invoice address•	Payment details •	Delivery address`
            },
          ]
        },
        description2: "2. Table 2 shows the data that is used pseudonymized and/or anonymized for the purpose of medical research.",
        table2: {
          title: "Table 2: ",
          header: [
            {
              row1: `Data category`,
              row2: `Data fields `,
            }
          ],
          body: [
            {
              row1: `Demographic data`,
              row2: `•	Age •	Gender `
            },
            {
              row1: `Clinical characteristics`,
              row2: `•	Diagnosis •	Symptom onset (DD.MM.YYYY)`
            },
            {
              row1: `Reports from certified laboratories`,
              row2: `•	Neurofilament light chain (NfL) in serum`
            },
            {
              row1: `Self-assessments`,
              row2: `•	ALSFRS-r – ALS Functional Rating Scale-Revised`
            },
          ]
        },
      }
    ],
  },

  dataProcessingOnWebsite: {
    title: "4. 	Data processing when visiting the website",
    list: [
      {
        data: `When using the APST Research website for informational purposes (e.g. www.apstresearch.com), i.e. if no registration via the personal user account takes place or other personal information is transmitted, APST Research processes the data that the user's browser transmits to the server of the APST Research website and which is technically necessary to display the APST Research website and to ensure stability and security:`,
        subList: [
          {
            data: `IP address,`,
          },
          {
            data: `Date and time of the request,`,
          },
          {
            data: `Duration of the website visit,`,
          },
          {
            data: `Time zone difference to Greenwich Mean Time (GMT),`,
          },
          {
            data: `Content of the request (specific page),`,
          },
          {
            data: `Access status/HTTP status code,`,
          },
          {
            data: `Amount of data transferred in each case,`,
          },
          {
            data: `Website from which the request originates,`,
          },
          {
            data: `Websites that you visit on our site,`,
          },
          {
            data: `Internet service provider, `,
          },
          {
            data: `Browser type,`,
          },
          {
            data: `Server log files,`,
          },
          {
            data: `Operating system and its interface`,
          },
          {
            data: `Language and version of the browser software. `,
          },
        ],
      },
      {
        data: `The legal basis is Art. 6 para. 1 sentence 1 lit. f GDPR, namely our legitimate interest in the presentation of the websites accessed.`,
      },
    ],

  },

  durationOfStorage: {
    title: "5. 	Duration of storage",
    list: [
      {
        data: `APST Research stores all personal data collected with the consent of patients for the purpose of medical research (Table 2 in Section 3.6) until the purpose of the research has been achieved or until consent is withdrawn (see Section 6 (3)).`,
      },
      {
        data: `APST Research stores all personal data in accordance with Table 1 in Section 3.6 until such time as the purpose for which the data was collected (e.g. provision of products and services) ceases to apply.`,
      },
      {
        data: `Data processing shall continue even after the death of the data subject (patient) if the contractual relationship is not terminated by the patient's legal successor(s) or the patient's consent(s) are not revoked. `,
      },
      {
        data: `If the data is not deleted because it is required for other and legally permissible purposes (e.g. the accounting data is subject to the retention obligation under commercial/tax law pursuant to Section 257 (1) HGB and/or Section 147 (1) AO), its processing is restricted. This means that the data is blocked for operational use. `,
      },
    ],
  },

  rightsOfAffectedPerson: {
    title: "6. 	Rights of affected persons",
    list: [
      {
        data: `Affected persons have the following rights vis-à-vis APST Research with regard to their personal data:`,
        subList: [
          {
            data: `Right of access (Art. 15 GDPR),`,
          },
          {
            data: `Right to rectification and erasure (Art. 16 and 17 GDPR),`,
          },
          {
            data: `Right to restriction of processing (Art. 18 GDPR),`,
          },
          {
            data: `Right to object to processing (Art. 21 GDPR),`,
          },
          {
            data: `Right to data portability (Art. 20 GDPR).`,
          },
        ],
      },
      {
        data: `Affected persons also have the right to complain to the data protection supervisory authority about the processing of their data by APST Research.`,
      },
      {
        data: `APST Research would like to point out that data subjects can revoke any data protection consent given to APST Research at any time with effect for the future.  For example, by sending a message to APST Research GmbH, Westhafenstraße 1, 13353 Berlin or an e-mail to <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>. The respective revocation can lead to the fact that the offers of APST Research can no longer be made available to the person concerned or only to a limited extent.`,
      },
      {
        data: `Insofar as APST Research bases the processing of personal data on a balancing of interests (Art. 6 para. 1 sentence 1 lit. f GDPR), data subjects may object to the processing. When exercising such an objection, APST Research asks for an explanation of the reasons why APST Research should not process the personal data. In the event of a justified objection, APST Research will examine the situation and will either discontinue or adapt the data processing or point out to the data subject the compelling legitimate grounds on the basis of which APST Research will continue the processing.`,
      }
    ],
  },

  finalProvisions: {
    title: "7. Final provisions",
    list: [
      {
        data: `APST Research uses technical and organizational security measures to protect the processed data, in particular against accidental or intentional manipulation, loss, destruction or against attacks by unauthorized persons. The security measures are continuously improved in line with technological developments.`,
      },
      {
        data: `If APST Research uses subcontractors, APST Research takes appropriate legal as well as technical and organizational measures to ensure the protection of personal data in accordance with the relevant legal requirements. These are, in particular, technical service providers who support APST Research in the provision of its services.`,
      },
      {
        data: `APST Research will update the privacy policy from time to time due to technical progress and the further development of its services. Insofar as the change to the data protection declaration does not affect the use of existing data, the new data protection declaration shall apply from the date of its update. An amendment to the privacy policy that affects the use of data already collected will only be made if it is reasonable for the affected persons. In such a case, APST Research will notify the persons affected in good time. Affected persons have the right to object to the validity of the new privacy policy within four (4) weeks of receipt of the notification. In the event of an objection, APST Research reserves the right to terminate the user contract. If no objection is made within the aforementioned period, the amended data protection declaration shall be deemed to have been accepted by the affected person. APST Research will inform the affected persons in the notification of their right to object and the significance of the objection period.`,
      },
      {
        data: `APST Research is available to affected persons via the e-mail <a href="mailto:info@apstresearch.com">info@apstresearch.com</a> and the postal address APST Research GmbH, Westhafenstraße 1, 13353 Berlin for further questions and information on data protection and the processing of their personal data. For questions, suggestions and information on data protection, affected persons can also contact the external data protection officer of APST Research at <a href="mailto:datenschutz@apst-research.de">datenschutz@apst-research.de</a>. `,
      }
    ],
  },
};

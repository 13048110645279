import { useState } from "react";
import { observer } from "mobx-react-lite";
import { ILabResult } from "../../models/LabResult";
import { Loader } from "../../shared/components/loading/Loading";
import icons from "../../shared/components/utils/icons";
import { formatDate } from "../../shared/components/utils/utils";
import { TFunction } from "i18next";

interface IProps {
    result: ILabResult
    onViewPDF: (resultId: number, date: string) => Promise<void>
    msg: TFunction<[string]>
}

export const LabItem = observer((props: IProps) => {

    const { result, onViewPDF, msg } = props;
    const [loading, setLoading] = useState(false);

    const handleViewPDF = async () => {
        setLoading(true);
        await onViewPDF(result.id, result.date);
        setLoading(false);
    };

    return (
        <div className="results uk-card-default">
            <p className="date"><img alt="icon" src={icons.today} className="icon" />{formatDate(result.date)}</p>
            <div className="uk-margin">
                <div className="result">
                    <p className="title">{msg("dateOfSampleCollection")}</p>
                    <p className="score">{formatDate(result.date_of_sample_collection)}</p>
                </div>
                <div className="result">
                    <p className="title">{msg("dateOfSampleReception")}</p>
                    <p className="score">{formatDate(result.date_of_sample_reception)}</p>
                </div>
                <div className="result">
                    <p className="title">{msg("dateOfMeasurement")}</p>
                    <p className="score">{formatDate(result.date_of_measurement)}</p>
                </div>
                <div className="result">
                    <p className="title">{msg("nflValue")}</p>
                    <p className="score">{result.nfl_value}</p>
                </div>
            </div>
            <textarea
                className="uk-textarea desc"
                rows={5}
                value={result.comment}
                disabled
            />
            <div className="uk-margin-small">
                <button className="btn btn-primary" title="View" onClick={handleViewPDF} >
                    {msg("viewDocumentButton")}
                    {loading && <Loader />}
                </button>
            </div>
        </div>
    )
});
import Store from "./Store";
import { runInAction } from "mobx";
import AppStore from "./AppStore";
import SurveyModel, { ISurvey } from "../models/Survey";

export default class SurveyStore extends Store<ISurvey, SurveyModel> {
  items = new Map<number, SurveyModel>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: ISurvey[] = []) {
    runInAction(() => {
      items.forEach((item,) => {
        this.items.set(item.survey_id, new SurveyModel(this.store, item));
      });
    });
  }

  getLatestSurvey(surveys: SurveyModel[]): SurveyModel | null {
    if (surveys.length === 0) {
      return null;
    }

    const latest = surveys.slice().sort((a, b) => {
      const dateA = new Date(a.asJson.date);
      const dateB = new Date(b.asJson.date);
      return dateB.getTime() - dateA.getTime();
    });
    return latest[0];
  }

  get latestSurvey() {
    const surveys = this.all;
    if (!surveys) return [];
    return this.getLatestSurvey(surveys);
  }
}

import  keycloak from "../keycloakConfig";
import { IPatient } from "../models/Patient";
import { FAILED_ACTION, SUCCESS_ACTION } from "../models/Snackbar";
import AppStore from "../stores/AppStore";
import UiStore from "../stores/UiStore";
import AppApi from "./AppApi";

export class PatientApi {
  constructor(
    private api: AppApi,
    private store: AppStore,
    public ui: UiStore
  ) { }

  private path() {
    return `${process.env.REACT_APP_API_ENDPOINT}/patient/`;
  }

  private headers() {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    _headers.append("Accept", "application/json");
    _headers.append("Authorization", `Bearer ${keycloak.token}`);
    return _headers;
  }

  async getuser() {
    const url = this.path();
    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: this.headers(),
      });
      if (response.ok) {
        const data: IPatient[] = await response.json();
        this.store.patient.load({
          ...data[0],
          username: this.store.patient.patientJson?.username,
        } as IPatient);
      }
    } catch (error) { }
  }

  async update(item: IPatient) {
    const url = this.path();
    const options = {
      method: 'PATCH',
      headers: this.headers(),
      body: JSON.stringify({ ...item }),
    };

    try {
      const response = await fetch(url + item.user_id + "/", {
        ...options,
        mode: "cors",
      });

      if (response.ok) {
        this.getuser()
        SUCCESS_ACTION(this.ui);
      } else {
        FAILED_ACTION(this.ui);
      }
    } catch (error) { }
  }
}

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import MODAL_NAMES from "../ModalName";
import { useTranslation } from "react-i18next";
import { hideModalFromId } from "../../../shared/components/functions/ModalShow";

interface IPDFProps {
  pdfBinaryData: Blob | null;
  setPdfBinaryData: React.Dispatch<React.SetStateAction<Blob | null>>
}

const ViewLabPDFDialog = observer((props: IPDFProps) => {
  const { pdfBinaryData, setPdfBinaryData } = props;
  const { t: msg } = useTranslation(['home']);

  const onCancel = () => {
    setPdfBinaryData(null);
    hideModalFromId(MODAL_NAMES.PATIENT.LAB_PDF_MODAL);
  };

  return (
    <div className="read-attachment-modal uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button
        className="uk-modal-close-full uk-close-large close"
        type="button"
        data-uk-close
        onClick={onCancel}
      ></button>
      <h3 className="uk-modal-title title">{"Results Document"}</h3>
      <div className="dialog-content uk-position-relative">
        {pdfBinaryData && <PdfViewer pdfBinaryData={pdfBinaryData} />}
        <div className="uk-text-right">
          <button
            className="btn btn-warning"
            type="button" onClick={onCancel}>
            {msg("cancelButton")}
          </button>
        </div>
      </div>
    </div>
  );
});

export default ViewLabPDFDialog;

interface IProps {
  pdfBinaryData: Blob;
}

const PdfViewer = (props: IProps) => {

  const { pdfBinaryData } = props;
  const [pdfObjectUrl, setPdfObjectUrl] = useState('');

  useEffect(() => {
    const pdfBlob = new Blob([pdfBinaryData], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(pdfBlob);
    setPdfObjectUrl(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [pdfBinaryData]);

  return (
    <div>
      <iframe
        className="read-document-frame"
        title={"Lab Result PDF Document"}
        src={pdfObjectUrl} width="100%" min-height="500px"
      />
    </div>
  );
};



import React, { useState, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import MainLayout from "./main-layout/MainLayout"
import Drawer from "./navigation/Drawer"
import Navbar from "./navigation/Navbar"
import MODAL_NAMES from "./dialogs/ModalName"
import Modal, { OffCanvasModal } from "../shared/components/Modal"
import { LoadingEllipsis } from "../shared/components/loading/Loading"
import { useAppContext } from "../shared/context/Context"
import keycloak from "../keycloakConfig"
import ContactsModal from "./dialogs/ContactsModal/ContactsModal"

const NotificationModal = React.lazy(() => import("./dialogs/NotificationModal/NotificationModal"))
const AccountAndPrivacyModal = React.lazy(() => import("./dialogs/account-and-privacy/AccountAndPrivacyModal"))

const LoggedInPatient = observer(() => {
  const { api, store } = useAppContext();
  const [fetchingData, setFetchingData] = useState(false);

  const user = store.patient.patientJson;
  const userId = user ? !!user.user_id && user.user_id !== null : false;
  const accountUrl = keycloak.createAccountUrl();

  function onLogout() {
    keycloak.logout();
  }

  const loadPatient = useCallback(async () => {
    setFetchingData(true)
    try {
      await api.patient.getuser();
    } catch (error) { }
    setFetchingData(false)
  }, [api.patient])

  const loadData = useCallback(async () => {
    try {
      await Promise.all([
        api.survey.getAll(),
        api.result.getAll(),
        api.notification.getAll(),
      ])
    } catch (error) { }
  }, [api.survey, api.result, api.notification])

  useEffect(() => {
    if (!userId) {
      loadPatient();
    } else {
      loadData();
    }
  }, [loadPatient, loadData, userId])

  return (
    <div>
      <div>

        {/* <Navbar onLogout={onLogout} accountUrl={accountUrl} />
        <div className="application-layout">
          <Drawer onLogout={onLogout} accountUrl={accountUrl} />
          <MainLayout fetchingData={fetchingData} />
        </div> */}


        {!fetchingData && userId ? (
          <div>
            <Navbar onLogout={onLogout} accountUrl={accountUrl} />
            <div className="application-layout">
              <Drawer onLogout={onLogout} accountUrl={accountUrl} />
              <MainLayout fetchingData={fetchingData} />
            </div>
          </div>
        ) : !userId && !fetchingData ? (
          <div className="uk-section uk-section-default uk-light uk-background-cover errorpage uk-text-center">
            <div className="uk-container">
              <p>If you are seeing this page, you logged in as admin or due to slow network.</p>
              <button onClick={onLogout} className="btn uk-button-danger">
                Logout
              </button>
            </div>
          </div>
        ) : (
          <LoadingEllipsis fullHeight />
        )}
      </div>
      <Modal modalId={MODAL_NAMES.PATIENT.ACCOUNT_AND_PRIVACY_MODAL}>
        <AccountAndPrivacyModal />
      </Modal>
      <OffCanvasModal modalId={MODAL_NAMES.PATIENT.NOTIFICATION_MODAL}>
        <NotificationModal />
      </OffCanvasModal>
      <OffCanvasModal modalId={MODAL_NAMES.PATIENT.CONTACTS_MODAL} closeOnBackgroundClick={true}>
        <ContactsModal />
      </OffCanvasModal>
    </div>
  )
})

export default LoggedInPatient
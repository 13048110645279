import useTitle from "../../shared/hooks/useTitle"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"

export const Shop = observer(() => {
  useTitle("Shop")
  const { t: msg } = useTranslation(["home"])

  const eshopUrl = `${process.env.REACT_APP_ESHOP_URL}`
  const eshopOrderUrl = `${process.env.REACT_APP_ESHOP_TRACK_ORDER_URL}`

  return (
    <div className="uk-section profile">
      <div className="uk-container uk-container-large">
        <h3 className="profile-title">{msg("orderNFL")}</h3>
        <div className="profile-card uk-card uk-card-default uk-card-body">
          <div>
            <h5 className="uk-text-bold uk-text-uppercase">{msg("whenToOrderNFLKIT")}</h5>
            <p className="text">{msg("nflExplained")}</p>
          </div>
          <div className="uk-padding-small" style={{ backgroundColor: "#DBECF7" }}>
            <p className="text uk-text-bold uk-text-italic uk-margin-remove-bottom">{msg("pleaseNote")}</p>
            <p className="text uk-text-italic uk-margin-remove-top">{msg("whenToOrderAnswer")}</p>
          </div>
          <div className="uk-grid uk-grid-small uk-margin" data-uk-grid>
            <div>
              <a className="btn btn-primary link" type="button" href={eshopUrl} target="_blank" rel="origin-when-cross-origin noreferrer">
                {msg("proceedToOrderButton")}
              </a>
            </div>
            <div>
              <a className="btn btn-primary link" type="button" href={eshopOrderUrl} target="_blank" rel="noreferrer">
                {msg("orderStatusButton")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export const defaultLabResult: ILabResult = {
  id: 0,
  patient_id: 0,
  date: "",
  nfl_value: "",
  date_of_measurement: "",
  date_of_sample_reception: "",
  date_of_sample_collection: "",
  sample_id: "",
  comment: ""
};

export interface ILabResult {
  id: number;
  patient_id: 0;
  nfl_value: string;
  date_of_measurement: string | "2023-11-10T11:04:05.094Z";
  date_of_sample_reception: string | "2023-11-10T11:04:05.094Z";
  date_of_sample_collection: string | "2023-11-10T11:04:05.094Z";
  sample_id: string;
  comment: string;
  date: string;
}

export default class LabResultModel {
  private result: ILabResult;

  constructor(private store: AppStore, result: ILabResult) {
    makeAutoObservable(this);
    this.result = result;
  }

  get asJson(): ILabResult {
    return toJS(this.result);
  }
}

import { useNavigate } from "react-router-dom";
import { englishTermsAndConditions } from "../locales/terms-and-conditions/englishTermsAndConditions";
import { germanTermsAndConditions } from "../locales/terms-and-conditions/germanTermsAndConditions";
 import { italianTermsAndConditions } from "../locales/terms-and-conditions/italianTermsAndConditions";
import { useEffect, useState } from "react";
import Asset1 from '../images/Asset1.svg';
import i18next from "../locales/config";
import { useTranslation } from "react-i18next";
import { languages } from "../locales/laguages";
import  keycloak from "../keycloakConfig";

const GeneralTermsConditionsPage = () => {

  const [data, setData] = useState(englishTermsAndConditions);
  const { t: msg } = useTranslation(['home']);
  useEffect(() => {
    const getData = () => {
      switch (i18next.language) {
        case 'en':
          return englishTermsAndConditions;
        case 'de':
          return germanTermsAndConditions;
        case 'it':
          return italianTermsAndConditions;
        default:
          return englishTermsAndConditions;
      }
    };
    setData(getData());
  }, [msg]);

  const navigate = useNavigate();
  const onButtonClick = (url: string) => {
    navigate(url);
  }

  return (
    <div className="uk-section reads">
      <div className="uk-container uk-container-large">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="uk-text-right">
            {!keycloak.authenticated && <LoggedOutContentLaguageSwitcher />}
            <div className="app-image">
              <img className="logo" src={Asset1} alt="" />
            </div>
          </div>
          <h4>{data.termsofuse.title}</h4>
          <div className="dialog-content uk-position-relative">
            <div>
              <h5>{data.termsofuse.semi}</h5>
              <p className="text">{data.termsofuse.description}</p>
            </div>

            <h5>{data.applicationOfSubject.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.applicationOfSubject.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

            <h5>{data.conclusionOfContract.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.conclusionOfContract.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

            <h5>{data.scopeOfService.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.scopeOfService.list.map((item, index) => (
                <li className="text" key={index}>
                  {item.data}
                  {item.subList && (
                    <ul className="uk-list uk-list-disc">
                      {item.subList.map((subItem, subIndex) => (
                        <li className="text" key={subIndex}>{subItem.data}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>

            <h5>{data.accessRequirements.title}</h5>
            <p className="text" dangerouslySetInnerHTML={{ __html: data.accessRequirements.description }} />

            <h5>{data.revocation.title}</h5>
            <p className="text" dangerouslySetInnerHTML={{ __html: data.revocation.description }} />

            <h5>{data.costOfDelivery.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.costOfDelivery.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{data.liability.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.liability.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{data.dataPrivacy.title}</h5>
            <p className="text" dangerouslySetInnerHTML={{ __html: data.dataPrivacy.description }} />


            <h5>{data.changesToTerms.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.changesToTerms.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

            <h5>{data.applicableLaw.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.applicableLaw.list.map((item, index) => (
                <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
              ))}
            </ul>

            <h5>{data.finalProvisions.title}</h5>
            <ul className="uk-list uk-list-decimal">
              {data.finalProvisions.list.map((item, index) => (
                <li className="text" key={index}>{item.data} </li>
              ))}
            </ul>

          </div>
          <div className="uk-text-right">
            <button
              className="btn btn-primary"
              type="button" onClick={() => onButtonClick("/")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralTermsConditionsPage;


export const LoggedOutContentLaguageSwitcher = (): JSX.Element => {
  const { i18n } = useTranslation(['home']);
  return (
    <div className="uk-margin">
      <select className="uk-select uk-form-small uk-border-rounded" aria-label="Select" style={{ height: "35px", width: "80px", }}
        onChange={(e) => i18n.changeLanguage(e.target.value)}>
        {languages.map((lang) => (
          <option key={lang.value} value={lang.value}>{lang.label}</option>
        ))}
      </select>
    </div>
  )
}
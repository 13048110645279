import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export interface INotification {
  id: number;
  message: string;
  date: string;
  unread: boolean;
}

export default class NotificationModel {
  private noti: INotification;

  constructor(private store: AppStore, noti: INotification) {
    makeAutoObservable(this);
    this.noti = noti;
  }

  get asJson(): INotification {
    return toJS(this.noti);
  }
}

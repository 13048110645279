import { MainApp } from "../models/App";
import LabResultStore from "./LabResultStore";
import NotificationStore from "./NotificationStore";
import PatientStore from "./PatientStore";
import SurveyStore from "./SurveyStore";

export default class AppStore {
  app: MainApp;
  survey = new SurveyStore(this);
  result = new LabResultStore(this);
  patient = new PatientStore(this);
  notification = new NotificationStore(this);

  constructor(app: MainApp) {
    this.app = app;
  }
}

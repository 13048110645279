import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { englishrevocation } from "../locales/revocation/englishrevocation";
import Asset1 from '../images/Asset1.svg';
import i18next from "../locales/config";
import { germanrevocation } from "../locales/revocation/germanrevocation";
 import { italianrevocation } from "../locales/revocation/italianrevocation";
import { useTranslation } from "react-i18next";
import { LoggedOutContentLaguageSwitcher } from "./GeneralTermsConditionsPage";
import  keycloak from "../keycloakConfig";

const RevocationPage = () => {
  const { t: msg } = useTranslation(['home']);
  const [data, setData] = useState(englishrevocation);
  useEffect(() => {
    const getData = () => {
      switch (i18next.language) {
        case 'en':
          return englishrevocation;
        case 'de':
          return germanrevocation;
        case 'it':
          return italianrevocation;
        default:
          return englishrevocation;
      }
    };
    setData(getData());
  }, [msg]);

  const navigate = useNavigate();
  const onButtonClick = (url: string) => {
    navigate(url);
  }

  return (
    <div className="uk-section reads">
      <div className="uk-container uk-container-large">
        <div className="uk-card uk-card-default uk-padding-small">
          <div className="uk-text-right">
          {!keycloak.authenticated && <LoggedOutContentLaguageSwitcher />}
            <div className="app-image">
              <img className="logo" src={Asset1} alt="" />
            </div>
          </div>
          <h4>{data.title}</h4>
          <div>
            <div>
              <h5>{data.subtitle}</h5>
            </div>
            <div>
              <h5>{data.policy.title}</h5>
              <h5>{data.policy.subtitle}</h5>
              <p className="text" dangerouslySetInnerHTML={{ __html: data.policy.description }} />
            </div>
            <div>
              <h5>{data.consequences.title}</h5>
              <h5>{data.consequences.subtitle}</h5>
              <p className="text">{data.consequences.description}</p>
            </div>
            <div>
              <h5>{data.expiryofrevocation.title}</h5>
              <h5>{data.expiryofrevocation.subtitle}</h5>
              <p className="text">{data.expiryofrevocation.description}</p>
            </div>
            <div>
              <h5>{data.expiryofrevocation.title}</h5>
              <h5>{data.expiryofrevocation.subtitle}</h5>
              <p className="text">{data.expiryofrevocation.description}</p>
            </div>
            <div>
              <h5>{data.samplerevocation.title}</h5>
              <p className="text">{data.samplerevocation.description}</p>

              <ul className="uk-list">
                {data.samplerevocation.list.map((item, index) => (
                  <li className="text" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
                ))}
              </ul>
              <form>
                <div className="uk-margin">
                  <label className="uk-form-label" >
                     {data.samplerevocation.labelOne}
                  </label>
                  <div className="uk-form-controls">
                    <input className="uk-input" type="text" disabled />
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {data.samplerevocation.labelTwo}
                  </label>
                  <div className="uk-form-controls">
                    <input className="uk-input" type="text" disabled />
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label"> {data.samplerevocation.labelThree}
                  </label>
                  <div className="uk-form-controls">
                    <input className="uk-input" type="text" disabled />
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {data.samplerevocation.labelFour}</label>
                  <textarea className="uk-textarea" rows={5} aria-label="Textarea" disabled></textarea>
                </div>
                <div className="uk-margin-large-top">
                  <label className="uk-form-label">
                    {data.samplerevocation.labelFive}
                  </label>
                  <hr />
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label">{data.samplerevocation.labelSix}
                  </label>
                  <hr />
                </div>
                <div className="uk-margin-large-top">
                  <hr />
                  <label className="uk-form-label">
                    {data.samplerevocation.labelSeven}</label>
                </div>
              </form>
            </div>
          </div>
          <div className="uk-text-right">
            <button
              className="btn btn-primary"
              type="button" onClick={() => onButtonClick("/")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevocationPage;


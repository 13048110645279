import { observer } from "mobx-react-lite";
import { useAppContext } from "../../shared/context/Context";
import MODAL_NAMES from "../dialogs/ModalName";
import { languages } from "../../locales/laguages";
import Dropdown from "../../shared/components/dropdown/Dropdown";
import showModalFromId, { showOffcanvasFromId } from "../../shared/components/functions/ModalShow";
import icons from "../../shared/components/utils/icons";
import { useNavigate } from "react-router-dom";
import Asset1 from '../../images/Asset1.svg';
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import keycloak from "../../keycloakConfig";

interface IProps {
  onLogout(): void
  accountUrl: string;
}

const Navbar = observer((props: IProps) => {

  const { onLogout, accountUrl } = props;
  const { api, store } = useAppContext();
  const { t: msg, i18n } = useTranslation(['home']);

  const navigate = useNavigate()

  const user = store.patient.patientJson;
  const firstName = user ? user.first_name : " ";
  const lastName = user ? user.last_name : " ";

  const onGTC = () => {
    navigate("/general-terms-and-conditions");
  }

  const onRegistrationPrivacy = () => {
    showModalFromId(MODAL_NAMES.PATIENT.ACCOUNT_AND_PRIVACY_MODAL)
  }

  const viewNotification = async () => {
    showOffcanvasFromId(MODAL_NAMES.PATIENT.NOTIFICATION_MODAL);
    store.notification.all.forEach(async (note) => (
      await api.notification.update(note.asJson)
    ));
  }

  function onChangeLang(e: ChangeEvent<HTMLSelectElement>) {
    i18n.changeLanguage(e.target.value);
    window.localStorage.setItem('lng', e.target.value);
  }

  return (
    <div className="sticky" data-uk-sticky="sel-target: .uk-navbar; cls-active: uk-navbar-sticky">
      <nav className="uk-navbar-container">
        <div className="lg-navbar" data-uk-navbar>
          <div className="uk-navbar-left">
            <div className="uk-navbar-item uk-logo uk-visible@m">
              <div className="account-settings">
                <img alt="icon" src={Asset1} />
              </div>
            </div>
            <button
              title=""
              className="uk-navbar-toggle uk-hidden@m"
              data-uk-navbar-toggle-icon
              data-uk-toggle="target: #apstnavbardrawer"
            />
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav uk-visible@s">
              <li className="btn-li-item">
                <p style={{ marginTop: "35px" }}>
                  <select
                    className="uk-select uk-form-medium"
                    aria-label="Select"
                    style={{ height: "30px" }}
                    onChange={onChangeLang}
                    value={i18n.language}>
                    {languages.map((lang) => (
                      <option key={lang.value} value={lang.value}>{lang.label}</option>
                    ))}
                  </select>
                </p>
              </li>
              <li className="avartar-li-item">
                <p className="avartar-username">
                  <span className="name">{firstName}{" "}{lastName}</span><br />
                  {keycloak.authenticated &&
                    <button style={{ marginTop: 0, marginLeft: 0 }} className="btn btn-primary" onClick={onLogout}>
                      <img alt="icon" src={icons.logout} className="icon" />
                      {msg('signOutButton')}
                    </button>
                  }
                </p>
                {store.notification.all.length === 0 &&
                  <div>
                    <button className="user-avartar">
                      <img alt="icon" src={icons.account_circle} className="icon" />
                    </button>
                    <Dropdown>
                      <li className="uk-margin">
                        <button className="btn-text uk-button uk-button-text">
                          <a href={accountUrl} target="_blank" rel="noreferrer">{msg('accountSecurity')}</a>
                        </button>
                      </li>
                      <li>
                        <button className="btn-text uk-button uk-button-text" onClick={onRegistrationPrivacy}>
                          <p className="text-link"> {msg('accountAndDataPrivacy')}</p>
                        </button>
                      </li>
                      <li>
                        <button className="btn-text uk-button uk-button-text" onClick={onGTC}>
                          <p className="text-link">{msg('gtcLink')}</p>
                        </button>
                      </li>
                    </Dropdown>
                  </div>
                }
                {store.notification.all.length !== 0 &&
                  <div className="notification-box" onClick={viewNotification}>
                    <span className="notification-count">{store.notification.all.length}</span>
                    <div className="notification-bell">
                      <span className="bell-top"></span>
                      <span className="bell-middle"></span>
                      <span className="bell-bottom"></span>
                      <span className="bell-rad"></span>
                    </div>
                  </div>
                }
              </li>
            </ul>
            <ul className="uk-navbar-nav uk-text-right">
              <div className="uk-navbar-item uk-hidden@s">
                <p className="uk-margin-right" style={{ marginTop: "20px" }}>
                  <select
                    className="uk-select uk-form-medium"
                    aria-label="Select" style={{ height: "30px" }}
                    onChange={onChangeLang}
                    value={i18n.language}>
                    {languages.map((lang) => (
                      <option key={lang.value} value={lang.value}>{lang.label}</option>
                    ))}
                  </select>
                </p>
                {store.notification.all.length !== 0 &&
                  <div className="notification-box" onClick={viewNotification}>
                    <span className="notification-count">{store.notification.all.length}</span>
                    <div className="notification-bell">
                      <span className="bell-top"></span>
                      <span className="bell-middle"></span>
                      <span className="bell-bottom"></span>
                      <span className="bell-rad"></span>
                    </div>
                  </div>
                }
                {store.notification.all.length === 0 &&
                  <div className="account-settings">
                    <img alt="icon" src={Asset1} />
                  </div>
                }
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
});

export default Navbar;

export const italiantermsofuse = {
  termsofuse: {
    title: "Termini di utilizzo dell'app ALS Analytics",
    semi: "Le presenti Condizioni d'uso sono state aggiornate l'ultima volta il 1° novembre 2023",
    description: `APST Research GmbH ("APST Research") ha sviluppato una piattaforma/app di ricerca sulla SLA
     ("App ALS Analytics"). APST Research fornisce l'app ALS Analytics agli utenti interessati che
      soffrono di sclerosi laterale amiotrofica (“Utenti”). Nell'app ALS Analytics, gli utenti possono
       utilizzare la scala di valutazione funzionale ALS rivista (ALSFRSr) ("punteggio di valutazione funzionale ALS")
        per registrare lo stato della loro malattia e valutarne la progressione.
         Inoltre gli utenti possono determinare il biomarcatore NfL (catena leggera dei neurofilamenti) ("valore NfL")
          prelevando un campione di sangue e ottenendo l'analisi della progressione dopo aver valutato il NfL
           valore visualizzato nell'app ("analisi NfL").`,
  },
  applicationOfSubject: {
    title: "1. Applicazione e oggetto delle Condizioni d'Uso",
    list: [
      {
        data: `I seguenti termini di utilizzo ("Termini di utilizzo") si applicano all'utilizzo iniziale e futuro dell'App ALS Analytics e dei contenuti, servizi e funzioni ivi offerti (di seguito collettivamente denominati "Servizi"). Per quanto riguarda i Servizi, occorre distinguere tra il contenuto gratuito dell'App ALS Analytics, che comprende la determinazione dell'ALS Functional Rating Score e altre informazioni e contenuti rilevanti ("Servizi gratuiti"), e l'offerta a pagamento per determinare e valutare il valore NF-L e visualizzare l'analisi NfL ("Servizio a pagamento").`,
      },
      {
        data: "Registrandosi nell'app ALS Analytics e utilizzando i servizi, l'utente conferma di accettare i termini di utilizzo e di concordarne la validità. La registrazione e l'utilizzo dei servizi non sono possibili senza l'accettazione delle Condizioni d'Uso.",
      },
    ],
  },
  conclusionOfContract: {
    title: "2. Conclusione del contratto",
    list: [
      {
        data: `Al momento della registrazione dell'account utente, l'utente deve accettare la validità delle Condizioni d'uso e dare il proprio consenso ai sensi della legge sulla protezione dei dati. Il contratto per l'utilizzo dell'app ALS Analytics e dei servizi non è concluso finché APST Research non ha inviato la conferma della registrazione via e-mail. Facendo clic sul pulsante "Andiamo", l'utente presenta un'offerta per la conclusione di un contratto per l'utilizzo dell'app e dei servizi ALS Analytics. Subito dopo l'invio dell'offerta, l'utente riceverà la conferma della propria registrazione. Fatta salva la frase 1, la conferma equivale anche all'accettazione della proposta contrattuale per l'utilizzo dell'app ALS Analytics e dei servizi gratuiti.`,
      },
      {
        data: `Il contratto per la fruizione del servizio a pagamento è soggetto all'art <a className="uk-text-primary gtc-text" href="${process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"}">Termini generali e condizioni</a> per l'analisi NfL.`,
      },
      {
        data: `L'utente può stampare o salvare le Condizioni d'Uso utilizzando la funzione del browser. L'utente può anche scaricare e archiviare le Condizioni d'uso in formato PDF come parte del processo di registrazione. Al momento della conferma della registrazione, APST Research trasmetterà all'utente le Condizioni d'Uso nella loro versione attuale. APST Research non memorizza i termini di utilizzo.`,
      },
    ],
  },
  scopeOfService: {
    title: "3. Entità dei servizi, modifiche ai servizi",
    list: [
      {
        data: "L'app e i servizi ALS Analytics sono offerti tramite un'applicazione Web basata su browser e come app nativa iOS o Android a cui è possibile accedere tramite Internet.",
      },
      {
        data: "L'ambito dell'App ALS Analytics e i servizi disponibili per l'utente dipendono dai servizi che APST Research rende disponibili nell'App ALS Analytics. I servizi forniti possono essere:",
        subList: [
          {
            data: "Fornitura della scala funzionale ALS per determinare il punteggio di valutazione funzionale ALS. Si tratta di un questionario composto da dodici domande sulle funzioni della testa, delle braccia, delle gambe, del tronco e della respirazione, ciascuna con cinque sottodomande, le cui risposte sono valutate su una scala da 0 a 4. Ciò si traduce in un punteggio totale compreso tra 48 e 0 punti, il punteggio di valutazione funzionale ALS, che consente alle persone affette da SLA di valutare da sole la progressione della loro malattia. Il punteggio di progressione nell'app ALS Analytics offre la possibilità di classificare la velocità di progressione della malattia come veloce, lenta o media. Serve come guida per l'utente.",
          },
          {
            data: `Fornitura a pagamento della funzione per la determinazione del biomarcatore NfL mediante un campione di sangue. Gli utenti possono utilizzare questa funzione per valutare il proprio valore NF-L e visualizzare l'analisi della tendenza risultante nell'app. I dettagli di questa funzione sono definiti nel <a className="uk-text-primary gtc-text" href="${
              process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"
            }">Termini generali e condizioni</a> per l'analisi NfL.`,
          },
        ],
      },
      {
        data: "APST Research sviluppa continuamente l'App ALS Analytics, i Servizi, il software sottostante e i relativi contenuti. APST Research si riserva il diritto di implementare in qualsiasi momento aggiornamenti e nuove versioni per migliorare l'App ALS Analytics e i Servizi. APST Research può apportare modifiche di qualsiasi tipo all'App ALS Analytics o ai Servizi o al modo in cui l'App ALS Analytics o i Servizi vengono forniti. APST Research si riserva il diritto di cambiare o modificare la funzionalità dell'App ALS Analytics e dei Servizi in qualsiasi momento o di interromperli in tutto o in parte, di interrompere o rimuovere i Servizi o di limitare l'accesso ai Servizi. APST Research informerà l'utente di qualsiasi compromissione non trascurabile dell'accessibilità o dell'usabilità dell'App ALS Analytics o dei servizi entro un termine ragionevole prima del momento della modifica. APST Research non è responsabile per eventuali danni diretti o indiretti subiti dall'utente a seguito del cambiamento, modifica, cessazione o limitazione dell'app o dei servizi ALS Analytics.",
      },
      {
        data: "APST Research sottolinea che l'App ALS Analytics e i Servizi non sono un dispositivo medico.",
      },
    ],
  },
  accessRequirements: {
    title: "4. Requisiti di accesso, account utente",
    list: [
      {
        data: "L'utente è tenuto a fornire tutto il software e l'hardware necessari per accedere all'App ALS Analytics e ai Servizi, come un dispositivo terminale e una connessione Internet. Potrebbe essere necessario scaricare o installare software aggiuntivo (ad esempio alcuni plug-in software o applicazioni software) per accedere a determinati servizi o documenti. Per utilizzare l'app nativa, l'utente deve scaricare l'applicazione tramite il provider dell'app store Google Play Store o l'Apple Store.",
      },
      {
        data: "Per poter utilizzare i servizi l'utente deve essere registrato con un account utente e loggato nell'app ALS Analytics. Per una nuova registrazione sono necessari l'indirizzo e-mail e la password dell'utente, nonché il consenso dell'utente ai sensi della legge sulla protezione dei dati. Una volta completata la registrazione, l'utente può effettuare il login, fornire ulteriori informazioni su se stesso (come età, sesso e insorgenza della malattia) e accedere all'app ALS Analytics e ai servizi adatti a lui.",
      },
      {
        data: "There are more than 500 characters with translation, please reduce the translation content. L'account utente è personale e può essere utilizzato solo personalmente dall'utente. I dati di accesso all'account utente devono essere mantenuti segreti e qualsiasi accesso da parte di terzi deve essere impedito mediante misure adeguate. L'utente è responsabile di tutte le attività svolte tramite il suo account utente ed è responsabile nei confronti di APST Research secondo le disposizioni di legge. APST Research non è responsabile per eventuali danni subiti dall'utente a causa dell'utilizzo da parte di terzi dei dati di accesso o dell'account utente con o senza la conoscenza dell'utente.",
      },
    ],
  },
  obligationsOfUser: {
    title: "5. Obblighi dell'utente.",
    list: [
      {
        data: "L'utente può utilizzare l'app ALS Analytics solo per lo scopo per il quale è stato concesso l'accesso ai servizi. Qualsiasi altro utilizzo dell'App ALS Analytics e dei servizi è vietato.",
      },
      {
        data: "In particolare, l'utente non può pubblicare nell'App ALS Analytics alcun contenuto che possa essere punibile dalla legge (in particolare incitamento all'odio, insulti, diffamazione, minacce), che sia pornografico, volgare o osceno, molesto o altrimenti offensivo, che sia anticostituzionale, estremista, razzista o xenofobo, o che rappresenti contenuti provenienti da gruppi vietati, che violi i diritti di terzi (in particolare diritti della persona, diritti d'autore, diritti di marchio, diritti di brevetto o altri diritti di terzi).",
      },
      {
        data: "L'utente è tenuto a utilizzare l'App ALS Analytics e i servizi in modo tale che non si verifichino impedimenti, sovraccarichi o danni e lo scopo perseguito con l'App ALS Analytics e i servizi non venga compromesso o eluso. L'utente non dovrà eludere o modificare, né direttamente né tramite terzi, le precauzioni di sicurezza dell'app ALS Analytics.",
      },
    ],
  },
  blockingOfAccount: {
    title: "6. Blocco dell'account utente",
    list: [
      {
        data: "APST Research si riserva il diritto, a sua esclusiva discrezione, di interrompere o limitare l'accesso dell'utente a qualsiasi parte dell'App ALS Analytics o dei Servizi in qualsiasi momento e senza preavviso.",
      },
      {
        data: "In particolare, APST Research ha il diritto di bloccare o cancellare un account utente se l'utente viola un obbligo previsto dalle presenti Condizioni d'Uso o se APST Research ha motivo di sospettare che tale violazione di obbligo si sia verificata.",
      },
    ],
  },
  rightsOfUser: {
    title: "7. Diritti d'uso dell'utente",
    list: [
      {
        data: "Tutti i diritti sull'App ALS Analytics e sui servizi, inclusi i diritti sui relativi software, database, grafica, interfacce utente, design e altri contenuti, designazioni, nomi e marchi, rimarranno di proprietà di APST Research o di eventuali terze parti che hanno concesso ad APST Research diritti in relazione all'App ALS Analytics o ai servizi.",
      },
      {
        data: "L'utente riceve un diritto semplice, non trasferibile e limitato nel tempo alla durata del contratto d'uso, di utilizzare l'app ALS Analytics e i servizi per lo scopo previsto. L'utente può utilizzare l'app ALS Analytics e i servizi solo per scopi privati. L'utente non gode di alcun diritto che non gli sia espressamente concesso.",
      },
      {
        data: "In particolare, l'utente non può copiare, tradurre, modificare, decodificare, decompilare o distribuire l'App ALS Analytics e i servizi o qualsiasi parte di essi, compreso il codice sorgente o qualsiasi copia, adattamento, trascrizione o parte accorpata degli stessi. L'utente non è autorizzato a trasferire, affittare, cedere, affittare o concedere in sublicenza i diritti concessigli dalle presenti Condizioni d'uso o a rendere i servizi disponibili per l'utilizzo da parte di altre persone.",
      },
    ],
  },
  rightsOfResponsibility: {
    title: "8. Diritti d'uso e responsabilità per i contenuti pubblicati dall'utente",
    list: [
      {
        data: "Caricando contenuti (come immagini, testo, materiale audio e video) sull'App ALS Analytics, l'utente concede ad APST Research un diritto non esclusivo, revocabile, esente da royalty e cedibile in sublicenza perpetuo di utilizzare il contenuto per gli scopi per i quali è è stato caricato sull'app ALS Analytics, in particolare per copiare, trasmettere, riprodurre pubblicamente, eseguire pubblicamente, riprodurre, modificare, tradurre e riformattare il contenuto.",
      },
      {
        data: "Pubblicando contenuti, l'utente dichiara e garantisce di essere il proprietario di tutti i diritti sui contenuti necessari per pubblicare i contenuti e di concedere ad APST Research i diritti di utilizzo ai sensi delle presenti Condizioni d'Uso.",
      },
      {
        data: "Non è possibile per APST Research controllare i contenuti pubblicati dagli utenti nell'app ALS Analytics. Ciò vale in particolare per quanto riguarda l'accuratezza dei contenuti, la loro legalità, attualità e qualità, nonché la loro idoneità per uno scopo specifico. APST Research non è responsabile dei contenuti pubblicati dagli utenti.",
      },
    ],
  },
  costs: {
    title: "9. Costi",
    list: [
      {
        data: `L'utente non dovrà sostenere alcun costo per la registrazione di un account utente e l'utilizzo dell'app ALS Analytics e dei servizi gratuiti ai sensi dei paragrafi 3.2.1 e 3.2.2. Per l'utilizzo dei servizi a pagamento secondo il punto 3.2.3 l'utente dovrà sostenere i costi specificati nelle <a className="uk-text-primary gtc-text" href="${
          process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"
        }">Termini generali e condizioni</a> per l'analisi NfL.`,
      },
      {
        data: `La registrazione e l'utilizzo dell'App ALS Analytics e dei servizi richiedono il conferimento dei dati personali dell'utente e il consenso al trattamento ai sensi della normativa sulla protezione dei dati. Le informazioni sul trattamento dei dati personali da parte di APST Research sono reperibili nella <a className="uk-text-primary gtc-text" href="${
          process.env.REACT_APP_FRONTEND_URL + "data-privacy-policy"
        }">Privacy dei dati</a>informazione.`,
      },
    ],
  },
  revocationsAndTermination: {
    title: "10. Revoca, risoluzione, cancellazione dell'account utente",
    list: [
      {
        data: "In quanto consumatore, l'utente dispone del diritto di revoca previsto dalla legge. Si applica la politica di revoca.",
      },
      {
        data: `L'utente ha la possibilità di cancellare in qualsiasi momento il proprio account utente e di disdire il contratto d'uso facendo clic sul pulsante di disdetta nel proprio account utente alla voce "Impostazioni". L'utente riceverà una e-mail da APST Research con la conferma della risoluzione inviata all'indirizzo di posta elettronica fornito ad APST Research. Con la rescissione e la cancellazione dell'account utente terminano il contratto d'uso e il diritto dell'utente di utilizzare l'app e i servizi ALS Analytics.`,
      },
      {
        data: "Se l'utente revoca il consenso da lui concesso ai sensi della legge sulla protezione dei dati o si oppone all'ulteriore trattamento dei suoi dati personali, APST Research può risolvere il contratto d'uso senza rispettare il termine di preavviso. Si applica di conseguenza il paragrafo 10.2 frasi 2 e 3.",
      },
    ],
  },
  noWarranty: {
    title: "11. Nessuna garanzia",
    list: [
      {
        data: "APST Research si impegnerà sempre a garantire il corretto funzionamento dell'app ALS Analytics e dei servizi, ma non sarà responsabile nei confronti dell'utente per la continua accessibilità, disponibilità e funzionalità dell'app ALS Analytics e dei servizi.",
      },
      {
        data: "APST Research ha il diritto di adottare misure che incidono sull'accesso all'App ALS Analytics o ai Servizi se ciò è necessario, ad esempio, per motivi tecnici, di manutenzione o di sicurezza.",
      },
      {
        data: "In particolare, APST Research non è responsabile nei confronti dell'utente per problemi di prestazioni o disponibilità:",
        subList: [
          {
            data: "A causa di fattori fuori dal controllo di APST Research (ad esempio disastri naturali, guerre, attacchi terroristici, rivolte o misure governative),",
          },
          {
            data: "Causato da servizi, hardware o software dell'utente o di terzi,",
          },
          {
            data: "Causato dall'utilizzo di un servizio dopo che APST Research ha dato istruzioni all'utente di modificare o aggiornare l'utilizzo di un servizio e l'utente non ha modificato o aggiornato l'utilizzo come indicato,",
          },
          {
            data: "Durante i servizi pre-release, beta e test (come stabilito da APST Research), ",
          },
          {
            data: "Causato da un atto o un'omissione non autorizzata dell'utente o di altre persone che sono riuscite ad accedere alla rete APST Research utilizzando i dati o i dispositivi di accesso dell'utente,",
          },
          {
            data: "Causato dall'incapacità dell'utente di mantenere le configurazioni richieste, utilizzare le piattaforme supportate e rispettare le politiche di utilizzo accettabili; O",
          },
          {
            data: "Si basano sul blocco dell'account utente ai sensi del punto 6.",
          },
        ],
      },
    ],
  },
  liability: {
    title: "12. Responsabilità",
    list: [
      {
        data: "Nonostante le seguenti limitazioni di responsabilità, APST Research sarà responsabile senza limitazioni per danni alla vita, al corpo e alla salute derivanti da una violazione negligente o intenzionale dei doveri da parte di APST Research, dei suoi rappresentanti legali o agenti ausiliari, nonché per danni che sono coperti da responsabilità ai sensi della legge sulla responsabilità del prodotto, nonché per danni derivanti da violazioni contrattuali intenzionali o gravemente negligenti e da intenti fraudolenti da parte di APST Research, dei suoi rappresentanti legali o agenti ausiliari.",
      },
      {
        data: "In caso di negligenza, la responsabilità di APST Research sarà limitata al risarcimento dei danni tipicamente prevedibili in relazione all'utilizzo dell'App ALS Analytics e dei Servizi. In caso di negligenza lieve, tuttavia, APST Research sarà responsabile solo se APST Research ha violato un obbligo il cui adempimento era essenziale per il corretto utilizzo dell'ALS Analytics App e dei servizi, tenendo conto degli interessi di entrambe le parti. , e sul cui adempimento l'utente aveva diritto di fare affidamento.",
      },
      {
        data: "APST Research non è inoltre responsabile di malfunzionamenti e perdita di qualità dell'App ALS Analytics e dei servizi derivanti dalla trasmissione di dati su Internet per i quali APST Research non è responsabile e che rendono più difficile o impossibile l'utilizzo dei servizi basati su Internet. .",
      },
      {
        data: "È esclusa ogni ulteriore responsabilità di APST Research indipendentemente dalla natura giuridica della pretesa fatta valere. Nella misura in cui la responsabilità di APST Research è esclusa o limitata, ciò si applica anche alla responsabilità personale dei suoi dipendenti, lavoratori, collaboratori, rappresentanti e agenti ausiliari.",
      },
    ],
  },

  dataPrivacy: {
    title: "13. Riservatezza dei dati",
    description: `APST Research desidera sottolineare che i dati personali dell'utente vengono trattati durante l'utilizzo dell'app ALS Analytics. APST Research rispetta le normative applicabili sulla protezione dei dati. Le informazioni sulle modalità di trattamento dei dati personali sono reperibili nell'art <a className="uk-text-primary gtc-text" target="_blank" href="${
      process.env.REACT_APP_FRONTEND_URL + "data-privacy-policy"
    }">Privacy dei dati</a> informazione.`,
  },

  applicableLaw: {
    title: "14. Legge applicabile, foro competente, risoluzione delle controversie",
    list: [
      {
        data: "Le presenti Condizioni d'uso, il loro oggetto e la loro formazione (così come tutte le controversie o reclami extracontrattuali) sono regolati dalle leggi della Repubblica Federale Tedesca, esclusa la Convenzione delle Nazioni Unite sui contratti di vendita internazionale di merci (CISG ), a meno che la legge imperativa sulla tutela dei consumatori non prescriva diversamente.",
      },
      {
        data: "Il foro competente è Berlino se l'utente è un commerciante ai sensi del Codice commerciale tedesco, una persona giuridica di diritto pubblico o un fondo speciale di diritto pubblico. Lo stesso vale per gli utenti che non hanno un foro competente generale in Germania o in un altro Stato membro dell'UE o che hanno spostato la loro residenza all'estero dopo la conclusione del contratto o il cui luogo di residenza o dimora abituale non è noto al momento della conclusione del contratto. l'azione è depositata. In caso contrario, alla giurisdizione locale e internazionale si applicano le disposizioni di legge applicabili.",
      },
      {
        data: `La Commissione Europea fornisce una piattaforma per la risoluzione delle controversie online (OS). Puoi trovare il link alla piattaforma qui: <a className="uk-text-primary gtc-text" target="_blank" href="https://ec.europa.eu/consumers/odr/">ec. europa.eu/consumers/odr/</a>. APST Research non è disposta né obbligata a partecipare a procedimenti di risoluzione delle controversie dinanzi a un collegio arbitrale dei consumatori.`,
      },
    ],
  },

  invalidityOfIndividual: {
    title: "15. Invalidità di singole disposizioni, omissioni contrattuali",
    list: [
      {
        data: "Qualora singole disposizioni delle presenti Condizioni d'uso fossero o diventassero invalide o inapplicabili, ciò non pregiudicherà la validità delle restanti Condizioni d'uso. La disposizione invalida o inapplicabile sarà sostituita dalle pertinenti disposizioni di legge. Se nel caso specifico non sono disponibili disposizioni di legge, la disposizione efficace o applicabile sostituirà la disposizione inefficace o inapplicabile, i cui effetti si avvicinano di più all'obiettivo che APST Research e l'utente perseguono con la norma inefficace o inapplicabile.",
      },
      {
        data: "La clausola 15.1 si applicherà di conseguenza nel caso in cui una o più condizioni risultino incomplete.",
      },
    ],
  },

  changesToTerms: {
    title: "16. Modifiche alle condizioni di utilizzo",
    list: [
      {
        data: "APST Research si riserva il diritto di modificare o integrare le presenti Condizioni d'Uso in qualsiasi momento senza fornire motivazioni con effetto per il futuro se:",
        subList: [
          {
            data: "Ciò è necessario per adattarsi a mutate circostanze di cui APST Research non è responsabile (in particolare modifiche legislative e difficoltà nell'attuazione del contratto di utilizzo che non erano prevedibili al momento della conclusione del contratto) e il cambiamento è ragionevole per l'utente ;",
          },
          {
            data: "La modifica è vantaggiosa solo per l'utente;",
          },
          {
            data: "Le modifiche o gli adeguamenti non hanno alcun impatto materiale sulle funzionalità dei servizi o sono di natura puramente tecnica o organizzativa;",
          },
          {
            data: `APST Research introduce servizi o prestazioni aggiuntivi, del tutto nuovi, che richiedono una descrizione nella <a className="uk-text-primary gtc-text" target="_blank" href="${
              process.env.REACT_APP_FRONTEND_URL + "general-terms-and-conditions"
            }">Termini generali e condizioni</a>, a meno che ciò non sia dannoso per l'attuale rapporto con l'utente. La modifica prevista verrà inviata all'utente all'ultimo indirizzo e-mail fornito ad APST Research o comunicata tramite un annuncio nell'app. La modifica entra in vigore entro 14 giorni dalla pubblicazione della modifica nell'app o dalla messa a conoscenza della modifica da parte dell'utente in altro modo, a meno che l'utente non si opponga alla modifica entro 14 giorni. Si ritiene che l'utente abbia accettato i nuovi termini e condizioni se l'app e i servizi ALS Analytics vengono utilizzati dopo la scadenza di questi 14 giorni.`,
          },
        ],
      },

      {
        data: `In caso di contestazione entro il termine indicato al punto 16.1, APST Research può risolvere il rapporto contrattuale con l'utente senza preavviso. Se APST Research non risolve il precedente rapporto contrattuale dopo l'effettiva opposizione, le precedenti Condizioni d'Uso continueranno ad applicarsi invariate nei confronti del rispettivo utente. La versione attuale delle Condizioni d'uso può essere visualizzata facendo clic sul collegamento ipertestuale "Condizioni d'uso" <a target="_blank" href='https://www.apstresearch.com'> sul sito</a>`,
      },
    ],
  },
  contact: {
    title: "17. Contatto",
    description: "APST Research può essere contattato a:",
    list: [
      {
        data: "APST Research GmbH",
      },
      {
        data: "Westhafenstraße 1",
      },
      {
        data: "13353 Berlin, Germany",
      },
      {
        data: `E-Mail: <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>`,
      },
      {
        data: `Telefono: <a href="tel:+49(0)30-81031410">+49 (0) 30-81031410</a>`,
      },
    ],
  },
}

import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoggedInPatient from "./logged-in/LoggedIn";
import { Dashboard } from "./logged-in/dashboard/Dashboard";
import { AboutAllAbbrev } from "./logged-in/information-library/about-all-abbrev/AboutAllAbbrev";
import { AboutLabAnalysis } from "./logged-in/information-library/about-lab-analysis/AboutLabAnalysis";
import { BloodSample } from "./logged-in/information-library/blood-sample/BloodSample";
import { CancellingKit } from "./logged-in/information-library/canceling-kit/CancellingKit";
import { HowToOrder } from "./logged-in/information-library/how-to-order/HowToOrder";
import { SendingKits } from "./logged-in/information-library/sending-kits/SendingKits";
import { LabResults } from "./logged-in/lab/LabResults";
import { Profile } from "./logged-in/profile/Profile";
import { Shop } from "./logged-in/shop/Shop";
import { InitialSurvey } from "./logged-in/survey/InitialSurvey";
import LandingPage from "./logged-out/LandingPage";
import { MainApp } from "./models/App";
import { Loading } from "./shared/components/loading/Loading";
import SnackbarManager from "./shared/components/snackbar/SnackbarManager";
import { AppContext } from "./shared/context/Context";
import GeneralTermsConditionsPage from "./contents/GeneralTermsConditionsPage";
import RevocationPage from "./contents/RevocationPage";
import TermsOfUsePage from "./contents/TermsOfUsePage";
import PrivacyPolicyPage from "./contents/PrivacyPolicyPage";
import { useKeycloak } from "@react-keycloak/web";

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [pathname]);
  return null;
};

const LoggedInRoute = () => (
  <Suspense fallback={<Loading fullHeight={true} />}>
    <LoggedInPatient />
  </Suspense>
);

const MainRoute = () => {

  const App = new MainApp();
  const { store, api, ui } = App;
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  if (!initialized) {
    return (
      <div className="center">
        <div className="animated-text">Initialising...</div>
      </div>
    );
  }

  if (!isLoggedIn) {
    return (
      <Routes>
        <Route path="/*" element={<Navigate to="/" />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="data-privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="general-terms-and-conditions" element={<GeneralTermsConditionsPage />} />
        <Route path="terms-of-use" element={<TermsOfUsePage />} />
        <Route path="revocation" element={<RevocationPage />} />
      </Routes>
    );
  }

  return (
    <AppContext.Provider value={{ store, api, ui }}>
      <Routes>
        <Route path="/*" element={<Navigate to="/dashboard" />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route path="/" element={<LoggedInRoute />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="info/how-to-order" element={<HowToOrder />} />
          <Route path="info/sending-kit" element={<SendingKits />} />
          <Route path="info/blood-sample" element={<BloodSample />} />
          <Route path="info/about-lab" element={<AboutLabAnalysis />} />
          <Route path="info/about-all" element={<AboutAllAbbrev />} />
          <Route path="info/cancel-kit" element={<CancellingKit />} />
          <Route path="order" element={<Shop />} />
          <Route path="survey" element={<InitialSurvey />} />
          <Route path="lab" element={<LabResults />} />
          <Route path="profile" element={<Profile />} />
          <Route path="data-privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="general-terms-and-conditions" element={<GeneralTermsConditionsPage />} />
          <Route path="terms-of-use" element={<TermsOfUsePage />} />
          <Route path="revocation" element={<RevocationPage />} />
        </Route>
      </Routes>
      <SnackbarManager />
    </AppContext.Provider>
  );
};

export default MainRoute;


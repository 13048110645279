// import ReactDOM from 'react-dom/client';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import "./shared/styles/Index.scss"
import './locales/config';
import "./serviceWorker";

// const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// root.render(<App />);
const container = document.getElementById('root') as Element | DocumentFragment
const root = ReactDOMClient.createRoot(container);

document.addEventListener('DOMContentLoaded', () => {
    root.render(<App />);
});
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import german from './german.json';
import english from './english.json';
import italian from "./italian.json"

const resources = {
    en: {
        home: english,
    },
    de: {
        home: german,
    },
    it: {
        home: italian,
    },
}


i18next.use(initReactI18next).init({
    resources,
    lng: 'en',
    debug: false,
    fallbackLng: 'en',
    saveMissing: true
});

export default i18next;

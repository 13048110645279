import useTitle from "../../shared/hooks/useTitle"
import { observer } from "mobx-react-lite"
import { useAppContext } from "../../shared/context/Context"
import { ALSFRSDiagram } from "../../shared/charts/ALSFRSDiagram";
import { NFLDiagramGraph } from "../../shared/charts/NFLDiagramGraph";
import InfoText from "../../shared/components/info/InfoText";
import icons from "../../shared/components/utils/icons";
import { getCourseValue } from "../../shared/components/utils/utils";
import { useTranslation } from "react-i18next";

export const Dashboard = observer(() => {

    const { store } = useAppContext();

    useTitle("Dashboard");
    const { t: msg } = useTranslation(['home']);

    const patient = store.patient.patientJson;
    const survey = store.survey.all.map((sv) => sv.asJson);

    const date_of_symptoms_onset = () => {
        return patient ? patient.time_of_symptoms_onset : "";
    }

    const progression = () => {
        const val = getCourseValue(survey, date_of_symptoms_onset());
        return val
    };

    return (
        <div className="uk-section dashboard">
            <div className="uk-container uk-container-large">
                <div>
                    <div className="uk-margin">
                        <h3 className="uk-text-bold main-title">{msg("dashboardTitle")}</h3>
                        <p className="uk-margin-remove-top"><img alt="icon" src={icons.today} className="icon uk-margin-small-right" />{new Date().toLocaleDateString("de-DE")}</p>
                    </div>
                    <div className="uk-margin custombg uk-border-rounded">
                        <NFLDiagramGraph />
                    </div>
                    <div className="uk-flex uk-flex-between uk-margin-large-top">
                        <div>
                            <p className="uk-margin-remove-bottom uk-text-bold">{msg("progressionRate")}</p>
                        </div>
                        <div>
                            <InfoText>
                                <div>
                                    <p className="uk-text-bold">{msg("progressInfoTextQ1")}</p>
                                    <p>{msg("progressInfoTextA1")} </p>
                                    <p className="uk-text-bold">{msg("progressInfoTextQ2")}</p>
                                    <p>{msg("progressInfoTextA2")} </p>
                                    <p className="uk-text-bold">{msg("progressInfoTextQ3")}</p>
                                    <p>{msg("progressInfoTextA3")}</p>
                                    <p className="uk-text-bold">{msg("progressInfoTextQ4")}</p>
                                    <p>{msg("progressInfoTextA4")}</p>
                                </div>
                            </InfoText>
                        </div>
                    </div>
                    <div className="progression uk-flex uk-flex-right uk-card uk-card-default">
                        <div className="uk-width-1-3 uk-card uk-card-small slow">
                            <div className="rates">
                                {progression().value <= 0.5 && <div className="rate">{progression().value}</div>}
                            </div>
                            <p className="rate-text">{msg("slowRate")}</p>
                        </div>
                        <div className="uk-width-1-3 uk-card uk-card-small average">
                            <div className="rates">
                                {progression().value >= 0.5 && progression().value <= 2 && <div className="rate">{progression().value}</div>}
                            </div>
                            <p className="rate-text">{msg("averageRate")}</p>
                        </div>
                        <div className="uk-width-1-3 uk-card uk-card-small fast">
                            <div className="rates">
                                {progression().value > 2 && <div className="rate">{progression().value}</div>}
                            </div>
                            <p className="rate-text">{msg("fastRate")}</p>
                        </div>
                    </div>
                    <p className="uk-text-small uk-margin-large-bottom">
                        {msg("progressionRateDescriptionPartOne")} {" "}
                        <span className="uk-text-bold">{progression().value}</span>{" "}
                        {msg("progressionRateDescriptionPartTwo")}
                    </p>
                    <div className="uk-margin custombg uk-border-rounded">
                        <ALSFRSDiagram />
                    </div>
                </div>
            </div>
        </div>
    )
})
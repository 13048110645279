import  keycloak from "../keycloakConfig";
import { INotification } from "../models/Notification";
import AppStore from "../stores/AppStore";
import UiStore from "../stores/UiStore";
import AppApi from "./AppApi";

export class NotificationApi {
  constructor(
    private api: AppApi,
    private store: AppStore,
    public ui: UiStore
  ) { }

  private path() {
    return `${process.env.REACT_APP_API_ENDPOINT}/notifications/`;
  }

  private headers() {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    _headers.append("Accept", "application/json");
    _headers.append("Authorization", `Bearer ${keycloak.token}`);
    return _headers;
  }

  async getAll() {
    const url = this.path();
    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: this.headers(),
      });
      if (response.ok) {
        const data = await response.json();
        this.store.notification.load(data as INotification[]);
      }
    } catch (error) { }
  }

  async update(item: INotification) {
    const url = this.path();
    const options = {
      method: 'PATCH',
      headers: this.headers(),
      body: JSON.stringify({ ...item, unread: false }),
    };

    try {
      await fetch(url + item.id + "/", {
        ...options,
        mode: "cors",
      });
    } catch (error) { }
  }
}



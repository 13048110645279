export const italianrevocation = {
  title: "Revoca",
  subtitle: "In qualità di consumatore, l'utente ha il diritto legale di recesso",

  policy: {
    title: "Politica di revoca",
    subtitle: "Diritto di revoca",
    description: `Hai il diritto di recedere dal presente contratto entro quattordici giorni senza fornire alcuna motivazione. Il periodo di recesso è di quattordici giorni dalla data di conclusione del contratto. Esercitarsi
     il diritto di recesso, è necessario informarci (APST Research GmbH, Westhafenstraße 1, 13353 Berlin, Germany, Phone: +49 (0) 30 81031410, E-Mail:<a href="mailto: info@apstresearch.com">info@apstresearch.com</a>) della tua decisione di recedere dal presente contratto tramite a
     dichiarazione chiara (ad esempio una lettera inviata per posta o e-mail). Puoi utilizzare il modello di modulo di recesso allegato, ma non è obbligatorio. Per rispettare il termine di recesso è sufficiente che invii il tuo
      comunicazione relativa all'esercizio del diritto di recesso prima della scadenza del periodo di recesso.`,
  },

  consequences: {
    title: "Conseguenze della revoca",
    subtitle: "",
    description: `Se recedete dal presente contratto, vi rimborseremo tutti i pagamenti ricevuti da voi, comprese le spese di consegna (ad eccezione dei costi supplementari derivanti dalla vostra scelta
       di un tipo di consegna diverso dal tipo meno costoso di consegna standard da noi offerto), senza indebito ritardo e in ogni caso entro e non oltre 14 giorni dal giorno in cui siamo informati della tua decisione
        di recedere dal presente contratto. Per questo rimborso utilizzeremo lo stesso mezzo di pagamento da te utilizzato per la transazione originaria, salvo diversamente concordato espressamente con te; in nessuna circostanza lo farai
         verranno addebitate eventuali spese per tale rimborso.`,
  },
  expiryofrevocation: {
    title: "Scadenza del diritto di revoca",
    subtitle: "",
    description: `Nel caso di un contratto per la fornitura di contenuto digitale che non si trova su un supporto fisico, il tuo diritto di recesso decade anche se abbiamo iniziato l'esecuzione del contratto dopo che hai
     hai espressamente concordato che inizieremo ad eseguire il contratto prima della scadenza del periodo di recesso e hai confermato di essere a conoscenza della perdita del diritto di recesso dando il tuo consenso al
      inizio dell'esecuzione del contratto.`,
  },
  samplerevocation: {
    title: "Modello di modulo di revoca",
    description: `(Se desideri recedere dal contratto, compila questo modulo e rispedircelo.)`,
    list: [
      {
        data: `APST Research GmbH, Westhafenstraße 1, 13353 Berlin, Telefono: <a href="tel:+49(0)30-81031410">+49 (0) 30-81031410</a>, e-mail: <a href="mailto: info@apstresearch.com">info@apstresearch.com</a>`,
      },
    ],
    labelOne: "Io/noi (*) revoco il contratto da me/noi (*) concluso per l'acquisto dei seguenti beni (*)/la fornitura del seguente servizio (*)",
    labelTwo: "Ordinato il (*)/ricevuto il (*)",
    labelThree: "Nome del(i) consumatore(i)",
    labelFour: "Indirizzo del(i) consumatore(i)",
    labelFive: "Firma del/i consumatore/i (solo per notifica cartacea) ",
    labelSix: "Data",
    labelSeven: "(*)Cancella dove è possibile",
  },
}

import { makeObservable, runInAction, toJS } from "mobx";
import AppStore from "./AppStore";

export default abstract class Store<Type, Model> {
  items = new Map<string | number, Model>();
  selected: Type | null = null;
  protected store: AppStore;

  constructor(store: AppStore) {
    this.store = store;
    makeObservable(this, {
      items: true,
      selected: true,
      all: true,
    });
  }

  remove(id: string | number) {
    runInAction(() => {
      if (toJS(this.items.has(id))) {
        this.items.delete(id)
      };
    });
  }

  removeAll() {
    runInAction(() => {
      this.items.clear();
    });
  }

  getById(id: string | number) {
    const item = this.items.get(id);
    if (toJS(item)) return item;
    return undefined;
  }


  select(item: Type) {
    runInAction(() => {
      this.selected = item;
    });
  }

  clearSelected() {
    runInAction(() => {
      this.selected = null;
    });
  }

  get all() {
    return Array.from(toJS(this.items.values()));
  }
}

export const germaninfolibrary = {
  aboutAllAbbrev: {
    title: "Über ALS, NFL und ALSFRS",
    description: `
      Das Protein <b>Neurofilament light chain (NfL)</b> ist ein wichtiger Bestandteil der Struktur von Nervenzellen (Neuronen) und deren Fortsätzen (Axonen) im Gehirn und Rückenmark sowie von Nervensträngen im menschlichen Körper. Wenn Nervenzellen geschädigt werden, wie es beim Ausfall von Motoneuronen bei <b>ALS</b> der Fall ist, wird NfL in die Gehirn-Rückenmarks-Flüssigkeit (Liquor) und das Blut (Serum) freigesetzt. NfL kann im Blutserum und im Liquor mit speziellen Analysemethoden nachgewiesen werden.
              <br /> <br />
              NfL gilt als <b>prognostischer und Verlaufsmarker der ALS</b>. Der NfL-Wert im Blut ist - neben den ALS-Symptomen - ein Merkmal für den Krankheitsverlauf der ALS. Ein hoher NfL-Wert im Blutserum ist häufig mit einem schnelleren Fortschreiten der ALS-Symptome verbunden.
              <br /> <br />
              Der NfL-Spiegel im Blut steigt mit dem Alter an. Studien haben sich auf Schwellenwerte geeinigt, ab denen der NfL-Spiegel bei der ALS pathologisch ist. Das Diagramm in der ALS Analytics App zeigt die einzelnen NfL-Werte im Blut im Verlauf der Krankheit in Abhängigkeit vom Alter. NfL-Werte oberhalb des blauen Bereichs im Diagramm gelten als eindeutig pathologisch.
              <br /> <br />
              Nach aktuellen wissenschaftlichen Erkenntnissen ist der NfL-Wert im Blut recht stabil, so dass eine Veränderung der Serumkonzentration erst im Laufe von mehreren Monaten zu erwarten ist. Daher sollte der Abstand zwischen den NfL-Untersuchungen bei etwa 6 Monaten liegen.
              <br /> <br />
              Die <b>ALS Functional Rating Scale (ALSFRS)</b> ist eine klinische Skala zur Bewertung des Schweregrads und des Fortschreitens der ALS. Die ALSFRS-Skala ist ein Fragebogen, der aus 12 Fragen zu ALS-typischen Symptomen und 5 Antwortmöglichkeiten (0-4 Punkte) besteht. Die Fragen können sowohl von einem Arzt als auch von einem Patienten beantwortet werden.
              <br /> <br />
              Die Online-Patientenbeurteilung des ALSFRS ermöglicht es, den individuellen Krankheitsverlauf zu erfassen und zwischen verschiedenen Verlaufsformen der ALS zu unterscheiden. Es wird zwischen verschiedenen Progressionsgeschwindigkeiten wie schnellem oder langsamem Fortschreiten, früher oder später Beeinträchtigung der Atemfunktion, frühen oder späten bulbären Symptomen, räumlicher Einschränkung oder Ausbreitung der Paresen unterschieden.
              <br /> <br />
              Der <b>Verlaufswert</b> in der ALS Analytics App bietet Menschen mit ALS die Möglichkeit, die Geschwindigkeit des eigenen Krankheitsverlaufs als schnell, langsam oder durchschnittlich einzustufen. Er dient als Orientierungshilfe für Betroffene sowie deren Ärzte und Therapeuten. Der Verlaufswert wird aus dem aktuellen ALSFRS-Gesamtwert und dem Datum des Auftretens der ersten Krankheitssymptome berechnet.
              <br /> <br />
              Die Selbstbewertungsdaten aus dem ALSFRS sind nur in Ihrem ALS Analytics App-Konto sichtbar.
              <br /> <br />
              Mit Ihrem Einverständnis können die Daten auch universitären Einrichtungen und Herstellern von Medizinprodukten oder Medikamenten für <b>Forschungs- und Entwicklungszwecke</b> zur Verfügung gestellt werden. Zu diesem Zweck erhalten die Forschungseinrichtungen und Hersteller die Daten nur in pseudonymisierter oder anonymisierter Form.`,
  },
  howToOrder: {
    title: "Wie Sie bestellen ",
    description: `
              Sie können einen Test für NfL bestellen, indem Sie unseren Online-Shop über die ALS Analytics-App besuchen. Um auf den Online-Shop zuzugreifen, klicken Sie auf die Registerkarte "NfL-Analyse-Kit bestellen" im Navigationsmenü auf der Startseite der App.
              <br /> <br />
              Nach dem Kauf werden wir Ihnen ein Blutprobenentnahme-Kit zukommen lassen. Ihr Blutprobenentnahme-Kit wird je nach Ihrem Standort innerhalb von 5-7 Tagen mit Standardversand geliefert.
              <br /> <br />
              Ihr Kit enthält alles, was Sie für die Rücksendung Ihrer Probe an APST Research benötigen. Das Set enthält entweder einen vorausbezahlten Rücksendeumschlag oder ein Rücksendeetikett auf dem Karton. Lesen Sie die Anweisungen, bevor Sie Ihre Probenentnahmebox verschließen.
              <br /> <br />
              Ein Rezept ist für die Bestellung unseres NfL-Tests nicht erforderlich.
              <br /> <br />
              <br /> <br />
              Wir akzeptieren die folgenden Zahlungsmittel:
              <ul>
                <li>Visa</li>
                <li>Mastercard</li>
                <li>American Express</li>
                <li>Paypal</li>
                <li>Apple Pay</li>
              </ul>
              Wir akzeptieren derzeit keine Krankenversicherungen, bemühen uns aber, unsere Tests so erschwinglich wie möglich zu halten.`,
  },
  sendingKits: {
    title: "Versand der Kits",
    description: `Wir liefern Ihnen ein Probenentnahme-Kit für zu Hause, das alles enthält, was Sie benötigen, um Ihre Blutprobe an APST Research zu senden.
              <br /> <br />
              Unser Blutprobenentnahme-Kit wird in einer allgemeinen, einfachen Verpackung geliefert, auf der kein Hinweis auf die ALS Analytics App zu finden ist.
              <br /> <br />
              In der Regel wird Ihr Blutprobenentnahme-Kit innerhalb von 5 Werktagen bei Ihnen eintreffen, dies ist jedoch abhängig von Ihrem Standort.
              <br /> <br />
              Der Versand zu Ihnen und der Rückversand an APST Research ist im Preis des Analyse-Kits enthalten.
              <br /> <br />
              Sobald die Probe entnommen wurde, versiegeln Sie die Blutprobe in dem Beutel, der Ihrem Kit beiliegt. Legen Sie dann den Beutel mit der Probe zurück in die Box des Kits. Wenn sich auf dem Beutel ein Etikett befindet, vergewissern Sie sich, dass Sie es vollständig ausgefüllt haben.
              <br /> <br />
              Schließen Sie die Kit-Box. Ihrem Kit liegt entweder ein frankierter Rückumschlag oder ein Rücksendeetikett bei, mit dem Sie Ihre Probe an APST Research zurückschicken können. Bitte senden Sie das Kit umgehend zurück. Weitere Informationen finden Sie in den Anweisungen in Ihrem Kit.
              <br /> <br />
              Zurzeit liefern wir nur in die folgenden Länder:
              <ul>
                <li>Deutschland</li>
                <li>Österreich</li>
                <li>Italien</li>
                <li>Schweiz</li>
              </ul>
              Bleiben Sie auf unserer Website und in den sozialen Medien auf dem Laufenden, denn wir möchten im Zuge unserer Expansion Menschen an weiteren Standorten unterstützen.`,
  },
  bloodSample: {
    title: "Entnahme von Blut",
    description: `Sie lassen sich Ihre Blutprobe bei Ihrem Arzt abnehmen.
              <br /> <br />
              Das Kit enthält alles, was Ihr Arzt für die Entnahme Ihrer Blutprobe benötigt.
              <br /> <br />
              Unserem Blutentnahme-Kit liegt eine Anleitung bei, die Ihnen zeigt, wie Ihre Probe beim Arzt entnommen werden sollte.`,
  },
  aboutLabAnalysis: {
    title: "Über die Laboranalyse",
    description: `Ihre Proben werden in demselben Labor bearbeitet, das auch von ambulanten Fachärzten und Krankenhäusern genutzt wird.
              <br /> <br />
              Alle Proben werden im Labor Berlin - Charité Vivantes GmbH in Berlin analysiert, das ein zertifiziertes und akkreditiertes Labor in der EU ist.
              <br /> <br />
              Unser Labor unterzieht sich regelmäßigen Qualitätskontrollen, um den höchsten Standard der Untersuchungen zu gewährleisten. Die in unserem Partnerlabor verwendeten Geräte und Analysatoren sind alle für die spezifische NfL-Untersuchung Ihrer Probe validiert.
              <br /> <br />
              Sobald APST Research Ihre Probe erhalten hat, werden Ihre Ergebnisse innerhalb von 5-14 Werktagen an Ihr ALS Analytics App-Konto übermittelt.
              <br /> <br />
              Wenn Ihre Ergebnisse fertig sind, erhalten Sie eine E-Mail oder eine Push-Nachricht auf Ihrem Mobiltelefon, um Sie darüber zu informieren, dass Ihre Ergebnisse verfügbar sind. Sie können dann in Ihrem ALS Analytics App-Konto auf Ihre Ergebnisse zugreifen.
              <br /> <br />
              Wir stellen Ihnen auch eine PDF-Kopie der Ergebnisse zur Verfügung, die Sie ausdrucken und mit zu Ihrem Arzt nehmen können.`,
  },
  cancellingKit: {
    title: "Widerruf des Kaufs",
    description: `Wenn Sie den Analyse-Kit nicht mehr verwenden wollen oder Ihre Meinung ändern, haben Sie das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Kaufvertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Abschlusses des Kaufvertrags. 
              <br /> <br />
              Um Ihr Widerrufsrecht auszuüben, müssen Sie uns mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder eine E-Mail) über Ihren Entschluss, diesen Kaufvertrag zu widerrufen, informieren. 
              <br /> <br />
              <br /> <br />
              APST Research GmbH
              <br /> <br />
              Westhafenstraße 1<br />
              13353 Berlin, Germany.
              <br /> <br />
              <br /> <br />
              E-Mail: <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>
              <br /> <br />
              Weitere Informationen zur Stornierung und Erstattung finden Sie in unserer Widerrufsbelehrung. 
              <br /> <br />
              Bei APST Research sind wir bestrebt, einen guten Kundenservice zu bieten, und wir würden gerne von Ihren Erfahrungen hören. Wenn Sie ein Feedback oder Verbesserungsvorschläge haben oder wenn Ihre Erfahrungen mit der ALS Analytics App nicht Ihren Erwartungen entsprechen, schreiben Sie uns bitte an: <a href="mailto:info@apstresearch.com">info@apstresearch.com</a>.`,
  },
}

import MODAL_NAMES from "../ModalName";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { hideOffcanvasFromId } from "../../../shared/components/functions/ModalShow";

const ContactsModal = observer(() => {

  const { t: msg } = useTranslation(['home']);

  const onCancel = () => {
    hideOffcanvasFromId(MODAL_NAMES.PATIENT.CONTACTS_MODAL)
  }

  return (
    <div className="uk-offcanvas-bar">
      <button className="uk-offcanvas-close" type="button" data-uk-close
        onClick={onCancel}></button>
      <h5>{msg("contactInformation")}</h5>
      <ul>
        <li><a href="Tel: +49 30-81031410">Tel: +49 30-81031410</a></li>
        <li><a href="Tel: +49 177-3106189">Mobil: +49 177-3106189</a> </li>
        <li><a href="mailto:info@apstresearch.com">info@apstresearch.com</a></li><br />
        <li>Westhafenstraße 1</li>
        <li>13353 Berlin</li>
      </ul>
      <div className="uk-text-right">
        <button
          className="btn btn-warning"
          type="button" onClick={onCancel}>
          {msg("cancelButton")}
        </button>
      </div>
    </div>
  );
});

export default ContactsModal;
